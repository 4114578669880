(function(){
'use strict';

scImpactBlock.$inject = ["$window", "scFroalaOptions", "scBlock", "scCampaignsService", "scSwitchFlowPickerService", "scAnalytics", "countryBlockService"];
angular.module('classy').directive('scImpactBlock', scImpactBlock);

function scImpactBlock($window, scFroalaOptions, scBlock, scCampaignsService, scSwitchFlowPickerService, scAnalytics, countryBlockService) {
  return scBlock({
    link: function link(scope) {
      scope.froalaOptionsPreview = scFroalaOptions({
        type: 'edit-inline'
      }, {
        charCounterCount: true,
        charCounterMax: 180,
        editorClass: 'no-counter',
        ariaLabel: 'Impact Headline'
      });

      var campaignType = scCampaignsService.active.current.type;

      scope.public = scope.$parent.public;

      scope.hasFundraising = scCampaignsService.active.HAS_FUNDRAISING;

      scope.handleImpactLink = function (level) {
        countryBlockService.checkOrganizationRestrictions(function () {
          return scope.impactLink(level);
        });
      };

      scope.impactLink = function (level) {
        var previewLink = scope.demo ? '&preview=true' : '';

        scAnalytics.eventBeacon({
          category: 'frs ' + campaignType,
          action: 'click',
          label: 'Fundraise CTA'
        });

        switch (scope.block.impactType) {
          case 'fundraise':
            scSwitchFlowPickerService.fundraiseSwitch({ goal: level.amount });
            break;
          default:
            $window.location = '/give/' + scope.campaign.id + '?amount=' + level.amount + previewLink + '#!/donation/checkout';
            break;
        }
      };
    }
  });
}
})();