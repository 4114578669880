(function(){
'use strict';

/*
 * Most parent abstraction of Crypto Giving
 * - Singleton
 */
// [TODO]
// - logic for params? to keep track of steps
angular.module('classy').service('CryptoGiving', ["scOrganizationsService", "$log", "CG_COINS", "CryptoGivingPaths", "CryptoGivingWallet", function (scOrganizationsService, $log, CG_COINS, CryptoGivingPaths, CryptoGivingWallet) {
  var steps = ['donation', 'contact', 'wallet'];
  this.activeStep = steps[0];
  this.pathCheckout = CryptoGivingPaths.checkout;
  this.pathToggle = CryptoGivingPaths.toggle;

  this.isEnabled = false; // Is crypto enabled on the campaign?
  this.isExclusive = false; // Is crypto the only checkout available?
  this.isHidden = true; // Is the crypto checkout form hidden? Default is yes

  this.wallet = CryptoGivingWallet.init({
    coins: CG_COINS
  });

  /* Can only enable CryptoGiving if has access and active crypto processor. */
  this.init = function (config) {
    if (!this.hasAccess()) {
      $log.err('[Classy:CryptoGiving] no access.');
    } else if (!this.hasProcessor()) {
      $log.err('[Classy:CryptoGiving] no processor.');
    } else {
      var c = config || {};
      this.isEnabled = _.isBoolean(c.isEnabled) ? c.isEnabled : this.isEnabled;
      this.isExclusive = _.isBoolean(c.isExclusive) ? c.isExclusive : this.isExclusive;
      this.isHidden = _.isBoolean(c.isHidden) ? c.isHidden : this.isHidden;
    }

    return this;
  };

  this.getCoin = function (symbol) {
    return this.wallet.getCoin(symbol);
  };

  /* Does the org have access? Org tag 'early-access:crypto-giving' required. */
  this.hasAccess = function () {
    return !!scOrganizationsService.getTag('early-access:crypto-giving', true);
  };

  /* Does the org have the crypto payment processor? */
  this.hasProcessor = function () {
    return !!(SC && SC.processors && SC.processors.Crypto && SC.processors.Crypto.id && SC.processors.Crypto.status === 'ACTIVE');
  };

  this.enabled = function () {
    return this.isEnabled;
  };

  this.exclusive = function () {
    return this.isExclusive;
  };

  this.hidden = function () {
    return this.isHidden;
  };

  this.show = function () {
    this.isHidden = false;
  };

  this.hide = function () {
    this.isHidden = true;
  };
}]);
})();