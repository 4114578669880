(function(){
'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

angular.module('classy').component('radioGroup', {
  transclude: true,
  template: '<ng-transclude></ng-transclude>',
  controller: function radioGroupControl() {
    _classCallCheck(this, radioGroupControl);

    this.radioOptions = [];
  }
}).component('radioControl', {
  transclude: true,
  require: {
    ngModelCtrl: 'ngModel',
    radioCtrl: '^?radioGroup'
  },
  template: '\n      <button class="{{::$ctrl.styleClass}}"\n      aria-checked="{{$ctrl.isChecked()}}"\n      role="radio"\n      tabindex="0"\n      ng-class="{ checked: $ctrl.isChecked() }"\n      ng-click="$ctrl.select()"\n      ng-keydown="$ctrl.onKeyDown($event)"\n      ng-disabled="$ctrl.disabled"\n      type="button"\n      ng-disabled="$ctrl.disabled"\n      aria-checked="{{ $ctrl.isChecked() }}"\n      aria-label="{{ ::$ctrl.ariaLabel }}"\n      _spec="button">\n\n        <span class="{{::$ctrl.styleClass + \'_input\'}}"\n        sc-error-class="$ctrl.ngModelCtrl.$name"\n        ng-style="{ color: $ctrl.color }"\n        _spec="radio"></span>\n\n        <span class="{{::$ctrl.styleClass + \'_label\'}}"\n          qa-id="{{:: $ctrl.scQaId.radioField }}"\n        ng-transclude\n        _spec="label"></span>\n\n      </button>\n    ',
  bindings: {
    value: '<',
    size: '<?',
    disabled: '<?',
    ariaLabel: '@?'
  },
  controller: function () {
    RadioControlCtrl.$inject = ["$state", "scThemesService", "scQaId", "$element"];
    function RadioControlCtrl($state, scThemesService, scQaId, $element) {
      _classCallCheck(this, RadioControlCtrl);

      this.color = $state.includes('admin') ? '#425CCD' : scThemesService.active.current.styles.primaryColor;
      this.scQaId = scQaId;
      this.$element = $element;
    }

    _createClass(RadioControlCtrl, [{
      key: '$onInit',
      value: function $onInit() {
        this.ngModelCtrl.$isEmpty = this.isEmpty;
        if (this.size === 'small') {
          this.styleClass = 'form__radio-control--small';
        } else {
          this.styleClass = 'form__radio-control';
        }
        if (this.radioCtrl && this.radioCtrl.radioOptions) {
          this.radioCtrl.radioOptions.push({ value: this.value, element: this.$element[0] });
        }
      }
    }, {
      key: 'select',
      value: function select() {
        this.ngModelCtrl.$setViewValue(this.value);
        this.ngModelCtrl.$setTouched();
        this.ngModelCtrl.$setDirty();
      }
    }, {
      key: 'onKeyDown',
      value: function onKeyDown($event) {
        var _this = this;

        var KEYS = {
          left: 37,
          up: 38,
          right: 39,
          down: 40
        };

        var valueList = this.radioCtrl && this.radioCtrl.radioOptions;
        if (valueList) {
          var currentIndex = valueList.findIndex(function (el) {
            return _this.$element[0].isSameNode(el.element);
          });

          if ($event.keyCode === KEYS.up || $event.keyCode === KEYS.left) {
            $event.preventDefault();
            var previousIndex = currentIndex - 1;
            if (previousIndex < 0) previousIndex = valueList.length - 1;
            valueList[previousIndex].element.children[0].focus();
            this.ngModelCtrl.$setViewValue(valueList[previousIndex].value);
          } else if ($event.keyCode === KEYS.down || $event.keyCode === KEYS.right) {
            $event.preventDefault();
            var nextIndex = currentIndex + 1;
            if (nextIndex > valueList.length - 1) nextIndex = 0;
            valueList[nextIndex].element.children[0].focus();
            this.ngModelCtrl.$setViewValue(valueList[nextIndex].value);
          }
        }
      }
    }, {
      key: 'isChecked',
      value: function isChecked() {
        return this.ngModelCtrl.$modelValue === this.value;
      }
    }, {
      key: 'isEmpty',
      value: function isEmpty(value) {
        return _.isUndefined(value);
      }
    }]);

    return RadioControlCtrl;
  }()
});
})();