(function(){
'use strict';

scAboutBlock.$inject = ["scFroalaOptions", "scBlock"];
angular.module('classy').directive('scAboutBlock', scAboutBlock);

function scAboutBlock(scFroalaOptions, scBlock) {
  return scBlock({
    link: function link(scope) {
      scope.froalaOptionsContent = scFroalaOptions({
        type: 'edit-inline-about'
      }, {
        scImgAssetFroalaUpload: true,
        ariaLabel: 'About Content'
      });

      scope.froalaOptionsTitle = scFroalaOptions({
        type: 'edit-inline-about-title'
      }, {
        charCounterCount: true,
        charCounterMax: 180,
        editorClass: 'no-counter',
        ariaLabel: 'About Headline'
      });

      scope.contentOptions = {
        fontBase: 22,
        fontRatio: 18
      };
    }
  });
}
})();