(function(){
'use strict';

angular.module('classy')
// TODO:
// - move to ClassyPayPaths to classy-pay services direectory
// - what about the other donate btn?
// - fine tuning :resolved $rootScope event / fine tuning frs stuff
.service('ClassyPayPaths', function () {
  var staticImagesPath = '/static/global/images/classy-pay/';

  this.logos = {
    ach: staticImagesPath + 'classy-pay-logo-ach.svg',
    ccs: staticImagesPath + 'classy-pay-logo-ccs.svg',
    paypal: staticImagesPath + 'classy-pay-logo-paypal.svg',
    secure: staticImagesPath + 'classy-pay-logo-secure.svg',
    venmo: staticImagesPath + 'classy-pay-logo-venmo.svg'
  };
}).component('cpDonateFooter', {
  templateUrl: 'global/classy-pay/components/donate-footer/classy-pay.components.donate-footer', // same folder as this
  bindings: {
    donationFrequency: '<',
    donationCurrency: '<',
    donationAmount: '<',
    pmLogo: '<', // "pm" = "payment method"
    pmLabel: '<',
    btnColor: '<',
    btnText: '<'
  },
  controller: ["$rootScope", "$timeout", "$window", "ClassyPayPaths", "scOrganizationsService", function controller($rootScope, $timeout, $window, ClassyPayPaths, scOrganizationsService) {
    var _this = this;

    var self = this;
    var DONATE_BTN_MAIN_ID = '#donate-cta-main';
    this.ClassyPayPaths = ClassyPayPaths;
    this.is = {
      accessible: false,
      donating: false,
      mainDonateVisible: false
    };

    function __isMainDonateButtonVisible() {
      var mainDonateBtn = angular.element(DONATE_BTN_MAIN_ID);

      if (!mainDonateBtn || !mainDonateBtn[0]) {
        return false;
      }

      var elementTop = mainDonateBtn.offset().top;
      var elementBottom = elementTop + mainDonateBtn.outerHeight();
      var viewportTop = $window.scrollY;
      var viewportBottom = viewportTop + $window.innerHeight;
      var isVisible = elementBottom > viewportTop && elementTop < viewportBottom;

      return isVisible;
    }

    // START: Event Handlers
    function __handleScroll() {
      $timeout(function () {
        self.is.mainDonateVisible = __isMainDonateButtonVisible();
      });
    }

    function __handleResize() {
      $timeout(function () {
        self.is.mainDonateVisible = __isMainDonateButtonVisible();
      });
    }

    function __handleDonateResolve() {
      $timeout(function () {
        self.is.donating = false;
      }, 150);
    }
    // STOP: Event Handlers

    this.$onInit = function () {
      _this.is.accessible = !!scOrganizationsService.getTag('mobile-checkout-optimization', true);
      if (!_this.is.accessible) {
        return;
      }

      _this.btnText = _this.btnText || 'Donate Now';
      _this.btnColor = _this.btnColor || '#425CCD'; // from figma

      // Subscribe to events
      _this.donateResolveHandler = $rootScope.$on('cpDonateFooter:donate:resolved', __handleDonateResolve);
      $window.addEventListener('scroll', __handleScroll);
      $window.addEventListener('resize', __handleResize);
    };

    this.canShow = function () {
      return !!_this.donationFrequency && !!_this.donationAmount && !!_this.donationCurrency;
    };

    this.shouldShow = function () {
      return _this.canShow() && !_this.is.mainDonateVisible;
    };

    this.donate = function () {
      _this.is.donating = true;
      $rootScope.$broadcast('cpDonateFooter:donate:submit');
    };

    // On destroy unsub from events (to avoid mem leaks)
    this.$onDestroy = function () {
      if (_this.donateResolveHandler) {
        _this.donateResolveHandler();
      }
      $window.removeEventListener('scroll', __handleScroll);
      $window.removeEventListener('resize', __handleResize);
    };
  }]
});
})();