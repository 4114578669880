(function(){
'use strict';

scHeaderBlock.$inject = ["$state", "$window", "mobileMenuAPI", "registrationEntityFlow", "registrationNavigator", "registrationRoutes", "scAuth", "scMembersService", "scBlock", "scCampaignsService", "scCartService", "scPagesService", "scFlowModalService", "scThemesService", "$location"];
angular.module('classy').directive('scHeaderBlock', scHeaderBlock);

function scHeaderBlock($state, $window, mobileMenuAPI, registrationEntityFlow, registrationNavigator, registrationRoutes, scAuth, scMembersService, scBlock, scCampaignsService, scCartService, scPagesService, scFlowModalService, scThemesService, $location) {
  return scBlock({
    link: function link(scope) {
      var BASE_ROUTE = registrationRoutes.BASE_ROUTE,
          RECEIPT_ROUTE = registrationRoutes.RECEIPT_ROUTE;


      scope.campaign = scCampaignsService.active;
      scope.theme = scThemesService.active;
      scope.mobileMenuIsOpen = false;

      /* ---------------------------------------------------------------- *
       * Logo Link
       * ---------------------------------------------------------------- */

      // return a label for screen readers to use on the logo link
      scope.getLogoLinkLabel = function () {
        var redirectPage = scope.block.logoLink || 'event';
        var campaignName = scope.campaign.current.name || 'campaign';

        if (redirectPage === 'custom') {
          return 'Go to ' + scope.block.logoLinkCustom;
        }

        return 'Go to the ' + campaignName + ' ' + redirectPage + ' page';
      };

      scope.goToLink = function () {
        // When leaving checkout flow via logo btn, save user's
        // last viewed checkout step so that we can return later.
        if ($state.current.name.indexOf(BASE_ROUTE) > -1) {
          registrationNavigator.setLastState();
        }
        $window.location.href = scope.block.logoLink == 'custom' ? getCustomUrl() : scope.headerLink(scope.block.logoLink);
      };

      function getCustomUrl() {
        var customUrl = scope.block.logoLinkCustom;

        if (!/^(f|ht)tps?:\/\//i.test(customUrl)) {
          customUrl = 'http://' + customUrl;
        }
        return customUrl;
      }

      scope.headerRedirect = function ($event) {
        scope.scrollToElement('sc-target-logo', $event);
        if (scope.editable) {
          return;
        }

        if ($state.current.name.indexOf(BASE_ROUTE) > -1 && !$state.is(RECEIPT_ROUTE) && !scCartService.active.isReserved) {
          registrationNavigator.confirmExit(function () {
            return scope.goToLink();
          });
        } else {
          scope.goToLink();
        }
      };

      scope.headerLink = function (logo) {
        if (!scope.public) {
          return undefined;
        }

        if (logo === 'donation') {
          scope.headerTarget = '_self';
        }

        var defaultLogoLink = scope.campaign.current.type == 'donation' ? 'donation' : 'landing';
        // fallback to the logo pointing at the landing page
        var logoLink = logo || defaultLogoLink;

        var landingRedirect = scPagesService.getLandingPageRedirect();
        if (landingRedirect) {
          scope.headerTarget = '_self'; // in case their custom redirect is the donation page
          return landingRedirect;
        }
        var options = {
          pageType: logoLink,
          campaignId: scope.campaign.current.id,
          campaignName: scope.campaign.current.name,
          status: scope.campaign.current.status
        };

        return scPagesService.getUrl(options).url;
      };

      /* ---------------------------------------------------------------- *
       * Search
       * ---------------------------------------------------------------- */

      scope.searchLink = scPagesService.getUrl({
        pageType: 'search',
        campaignId: scope.campaign.current.id
      }).url;

      scope.showSearchIcon = scCampaignsService.active.HAS_FUNDRAISING;

      scope.target = $state.current.name.indexOf('frs.landing.name.campaign.registration') > -1 ? '_blank' : '_self';

      /* ---------------------------------------------------------------- *
       * Cart
       * ---------------------------------------------------------------- */

      scope.cartItems = function () {
        return _.get(scCartService, 'active.quantity', 0);
      };

      scope.goToCheckout = function (event) {
        // capture event target for refocusing after closing modal
        var eventTarget = _.get(event, 'currentTarget', null);

        if ($state.includes('frs.landing.name.campaign.registration')) {
          // if mobile view, display cart
          var isMobile = void 0;

          if (Modernizr.prefixed('matchMedia', window)) {
            isMobile = window.matchMedia('(max-width: 1023px)').matches;
          } else {
            isMobile = angular.element($window).width() <= 1023;
          }

          scope.cart = scCartService.active;

          if (isMobile) {
            scFlowModalService.open({
              id: 'rwf_mobile_cart',
              context: scope,
              template: '\n                <mobile-cart-modal\n                cart="cart">\n                </mobile-cart-modal>\n              '
            });
          }

          return;
        }

        if (scCartService.active.isReserved) {
          // if coming from donation page, redirect with link
          if ($state.includes('frs.donation')) {
            var checkoutUrl = scPagesService.getCheckoutRedirect();
            $window.location.href = checkoutUrl;
          } else {
            registrationNavigator.goToLastState();
          }
        } else {
          registrationEntityFlow(eventTarget);
        }
      };

      /* ---------------------------------------------------------------- *
       * Auth
       * ---------------------------------------------------------------- */

      scope.scAuth = scAuth;
      scope.loggedIn = !!scMembersService.active.current.id;

      scope.login = function () {
        scAuth.loginOnly({ callback: $window.location.href });
      };

      scope.$watch(function () {
        return _.get(scMembersService, 'active.current.id');
      }, function (newVal, oldVal) {
        if (newVal !== oldVal) {
          scope.loggedIn = !!newVal;
        }
      });

      if ($location.search().source === 'sso_reset_password_link') {
        scAuth.resetPasswordByLink();
      }

      /* ---------------------------------------------------------------- *
       * Mobile menu
       * ---------------------------------------------------------------- */

      scope.openMobileMenu = function (ref) {
        mobileMenuAPI.open(ref);
        scope.mobileMenuIsOpen = true;
      };

      // Check if menu has been opened
      scope.$watch(function () {
        return _.get(mobileMenuAPI, 'active');
      }, function (newVal, oldVal) {
        if (newVal !== oldVal) {
          scope.mobileMenuIsOpen = newVal;
        }
      });
    }
  });
}
})();