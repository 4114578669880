(function(){
'use strict';

scFundraiserBlock.$inject = ["$http", "$log", "$q", "$rootScope", "$state", "$stateParams", "$timeout", "$window", "scCampaignsService", "scBlock", "scFundraisingPagesService", "scMembersService", "scPagesService", "fundraiseSwitchFlow", "scButtonStyleUpdate", "scBanner", "scThemesService", "scBlocksService", "scSocialShareLinks", "scProgressDataFactory", "accessibilityService"];
angular.module('classy').directive('scFundraiserBlock', scFundraiserBlock);

function scFundraiserBlock($http, $log, $q, $rootScope, $state, $stateParams, $timeout, $window, scCampaignsService, scBlock, scFundraisingPagesService, scMembersService, scPagesService, fundraiseSwitchFlow, scButtonStyleUpdate, scBanner, scThemesService, scBlocksService, scSocialShareLinks, scProgressDataFactory, accessibilityService) {
  return scBlock({
    responsiveSettings: {
      tablet: 'tablet'
    },

    controller: ['$scope', function ($scope) {
      // initial state of button colors
      var primary = _.get($scope.theme, 'styles.primaryColor', 'white');

      if (_.isNull($scope.block.donateButtonColor)) {
        $scope.block.donateButtonColor = primary;
      }

      $scope.block.raisedColor = '#f0f0f0';

      $scope.scButtonStyleUpdate = scButtonStyleUpdate;
      $scope.public = $scope.$parent.public;
      $scope.scFundraisingPagesService = scFundraisingPagesService;
    }],

    link: function link(scope) {
      scope.public = scope.$parent.public;

      scope.checkPreview = scope.SC.preview;

      scope.teamBlock = scBlocksService.get(scBlocksService.getIdByType('team'));

      scope.getFundraiserAlias = function () {
        return scope.fundraiser.getAlias();
      };

      scope.getHeaderText = function () {
        if (scope.fundraiser.current.is_tribute) return scope.fundraiser.current.intro_text;
        return scope.fundraiser.getAlias();
      };

      // Manage button (mobile only)

      scope.manage = function () {
        $state.go('frs.fundraiser.dashboardindex.dashboard.overview');
      };

      scope.canManage = false;

      scope.$watch(function () {
        return scMembersService.active.current.id;
      }, function () {
        scope.fundraiser.checkDashboardAccess().then(function (result) {
          scope.canManage = result;
        });
      });

      scope.isPreview = function () {
        return $state.is('admin.manage.campaign.step.setup') || $state.is('admin.manage.campaign.step.design.views.block') || _.get($state, 'params.preview', false);
      };

      scope.setActiveActivityTab = function (activeTab) {
        scope.activeActivityTab = activeTab;
      };

      scope.setActiveUpdatesTab = function (activeTab) {
        scope.activeUpdatesTab = activeTab;
      };

      scope.activeUpdatesTab = 'story';
      scope.activeActivityTab = 'fundraiser_activity';

      /* ---------------------------------------------------------------- *
       * Claim fundraising page banner
       * ---------------------------------------------------------------- */

      if (!scope.isPreview()) {
        var primaryColor = scThemesService.active.current.styles.primaryColor;
        var member = scMembersService.active.current;
        var fundraisingPage = scFundraisingPagesService.active;

        if (scope.campaign.type == 'reg_w_fund' && !_.isUndefined(member) && !_.isUndefined(fundraisingPage)) {
          var memberName = member.first_name || 'there';
          // If you're the owner of the fundraising page but you haven't claimed your page
          if (fundraisingPage.current.member_id === member.id && !fundraisingPage.checkDashboardAccess()) {
            scBanner('Hey ' + memberName + '! Please check your email to edit and manage your fundraising page', 'success', false, 5000, primaryColor);
          }
          // If you're the purchaser looking at a purchased registrant's fundraising page
          if ($stateParams.is_new && member.id !== fundraisingPage.current.member_id) {
            scBanner('Hey ' + memberName + '! ' + scope.getFundraiserAlias() + ' needs to check their email to edit and manage their page', 'success', false, 5000, primaryColor);
          }
        }
      }

      scope.updatesPerPage = 5;

      scope.loadAdditionalUpdates = function () {
        scope.updatesPerPage += 5;
      };

      scope.donateLink = function () {
        if (!scope.public) {
          return undefined;
        }

        var donateStr = '';

        if (scope.SC.preview) {
          donateStr = '/give/' + scope.campaign.id + '/?preview=true#!/donation/checkout';
        } else {
          donateStr = '/give/f' + scope.fundraiser.current.id + '/#!/donation/checkout';
        }

        return donateStr;
      };

      scope.fundraiseSwitch = function () {
        if (scope.demo) {
          $rootScope.SC.status.banner = {
            type: 'error',
            msg: 'Whoops, this is just a preview of your campaign page. Please publish your page first.'
          };
        } else {
          fundraiseSwitchFlow({
            fundraiser: scope.fundraiser.current.id,
            disableIndividual: true
          });
        }
      };

      scope.teamLink = function (id) {
        return scPagesService.getUrl({
          pageType: 'team',
          teamId: id,
          example: false
        }).url;
      };

      scope.campaignLink = function () {
        return scPagesService.getUrl({
          pageType: 'landing',
          campaignId: scCampaignsService.active.current.id,
          campaignName: scCampaignsService.active.current.name,
          status: scCampaignsService.active.current.status
        }).url;
      };

      scope.logoSrc = function () {
        return scFundraisingPagesService.getPagePhoto(scope.fundraiser.current);
      };

      scope.campaignPhoto = scope.theme.headerLogo ? scope.theme.headerLogo.src : '/static/global/images/team_default_image.png';

      /* ---------------------------------------------------------------- *
       * Sharing
       * ---------------------------------------------------------------- */

      scSocialShareLinks.getSocialData().then(function (socialData) {
        scope.twitterShareUrl = socialData.twitter_link;
        scope.emailUrl = socialData.email_link;
        scope.fbShare = socialData.facebook_share;
      });

      /* ---------------------------------------------------------------- *
       * Team
       * ---------------------------------------------------------------- */
      scope.team = {
        fetch: function fetch() {
          scope.fundraiser.getTeam().then(function (response) {
            scope.fundraiser.current.team = _.cloneDeep(response.data);

            if (!scope.fundraiser.current.team) return;

            scope.teamPhoto = _.get(scope.fundraiser.current, 'team.logo.cdn_url', _.get(scope.teamBlock.current, 'defaultTeamLogo.asset.src', '/static/global/images/team_default_image.png'));

            scope.fundraiser.current.team.logo = { src: scope.teamPhoto };
          }, function (error) {
            $log.error(error);
          });
        }
      };

      /* ---------------------------------------------------------------- *
       * Posts
       * ---------------------------------------------------------------- */

      scope.posts = {
        data: [],
        total: 0,
        ui: {
          deferred: $q.defer(),
          loading: true,
          active: null,
          mode: $state.is('frs.fundraiser.index.post') ? 'post' : 'list'
        },
        fetch: function fetch() {
          scope.fundraiser.getPosts().then(function (response) {
            if (!response) {
              return;
            }

            var posts = response.data;

            // Assign rank and latest status
            posts.data.forEach(function (post, index) {
              post.rank = posts.total - (posts.from + index) + 1;
              post.latest = posts.rank === posts.total;
            });

            // Fill out metadata
            scope.posts.data = posts.data;
            scope.posts.total = posts.total;
            scope.posts.ui.active = $state.is('frs.fundraiser.index.post') ? scope.posts.getById($state.params.postId) : null;

            scope.campaign.default_page_post_title = scope.campaign.default_page_post_title || scope.posts.data[0].title;

            scope.campaign.default_page_post_body = scope.campaign.default_page_post_body || scope.posts.data[0].body;

            if (!$rootScope.$$phase) {
              scope.$apply(function () {
                scope.posts.data.length = posts.data.length;
              });
            }

            scope.posts.ui.deferred.resolve();
          }, function () {
            scope.posts.ui.deferred.reject();
          }).finally(function () {
            scope.posts.ui.loading = false;
          });
        },
        getById: function getById(id) {
          var found = scope.posts.data.find(function (post) {
            return post.id.toString() === id.toString();
          });
          return found || false;
        },
        toPost: function toPost(post) {
          if ($state.is('frs.fundraiser.index')) {
            $state.go('.post', { postId: post.id });
          }
        },
        toList: function toList() {
          scope.posts.ui.active = null;
          if ($state.is('frs.fundraiser.index.post')) {
            $state.go('^');
          }
        }
      };

      scope.scrollToComments = function () {
        $timeout(function () {
          var commentInput = angular.element('.activity_comment-input');
          if (commentInput.length) {
            commentInput.focus();

            $('html, body').animate({
              scrollTop: commentInput.offset().top
            }, 400);
          }
        });
      };

      scope.stories = {
        data: [],
        total: 0,
        ui: {
          deferred: $q.defer(),
          loading: true,
          active: null,
          mode: $state.is('frs.fundraiser.index.post') ? 'post' : 'list'
        },
        fetch: function fetch() {
          scope.fundraiser.getStory().then(function (response) {
            if (!response) {
              return;
            }

            var story = response.data;

            // Fill out metadata
            scope.stories.data = story.data[0];
            scope.stories.total = story.total;
            scope.stories.ui.active = $state.is('frs.fundraiser.index.post') ? scope.stories.getById($state.params.storyId) : null;

            scope.stories.ui.deferred.resolve();
          }, function () {
            scope.stories.ui.deferred.reject();
          }).finally(function () {
            scope.stories.ui.loading = false;
          });
        },
        getById: function getById(id) {
          var stories = scope.stories.data;
          if (stories.id.toString() === id.toString()) {
            return stories;
          }

          return false;
        }
      };

      /* ---------------------------------------------------------------- *
       * Watchers
       * ---------------------------------------------------------------- */

      // eslint-disable-next-line no-unused-vars
      $rootScope.$on('$stateChangeSuccess', function (e, toState, toParams, fromState, fromParams) {
        if (toState.name === 'frs.fundraiser.index.post') {
          scope.posts.ui.deferred.promise.then(function () {
            scope.posts.ui.active = scope.posts.getById(toParams.postId);
            scope.posts.ui.mode = 'post';
            $timeout(function () {
              angular.element('html, body').animate({
                scrollTop: angular.element('.post').offset().top
              }, 400);
            });
          });
        }

        if (toState.name === 'frs.fundraiser.index') {
          scope.posts.ui.active = null;
          scope.posts.ui.mode = 'list';

          scope.stories.fetch();
          scope.stories.ui.active = null;
          scope.stories.ui.mode = 'list';
        }
      });

      if ((_.isEmpty(scope.fundraiser) || scope.fundraiser.isDemo) && (scope.demo || SC.preview)) {
        scope.fundraiser = scFundraisingPagesService.useExample();
      } else {
        scope.team.fetch();
      }

      var fundraiserProgress = scProgressDataFactory.getEntityProgress(scope.fundraiser.current);
      scope.fundraiserPercentToGoal = fundraiserProgress.percentToGoal * 100;
      scope.fundraiserGoal = fundraiserProgress.goal;
      scope.currency = fundraiserProgress.currency;
      scope.fundraiser.current.display_total_raised = fundraiserProgress.totalRaised;

      scope.posts.fetch();
      if ($state.current.name !== 'frs.fundraiser.index.post') {
        scope.stories.fetch();
      }

      // if this frpage belongs to the member, point the active frpage
      // to the user fundraiser page so that it picks up all
      // actions performed on the active page.
      var userFrPages = scFundraisingPagesService.userFundraisers;
      if (scope.fundraiser.current.member_id === scope.you.current.id) {
        userFrPages[scope.fundraiser.current.id] = scFundraisingPagesService.active;
      }

      /* ---------------------------------------------------------------- *
       * Display team join button if user owns the page, and if they are
       * not yet on a team.
       * ---------------------------------------------------------------- */
      scope.enableTeamJoin = function () {
        scope.fundraiser.getTeam().then(function (response) {
          if (scope.fundraiser.current.member_id === scope.you.current.id && _.isUndefined(response.data)) {
            scope.displayJoinTeamBtn = true;
          } else {
            scope.displayJoinTeamBtn = false;
          }
        });
      };

      scope.enableTeamJoin();

      /* ---------------------------------------------------------------- *
       * Testing
       * ---------------------------------------------------------------- */

      $window.deletePage = function () {
        if (
        // eslint-disable-next-line no-alert
        window.confirm('No, seriously, this will delete this fundraising page. Are you cool with that?')) {
          $http({
            method: 'DELETE',
            url: '/frs-api/fundraising-pages/' + scope.fundraiser.current.id
          }).then(function () {
            $state.go('frs.landing.name.campaign', {
              campaignId: scope.fundraiser.current.campaign_id,
              eventType: 'campaign'
            });
          }).catch(function (err) {
            $log.error(err);
          });
        }
      };
      scope.isPreviewHeading = function () {
        return $state.is('admin.manage.campaign.step.setup') || $state.is('admin.manage.campaign.step.design.views.content-blocks') || $state.is('admin.manage.campaign.step.design.views.block');
      };
      scope.setActiveTab = function (activeTab, $event) {
        if ($event) {
          if ($event.keyCode === accessibilityService.CONSTANTS.ENTER_KEY || $event.keyCode === accessibilityService.CONSTANTS.SPACE_KEY) {
            scope.activeUpdatesTab = activeTab;
          }
        }
      };
    }
  });
}
})();