(function(){
'use strict';

angular.module('classy').component('datepickerField', {
  template: '\n      <form-label \n      required="$ctrl.required"\n      ng-if="$ctrl.label"\n      _spec="label">\n        {{:: $ctrl.label }}\n      </form-label>\n\n      <input class="form__text-input--dark" \n      ng-model="$ctrl.model"\n      ng-model-options="{ getterSetter: $ctrl.getterSetter }"\n      ng-required="$ctrl.required"\n      sc-validity-reporter\n      sc-validity-classes\n      uib-datepicker-popup\n      is-open="visible"\n      ng-click="visible = true"\n      show-weeks="false"\n      type="text"\n      ng-disabled="$ctrl.disabled"\n      name="{{:: $ctrl.name }}"\n      placeholder="{{:: $ctrl.placeholder }}"\n      qa-id="{{:: scQaId.dateField }}"\n      _spec="input" />\n\n      <form-error \n      name="$ctrl.name" \n      messages="$ctrl.errorMessages"\n      _spec="errors"></form-error>\n    ',
  bindings: {
    model: '=',
    label: '<',
    name: '<',
    errorMessages: '<?',
    getterSetter: '<?',
    placeholder: '<?',
    required: '<?',
    disabled: '<?'
  },
  controller: ["scQaId", function DatepickerFieldCtrl(scQaId) {
    this.scQaId = scQaId;

    var DEFAULTS = {
      errorMessages: {
        required: 'This field is required.',
        date: 'Please enter a date.'
      },
      getterSetter: false,
      placeholder: 'YYYY-MM-DD',
      required: false
    };

    this.$onInit = function () {
      _.defaultsDeep(this, DEFAULTS);
    };
  }]
});
})();