(function(){
'use strict';

createFundraisingTeamFlowFactory.$inject = ["$http", "$q", "$rootScope", "$state", "$timeout", "$cacheFactory", "scFacebookSDKService", "scBlocksService", "scCampaignsService", "scFlowModalService", "scFroalaOptions", "scMembersService", "scFundraisingTeamModel", "scFundraisingTeamsService", "scFundraisingPagesService", "scThemesService", "scDomainsService", "scAnalytics", "scOrganizationsService", "scSourceCodes", "CFPAuthSwitchView", "CFPExitView", "CHAR_LIMITS"];
function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

angular.module('classy').factory('createFundraisingTeamFlow', createFundraisingTeamFlowFactory);

function createFundraisingTeamFlowFactory($http, $q, $rootScope, $state, $timeout, $cacheFactory, scFacebookSDKService, scBlocksService, scCampaignsService, scFlowModalService, scFroalaOptions, scMembersService, scFundraisingTeamModel, scFundraisingTeamsService, scFundraisingPagesService, scThemesService, scDomainsService, scAnalytics, scOrganizationsService, scSourceCodes, CFPAuthSwitchView, CFPExitView, CHAR_LIMITS) {
  'use strict';

  function createFundraisingTeamFlow(createOptions) {
    var $scope = $rootScope.$new(),
        views = [],
        tplPath = 'global/flows/create-fundraising-team/views';

    var customGoal = _.get(createOptions, 'goal', false);
    $scope.campaign = scCampaignsService.active;
    $scope.block = scBlocksService.getBlockByType('team');
    var campaignType = $scope.campaign.current.type;

    $scope.custom_url = null;

    scFundraisingTeamsService.active = new scFundraisingTeamModel({}, false);
    $scope.team = scFundraisingTeamsService.active;
    $scope.team.current = {
      description: $scope.campaign.current.default_team_appeal,
      goal: customGoal || $scope.campaign.current.default_team_goal,
      logo: $scope.block.current.defaultTeamLogo,
      campaign_id: $scope.campaign.current.id,
      team_lead_id: null
    };

    $scope.displaySubteamOptions = scOrganizationsService.active.current.tags.some(function (tag) {
      return tag === 'allow-subteams';
    });

    $scope.designationOptions = {
      fieldName: 'program',
      valueKey: 'id',
      labelKey: 'name',
      required: false,
      check: {
        enabled: true,
        condition: '$scope.form.program.$dirty && $scope.form.program.$valid && $scope.form.program.$touched'
      }
    };

    // Opt In/consent
    $scope.optIn = {
      opt_in_wording: _.get(scOrganizationsService.active, 'current.opt_in_wording', "It's okay to contact me in the future."),
      opted_in: false
    };

    // For orgs that allow subteams, default policy is to turn on subteams
    // Else, default policy is to disable subteams.
    $scope.team.current.team_policy = {
      allow_subteams: $scope.displaySubteamOptions
    };

    $scope.showQuestions = function () {
      return false;
    };

    /* -------------------------------------------------------------------- *
     * UI
     * -------------------------------------------------------------------- */

    $scope.ui = {
      processing: false,
      primaryColor: _.get(scThemesService, 'active.current.styles.primaryColor', '#36bff7'),
      questionsLoading: true,
      questionsPromise: null,
      // Show dark outline around scCurrencyPicker input on focus, hide on blur
      inputFocus: false
    };

    /* -------------------------------------------------------------------- *
     * Submit
     * -------------------------------------------------------------------- */

    function submitTeam() {
      var submissionStart = new Date().getTime();
      if ($scope.ui.processing) {
        return;
      }

      $scope.ui.processing = true;

      var member = scMembersService.you.current;

      $scope.team.current.team_lead_id = member.id;
      $scope.team.current.team_lead = member;
      $scope.team.current.answers = [];
      $scope.team.current.fundraising_page_answers = [];
      $scope.team.current.user_agent = navigator.userAgent;

      _.forEach($scope.answers, function (answer, key) {
        var qid = key.substring(4); // 'qid_xxxxx'
        var parsedAnswer = answer;
        if (answer != null && $scope.questions.length) {
          var textTypeQuestionObj = _.find($scope.questions, function (q) {
            return q.id == qid && q.type === 'text';
          });
          if (textTypeQuestionObj) parsedAnswer = answer.replace(/<\/?p>|&nbsp;/g, '').trim() || null;
          if (parsedAnswer !== null && parsedAnswer != '') {
            $scope.team.current.answers.push({
              question_id: +qid,
              answer: parsedAnswer
            });
          }
        }
      });

      _.forEach($scope.fundraising_page_answers, function (answer, key) {
        var qid = key.substring(4); // 'qid_xxxxx'
        var parsedFundraisingPageAnswer = answer;
        if (answer != null && $scope.fundraisingPageQuestions.length) {
          var textTypeQuestionObj = _.find($scope.fundraisingPageQuestions, function (q) {
            return q.id == qid && q.type === 'text';
          });
          if (textTypeQuestionObj) parsedFundraisingPageAnswer = answer.replace(/<\/?p>|&nbsp;/g, '').trim() || null;
          if (parsedFundraisingPageAnswer !== null && parsedFundraisingPageAnswer != '') {
            $scope.team.current.fundraising_page_answers.push({
              question_id: +qid,
              answer: parsedFundraisingPageAnswer
            });
          }
        }
      });

      function uploadLogo() {
        if ($scope.team.current.logo) {
          return $scope.team.current.logo.on('save');
        }
        return $q.resolve();
      }

      uploadLogo().then(function () {
        $scope.team.current.logo_id = _.get($scope, 'team.current.logo.assetId', null);
        $scope.team.current.parent_id = _.get(createOptions, 'parentId', null);
        return $scope.team.create($scope.campaign.current.id, $scope.optIn);
      }).then(function (result) {
        var submissionEnd = new Date().getTime();
        scAnalytics.formSubmitTiming({
          time: submissionEnd - submissionStart,
          form: 'Team Create',
          status: 'success'
        });

        $cacheFactory.get('$http').remove('/frs-api/campaign/' + scCampaignsService.active.current.id + '/fundraising-teams' + ('?aggregates=true&filter=team_lead_id=' + member.id));
        $cacheFactory.get('$http').remove('/frs-api/campaign/' + scCampaignsService.active.current.id + '/fundraising-pages' + ('?aggregates=true&filter=member_id=' + member.id));

        if (!!createOptions && createOptions.fundraiser) {
          scFundraisingPagesService.active.joinTeam(result.data.id);
        }

        $scope.ui.processing = false;
        var id = result.data.id;

        if ($scope.custom_url) {
          scDomainsService.postSlug('fundraising-teams', id, $scope.custom_url);
        }
        scFundraisingTeamsService.add(result.data);
        $state.go('frs.team.index', { fundraisingTeamId: id, is_new: true });

        var deregister = $scope.$on('$stateChangeSuccess', function () {
          scAnalytics.eventBeacon({
            category: 'frs ' + campaignType,
            action: 'creation',
            label: 'New Team',
            meta: scSourceCodes.getLatest($scope.campaign.current.id),
            entity_id: scFundraisingTeamsService.active.current.id,
            team: scFundraisingTeamsService.active.current
          });

          scFlowModalService.to('team.success');

          $timeout(function () {
            scFlowModalService.close(true);
            deregister();
          }, 4000);
        });
      }).catch(function () {
        $scope.ui.processing = false;
        $scope.ui.error = 'Sorry, your team could not be created right now. Please try again later.';
        var submissionEnd = new Date().getTime();
        scAnalytics.formSubmitTiming({
          time: submissionEnd - submissionStart,
          form: 'Team Create',
          status: 'failure'
        });
      });
    }

    /* -------------------------------------------------------------------- *
     * VIEWS : Details
     * -------------------------------------------------------------------- */
    views.push({
      id: 'team.details',
      templateUrl: tplPath + '/details',
      maxWidth: 400,
      context: $scope.$new(),
      eventBeacon: function eventBeacon() {
        scAnalytics.eventBeacon({
          category: 'frs ' + campaignType,
          action: 'triggers',
          label: 'Create Your Team'
        });
      }
    });

    $scope.froalaOptions = scFroalaOptions({ type: 'textarea' }, { charCounterCount: true, charCounterMax: CHAR_LIMITS.DEFAULT_PAGE_APPEAL });

    /* -------------------------------------------------------------------- *
     * VIEWS : Logo
     * -------------------------------------------------------------------- */

    views.push({
      id: 'team.logo',
      templateUrl: tplPath + '/logo',
      maxWidth: 385,
      context: $scope.$new(),
      eventBeacon: function eventBeacon() {
        scAnalytics.eventBeacon({
          category: 'frs ' + campaignType,
          action: 'triggers',
          label: 'Choose Team Photo'
        });
      }
    });

    /* -------------------------------------------------------------------- *
     * VIEWS : Logo Switch
     * -------------------------------------------------------------------- */

    views.push({
      id: 'team.logo.switch',
      templateUrl: tplPath + '/logo-switch',
      maxWidth: 415
    });

    $scope.getFacebookProfilePic = function () {
      scFacebookSDKService.initFacebook(_.get(SC, 'organization.domain_mask.facebook_app_id', 0) || _.get(SC, 'fbid', 0)).then(function (FB) {
        FB.getAuthResponse(function () {});
      });
    };

    /* -------------------------------------------------------------------- *
     * VIEWS : Media Manager
     * -------------------------------------------------------------------- */

    views.push({
      id: 'team.logo.mediaManager',
      template: "<div data-sc-media-manager='mmOptions'></div>",
      maxWidth: 980,
      context: $scope.$new()
    });

    $scope.mmOptions = {
      title: 'Choose your photo',
      image: {
        model: 'team.current.logo',
        minWidth: 300,
        minHeight: 300,
        crop: {
          width: 250,
          height: 250,
          title: 'Crop your image',
          blockClass: 'fundraising-page-logo-crop',
          cancel: 'team.logo.mediaManager'
        },
        guide: {
          title: 'Team photo',
          message: 'This will appear on your team page and in search results.',
          size: '300x300'
        }
      },
      buttons: {
        custom: {
          action: 'mmCancel()',
          text: 'Cancel'
        },
        submit: {
          action: 'mmSubmit($images)'
        }
      },
      userAccess: 'userAccess'
    };

    var deferAccess = $q.defer();
    $scope.userAccess = deferAccess.promise;

    // Update if user logs in mid-flow.
    $scope.$watch(function () {
      return _.get(scMembersService, 'active.current.id');
    }, function (ans) {
      if (ans) {
        deferAccess.resolve();
      }
    });

    $scope.mmCancel = function () {
      scFlowModalService.to('team.logo', 'slideRight');
    };

    $scope.mmSubmit = function (images) {
      $scope.team.current.logo = images[0];
      scFlowModalService.to('team.logo', 'slideRight');
    };

    /* -------------------------------------------------------------------- *
     * VIEWS : Subteams
     * -------------------------------------------------------------------- */
    if ($scope.displaySubteamOptions) {
      views.push({
        id: 'team.subteams',
        templateUrl: tplPath + '/subteams',
        maxWidth: 415,
        context: $scope.$new()
      });
    }

    // Container for all current teams
    $scope.allTeams = [];
    $scope.campaign.getFundraisingTeams('?with=team_policy').then(function (result) {
      $scope.allTeams = result.data.data.filter(function (team) {
        return team.team_policy && team.team_policy.allow_subteams;
      });
    });

    /* -------------------------------------------------------------------- *
     * VIEWS : Questions
     * -------------------------------------------------------------------- */

    var $qscope = $scope.$new();

    views.push({
      id: 'team.questions',
      templateUrl: tplPath + '/questions',
      maxWidth: 460,
      context: $qscope,
      eventBeacon: function eventBeacon() {
        scAnalytics.eventBeacon({
          category: 'frs ' + campaignType,
          action: 'triggers',
          label: 'Complete Custom Questions'
        });
      }
    });

    views.push({
      id: 'team.fundraising-page.questions',
      templateUrl: tplPath + '/fundraising-page-questions',
      maxWidth: 460,
      context: $qscope,
      eventBeacon: function eventBeacon() {
        scAnalytics.eventBeacon({
          category: 'frs ' + campaignType,
          action: 'triggers',
          label: 'Complete Required Fundraising Page Questions'
        });
      }
    });

    $scope.questions = [];
    $scope.answers = {};
    $scope.fundraising_page_answers = {};

    views.push({
      id: 'team.fundraising-page.program-designation',
      templateUrl: tplPath + '/select-program-designation',
      maxWidth: 460
    });

    /* ---------------------------------------------------------------------- *
     * Fetch questions
     * ---------------------------------------------------------------------- */
    var MAX_ATTEMPTS = 1;
    var RETRY_DELAY_MS = 500;
    var attemptCount = 0;

    function fetchQuestions() {
      $scope.ui.questionsPromise = $http({
        method: 'GET',
        url: '/frs-api/campaign/' + $scope.campaign.current.id + '/questions'
      }).then(function (response) {
        var d = $q.defer();
        $timeout(function () {
          d.resolve(response);
        }, 10000);

        return d.promise;
      }).then(function (response) {
        var _$scope$questions;

        $scope.fundraisingPageQuestions = _.filter(_.get(response, 'data.data', []), {
          location: 'page_creation'
        });

        $scope.allQuestions = _.filter(_.get(response, 'data.data', []), {
          location: 'team_creation'
        });

        $scope.customQuestions = _.sortBy(_.filter($scope.allQuestions, {
          tag: null
        }), 'weight');
        $scope.questions = _.reject($scope.allQuestions, {
          tag: null
        });
        // push custom questions inside questions to show it in the sequense on the UI
        (_$scope$questions = $scope.questions).push.apply(_$scope$questions, _toConsumableArray($scope.customQuestions));
        $scope.ui.questionsLoading = false;
        if ($scope.ui.currentView === 'team.wait') {
          if ($scope.questions.length || $scope.showQuestions()) {
            scFlowModalService.to('team.questions');
            $scope.ui.currentView = 'team.questions';
            $scope.ui.isFinalView = !getNextView();
          } else if ($scope.fundraisingPageQuestions.length) {
            scFlowModalService.to('team.fundraising-page.questions');
            $scope.ui.currentView = 'team.fundraising-page.questions';
            $scope.ui.isFinalView = !getNextView();
          } else {
            $scope.next(); // returns null, submits team
          }
        }
      }).catch(function (err) {
        err.message += '| Questions could not be loaded';
        bugsnagClient.notify(err);
        if (attemptCount < MAX_ATTEMPTS) {
          setTimeout(fetchQuestions, RETRY_DELAY_MS);
        } else {
          $scope.ui.questionsLoading = false;
        }
        attemptCount += 1;
      });
    }

    fetchQuestions();

    // Placeholder for client questions
    $scope.clientQuestionsTpl = null;

    /* ---------------------------------------------------------------------- *
     * Clean up after our naughty datepicker
     * ---------------------------------------------------------------------- */

    $qscope.questionsDisabled = function (formType) {
      var datesFilled = true;
      if (!_.get($scope, 'questions.length')) {
        return undefined;
      }

      _.forEach($scope.questions, function (question) {
        if (question.type === 'date' && question.is_required === true && question.is_deleted === false && !$scope.answers['qid_' + question.id]) {
          datesFilled = false;
        }
      });
      if (formType === 'page') {
        return $qscope.create_fundraising_page_questions.$invalid || !datesFilled;
      }
      return $qscope.create_team_questions.$invalid || !datesFilled;
    };

    /* -------------------------------------------------------------------- *
     * VIEWS : Wait
     * -------------------------------------------------------------------- */

    views.push({
      id: 'team.wait',
      templateUrl: tplPath + '/wait',
      maxWidth: 300
    });

    /* -------------------------------------------------------------------- *
     * VIEWS : Success
     * -------------------------------------------------------------------- */

    views.push({
      id: 'team.success',
      templateUrl: tplPath + '/success',
      maxWidth: 460
    });

    /* -------------------------------------------------------------------- *
     * VIEWS : Auth Switch + Exit view
     * -------------------------------------------------------------------- */

    $scope.authOptions = {
      callback: function callback() {
        $scope.ui.currentView = 'team.details';

        options.startingView = 'team.details';
        scFlowModalService.open('createFundraisingTeamFlow', options);
        scFlowModalService.whitelist('frs.team.index');
      }
    };

    $scope.flow = {
      template: function template(slug) {
        return 'global/flows/create-fundraising-page/views/' + slug + '/template';
      },
      abort: function abort() {},
      to: function to(dest) {
        scFlowModalService.to(dest);
        $scope.ui.currentView = dest;
      }
    };

    views.push(new CFPAuthSwitchView($scope));
    views.push(new CFPExitView($scope));

    /* -------------------------------------------------------------------- *
     * Navigation
     * -------------------------------------------------------------------- */

    function getNextView() {
      switch ($scope.ui.currentView) {
        case 'team.details':
          return 'team.logo';

        case 'team.auth.switch':
          return 'team.logo';

        case 'team.logo':
          if ($scope.displaySubteamOptions) {
            return 'team.subteams';
          } else if ($scope.ui.questionsLoading) {
            return 'team.wait';
          } else if ($scope.questions.length || $scope.showQuestions()) {
            return 'team.questions';
          } else if ($scope.fundraisingPageQuestions.length) {
            return 'team.fundraising-page.questions';
          } else if ($scope.campaign.current.designations_count > 1) {
            return 'team.fundraising-page.program-designation';
          }
          return null;

        case 'team.subteams':
          if ($scope.ui.questionsLoading) {
            return 'team.wait';
          } else if ($scope.questions.length || $scope.showQuestions()) {
            return 'team.questions';
          } else if ($scope.fundraisingPageQuestions.length) {
            return 'team.fundraising-page.questions';
          } else if ($scope.campaign.current.designations_count > 1) {
            return 'team.fundraising-page.program-designation';
          }
          return null;

        case 'team.questions':
          if ($scope.fundraisingPageQuestions.length) {
            return 'team.fundraising-page.questions';
          } else if ($scope.campaign.current.designations_count > 1) {
            return 'team.fundraising-page.program-designation';
          }
          return null;

        case 'team.wait':
        case 'team.fundraising-page.questions':
          return $scope.campaign.current.designations_count > 1 ? 'team.fundraising-page.program-designation' : null;

        default:
          return null;
        // do nothing
      }
    }

    $scope.next = function () {
      var nextView = getNextView();

      if (nextView) {
        scFlowModalService.to(nextView);
        $scope.ui.currentView = nextView;
        $scope.ui.isFinalView = !getNextView();
      } else {
        submitTeam();
      }
    };

    $scope.ui.currentView = 'team.details';
    $scope.ui.isFinalView = !getNextView();

    /* -------------------------------------------------------------------- *
     * Registration and Launch
     * -------------------------------------------------------------------- */
    // Need this for exit view usage
    scFlowModalService.register('createFundraisingTeamFlow', $scope, views);

    var options = {
      startingView: $scope.ui.currentView,
      animate: true,
      closable: true,
      closeOnClickOut: false,
      matte: '#f5f5f5',
      onClose: function onClose() {
        if (getNextView()) {
          $scope.ui.previousView = $scope.ui.currentView;
          $scope.flow.to('indiv.exit');
          $scope.closeOutPromise = $q.defer();
          return $scope.closeOutPromise.promise;
        }
        return undefined;
      }
    };

    if (scFlowModalService.ui.returnState !== 'frs.team.index') {
      options.returnState = 'frs.landing.name.campaign';
      options.returnParams = { campaignId: scCampaignsService.active.current.id };
    }

    $timeout(function () {
      // If there is no active user, log in/sign up first.
      if (!scMembersService.active.current.id) {
        $scope.ui.currentView = 'indiv.auth.switch';
        options.startingView = 'indiv.auth.switch';
        scFlowModalService.open('createFundraisingTeamFlow', options);
      } else {
        scFlowModalService.open('createFundraisingTeamFlow', options);
        scFlowModalService.whitelist('frs.team.index');
      }
    });

    return $scope.team;
  }

  return createFundraisingTeamFlow;
}
})();