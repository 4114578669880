(function(){
'use strict';

scFundraisersBlock.$inject = ["$animate", "$state", "$http", "$q", "scFroalaOptions", "scBlock", "scCampaignsService", "scSwitchFlowPickerService", "registrationEntityFlow", "scResponsiveWidth", "scCurrencyService", "fundraisersBlockDemoData", "classyQL", "accessibilityService", "countryBlockService"];
angular.module('classy').directive('scFundraisersBlock', scFundraisersBlock);

function scFundraisersBlock($animate, $state, $http, $q, scFroalaOptions, scBlock, scCampaignsService, scSwitchFlowPickerService, registrationEntityFlow, scResponsiveWidth, scCurrencyService, fundraisersBlockDemoData, classyQL, accessibilityService, countryBlockService) {
  return scBlock({
    link: function link(scope, element) {
      $animate.enabled(element, false);

      scope.froalaOptionsPreview = scFroalaOptions({
        type: 'edit-inline'
      }, {
        scImgAssetFroalaUpload: true,
        charCounterCount: true,
        charCounterMax: 180,
        editorClass: 'no-counter',
        ariaLabel: 'Fundraising Leaderboard Headline'
      });

      scope.isRwf = function () {
        return scCampaignsService.active.isType('reg_w_fund');
      };
      scope.registrationSwitch = function (event) {
        if (event && event.type !== 'click' && !accessibilityService.isValidKeyBoardEvent(event)) return;

        // capture event trigger to refocus on after modal is closed
        var eventTarget = _.get(event, 'currentTarget', null);
        countryBlockService.checkOrganizationRestrictions(function () {
          return registrationEntityFlow(eventTarget);
        });
      };

      scope.isMobile = scResponsiveWidth.isMobile;

      var campaign = scCampaignsService.active.current;
      scope.campaignId = campaign.id;
      scope.leaders = [];
      scope.fundraisers = [];
      scope.teams = [];
      scope.fundHalf = 0;
      scope.teamHalf = 0;
      scope.fetchNum = '5';
      scope.campaignInactive = scCampaignsService.active.isInactive();

      if (!angular.isDefined(scope.block.headline)) {
        scope.block.headline = campaign.type == 'reg_w_fund' ? '<span>Register Today</span>' : '<span>Start Fundraising</span>';
      }

      // For backwards compatibility, we need to change the headline for RwF events
      var headlineString = scope.block.headline.toString();

      if (headlineString.indexOf('Start Fundraising') > -1 && campaign.type == 'reg_w_fund') {
        scope.block.headline = scope.block.headline.toString().replace('Start Fundraising', 'Register Today');
      }

      scope.show = {
        individuals: function individuals() {
          return scope.block.showIndividuals;
        },
        individualsOnly: function individualsOnly() {
          return scope.show.individuals() && !scope.show.teams();
        },
        teams: function teams() {
          return scope.block.showTeams;
        },
        teamsOnly: function teamsOnly() {
          return scope.show.teams() && !scope.show.individuals();
        },


        // scope.leaders will always be populated, at least with placeholders, so do not
        // make the conditional dependent on scope.leaders.
        noSupporters: function noSupporters() {
          return !scope.teams.length && !scope.fundraisers.length;
        },
        topPerformers: function topPerformers() {
          return scope.block.showLeaderboard && scope.leaders.length;
        }
      };

      scope.frSubset = function () {
        if (scope.show.individualsOnly()) {
          return Math.min(5, Math.ceil(scope.fundraisers.length / 2));
        }
        return Math.min(5, scope.fundraisers.length);
      };

      scope.tmSubset = function () {
        if (scope.show.teamsOnly()) {
          return Math.min(5, Math.ceil(scope.teams.length / 2));
        }
        return Math.min(5, scope.teams.length);
      };

      var queryParams = {
        with: 'member,primary_captain',
        filter: 'status=active'
      };

      scope.searchOptionsLoaded = false;

      scope.search = {
        configuration: {
          'Fundraising Page': {
            service: 'scFundraisingPageSearchModel',
            fields: ['alias'],
            queryParams: queryParams
          },
          Fundraiser: {
            service: 'scFundraisingPageSearchModel',
            fields: ['member.first_name', 'member.last_name'],
            queryParams: queryParams
          }
        },
        subtitle: function subtitle() {
          var fundraiser = scope.show.individuals() ? 'fundraiser ' : '';
          var team = scope.show.teams() ? 'team ' : '';
          var or = fundraiser && team ? 'or ' : '';
          // the search and subtitle can still show up when fundraisers and teams are disabled
          // as such, we have to pick something to display as a fallback
          var fallback = !scope.show.teams() && !scope.show.individuals() && scope.block.showLeaderboard ? 'fundraiser ' : '';
          return 'Search for a ' + fundraiser + or + team + fallback + 'to support';
        },
        go: function go(selection, type) {
          switch (type) {
            case 'fundraising-teams':
              $state.go('frs.team.index', {
                fundraisingTeamId: selection.id
              });
              return false;
            case 'fundraising-pages':
              $state.go('frs.fundraiser.index', {
                fundraisingPageId: selection.id
              });
              return false;
            default:
              return false;
          }
        }
      };

      scope.joinButtonStyle = function () {
        if (scope.campaignInactive) {
          return {};
        }
        var style = {
          'background-color': scope.block.joinButtonColor || scope.theme.styles.primaryColor
        };

        _.merge(style, scope.block.joinButtonStyle);
        return style;
      };

      if (scope.show.individualsOnly() || scope.show.teamsOnly()) {
        scope.fetchNum = '10';
      }

      scope.fundraiseSwitchFlow = function (keyboardEvent) {
        if (keyboardEvent && !accessibilityService.isValidKeyBoardEvent(keyboardEvent)) return;
        countryBlockService.checkOrganizationRestrictions(scSwitchFlowPickerService.fundraiseSwitch);
      };

      /* -------------------------------------------------------------------------
       * Scope functions to generate leaderboard, fundraiser, and team data.
       * ------------------------------------------------------------------------- */

      scope.generateLeadersList = function () {
        var leaders = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        scope.generateTopPage(leaders);
        scope.generateTopTeam(leaders);
        scope.generateMostDonorsPage(leaders);
        scope.generateMostMembersTeam(leaders);
      };

      scope.generateTopPage = function () {
        var leaders = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        var topRaisedPage = {
          order: 1,
          title: 'Top Fundraiser',
          type: 'fundraiser',
          measure: '$',
          metric: 'raised',
          placeholder: true
        };

        if (leaders.topPage) {
          Object.assign(topRaisedPage, leaders.topPage, { placeholder: false });
        }

        scope.leaders.push(topRaisedPage);
      };

      scope.generateTopTeam = function () {
        var leaders = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        var topRaisedTeam = {
          order: 3,
          title: 'Top Team',
          type: 'team',
          measure: '$',
          metric: 'raised',
          placeholder: true
        };

        if (leaders.topTeam) {
          Object.assign(topRaisedTeam, leaders.topTeam, { placeholder: false });
        }

        scope.leaders.push(topRaisedTeam);
      };

      scope.generateMostDonorsPage = function () {
        var leaders = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        var mostDonorsPage = {
          order: 2,
          title: 'Most Donors',
          type: 'fundraiser',
          measure: '',
          metric: 'donors',
          placeholder: true
        };

        if (leaders.pageMostDonations) {
          Object.assign(mostDonorsPage, leaders.pageMostDonations, { placeholder: false });
        }

        scope.leaders.push(mostDonorsPage);
      };

      scope.generateMostMembersTeam = function () {
        var leaders = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        var mostMembersTeam = {
          order: 4,
          title: 'Most Members',
          type: 'team',
          measure: '',
          metric: 'members',
          placeholder: true
        };

        if (leaders.teamMostMembers) {
          Object.assign(mostMembersTeam, leaders.teamMostMembers, { placeholder: false });
        }

        scope.leaders.push(mostMembersTeam);
      };

      scope.setDemoData = function () {
        var campaignCurrency = scCurrencyService.getEntityCurrency(campaign);

        scope.leaders = fundraisersBlockDemoData.leaders;

        scope.teams = fundraisersBlockDemoData.teams.map(function (team) {
          return Object.assign({}, team, { raw_currency_code: campaignCurrency });
        });
        scope.teamHalf = Math.ceil(scope.teams.length / 2);
        scope.fundraisers = fundraisersBlockDemoData.fundraisers.map(function (fundraiser) {
          return Object.assign({}, fundraiser, { raw_currency_code: campaignCurrency });
        });
        scope.fundHalf = Math.ceil(scope.fundraisers.length / 2);
      };

      scope.getTopFundraisers = function () {
        if (!scope.show.individuals()) {
          return $q.resolve();
        }

        var count = scope.show.individualsOnly() ? 10 : 5;
        var topPagesQuery = '\n          query {\n            fundraisingPageSearchPublic(\n              filter: {\n                campaign_id: { eq: ' + scope.campaignId + ' },\n                goal_normalized: { gt: 0 },\n                status: { ne: deleted }\n              },\n              sort: { field: total_raised, order: DESC },\n              count: ' + count + '\n            ) {\n              results {\n                id\n                name\n                fundraising_team_id\n                campaign_id\n                organization_id\n                total_raised\n                goal_raw\n                fundraiser_profile_image\n                percent_to_goal\n                currency_code\n                normalized_currency_code\n                goal_normalized\n              }\n            }\n          }\n        ';

        return classyQL.query({ query: topPagesQuery }).then(function (_ref) {
          var data = _ref.data;

          scope.fundraisers = _.get(data, 'data.fundraisingPageSearchPublic.results', []);
          scope.fundraisers.forEach(function (fundraiser) {
            fundraiser.raw_currency_code = fundraiser.currency_code;
            fundraiser.goal = fundraiser.goal_normalized;
          });
        }).catch(function () {
          scope.fundraisers = [];
        });
      };

      scope.getTopTeams = function () {
        if (!scope.show.teams()) {
          return $q.resolve();
        }

        var count = scope.show.teamsOnly() ? 10 : 5;
        var topTeamsQuery = '\n          query {\n            fundraisingTeamSearchPublic(\n              filter: {\n                campaign_id: { eq: ' + scope.campaignId + ' },\n                status: { ne: deleted }\n              },\n              sort: { field: total_raised, order: DESC },\n              count: ' + count + '\n            ) {\n              results {\n                id\n                name\n                campaign_id\n                organization_id\n                total_raised\n                goal_raw\n                percent_to_goal\n                pages_count\n                pages_count_active\n                team_profile_image\n                currency_code\n                goal_normalized\n              }\n            }\n          }\n        ';

        return classyQL.query({ query: topTeamsQuery }).then(function (_ref2) {
          var data = _ref2.data;

          scope.teams = _.get(data, 'data.fundraisingTeamSearchPublic.results', []);
          scope.teams.forEach(function (team) {
            team.raw_currency_code = team.currency_code;
            team.goal = team.goal_normalized;
          });

          scope.search.configuration['Fundraising Team'] = {
            service: 'scFundraisingTeamSearchModel',
            fields: ['name'],
            queryParams: queryParams
          };

          scope.search.configuration['Team Captain'] = {
            service: 'scFundraisingTeamSearchModel',
            fields: ['primary_captain.first_name', 'primary_captain.last_name'],
            queryParams: queryParams
          };
        }).catch(function () {
          scope.teams = [];
        });
      };

      /* -------------------------------------------------------------------------
       * Initialize leaderboard, fundraiser, and team data.
       * ------------------------------------------------------------------------- */

      if (scope.demo) {
        scope.setDemoData();
      } else {
        var teamsPromise = scope.getTopTeams();
        var pagesPromise = scope.getTopFundraisers();
        $q.all([teamsPromise, pagesPromise]).then(function () {
          scope.searchOptionsLoaded = true;
        });

        // LEADERBOARD
        $http.get('/frs-api/campaigns/' + scope.campaignId + '/leaderboard').then(function (response) {
          scope.generateLeadersList(response.data);
        }).catch(function () {});
      }
    }
  });
}
})();