(function(){
'use strict';

FeedItemController.$inject = ["$scope", "$http", "$state", "$log", "scFeedItemText", "scMembersService", "scThemesService", "scFundraisingPagesService", "scFundraisingTeamsService", "scPageAccessService", "scFlowModalService", "accessibilityService"];
function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

/**
 * Directive for displaying a feed item.
 * @name scFeedItem
 */
angular.module('classy').directive('scFeedItem', scFeedItem);

function scFeedItem() {
  return {
    scope: {
      feedItem: '=scFeedItem',
      theme: '=',
      block: '=',
      me: '=',
      fundraisingDashboard: '=',
      allowCommenting: '='
    },
    templateUrl: 'global/objects/scBlock/shared/feed/feed-item',
    controller: FeedItemController
  };
}

/**
 *
 */
function FeedItemController($scope, $http, $state, $log, scFeedItemText, scMembersService, scThemesService, scFundraisingPagesService, scFundraisingTeamsService, scPageAccessService, scFlowModalService, accessibilityService) {
  var vm = $scope;

  var currentTimeISO = moment.utc().format();
  var page = 1;

  // Properties
  vm.commentOpen = false;
  vm.form = {};
  vm.form.commentBody = '';
  vm.me = scMembersService.you.current;
  vm.theme = scThemesService.active.current;

  vm.feedItem.comments = vm.feedItem.latest_comments || [];
  vm.feedItem.open = false;
  // only show the name if the transaction is not anonymous
  vm.feedItem.showName = !vm.feedItem.linkable || !vm.feedItem.linkable.is_anonymous;

  // Methods
  vm.clickComment = clickComment;
  vm.closeComment = closeComment;
  vm.getActionText = scFeedItemText;
  vm.min = Math.min;
  vm.showMoreComments = showMoreComments;
  vm.submitComment = submitComment;
  vm.removeFeedItem = removeFeedItem;
  vm.showImage = showImage;
  vm.getMemberFirstInitial = scMembersService.getMemberFirstInitial;

  vm.displayLink = false;
  var linkType = vm.feedItem.linkable_type;
  var feedType = vm.feedItem.type;

  if (feedType === 'creation' && (linkType === 'fundraising_team' || linkType === 'fundraising_page')) {
    vm.displayLink = true;
  }

  vm.canManage = scPageAccessService.pageAccess;
  if (!vm.canManage && $state.current.name.indexOf('fundraiser') > -1) {
    vm.canManage = scFundraisingPagesService.active.checkDashboardAccess();
  } else if (!vm.canManage && $state.current.name.indexOf('team') > -1) {
    scFundraisingTeamsService.active.checkDashboardAccess().then(function (result) {
      vm.canManage = result;
    });
  }

  if (vm.feedItem.commentable_type === 'update') {
    vm.feedItem.type = 'comment';
    vm.feedItem.feedable_type = 'update';
  }

  if (vm.feedItem.feedable_type === 'fundraising_team') {
    vm.feedItem.createImg = SC.team && SC.team.logo ? SC.team.logo.cdn_url : '/static/global/images/team_default_image.png';
    vm.feedItem.fallbackImg = '/static/global/images/team_default_image.png';
  } else if (vm.feedItem.feedable_type === 'fundraising_page') {
    vm.feedItem.createImg = SC.fundraiser && SC.fundraiser.logo ? SC.fundraiser.logo.cdn_url : '/static/global/images/individual_default_image.png';
    vm.feedItem.fallbackImg = '/static/global/images/higher_contrast_individual_default_image.png';
  } else {
    vm.feedItem.fallbackImg = '/static/global/images/individual_default_image.png';
  }

  // Watch for change in member when logging in
  vm.$watch(function () {
    return _.get(scMembersService, 'active.current');
  }, function (newVal, oldVal) {
    if (newVal !== oldVal) {
      vm.me = newVal;

      if ($state.current.name.indexOf('fundraiser') > -1) {
        vm.canManage = scFundraisingPagesService.active.checkDashboardAccess();
      } else if ($state.current.name.indexOf('team') > -1) {
        scFundraisingTeamsService.active.checkDashboardAccess().then(function (result) {
          vm.canManage = result;
        });
      }
    }
  });

  // If a feedItem relates to a business (matching plan), we will need to render a proper
  // logo for it. However, non-campaign feedable types will already have an avatar associated
  // with them. Thus, we only want to request a logo if this is a campaign feedable type.
  if (vm.feedItem.agent_type === 'business' && vm.feedItem.feedable_type === 'campaign') {
    $http({
      method: 'GET',
      url: '/frs-api/campaign/' + vm.feedItem.feedable_id + '/business-logo?filter=business_id=' + encodeURIComponent(vm.feedItem.agent_id),
      loadingScope: true
    }).then(function (resp) {
      vm.feedItem.member = { thumbnail_medium: null };
      vm.feedItem.member.thumbnail_medium = _.get(resp, 'data.cdn_url', null);
    });
  }
  if (vm.feedItem.agent_type === 'business' && (vm.feedItem.feedable_type === 'fundraising_page' || vm.feedItem.feedable_type === 'fundraising_team')) {
    $http({
      method: 'GET',
      url: '/frs-api/campaign/' + vm.feedItem.linkable.campaign_id + '/business-logo?filter=business_id=' + encodeURIComponent(vm.feedItem.agent_id),
      loadingScope: true
    }).then(function (resp) {
      vm.feedItem.member = { thumbnail_medium: null };
      vm.feedItem.member.thumbnail_medium = _.get(resp, 'data.cdn_url', null);
    }).catch(function (error) {
      $log.log(error);
    });
  }

  function showImage() {
    return !vm.feedItem.is_anonymous && vm.feedItem.showName && vm.feedItem.member && vm.feedItem.member.thumbnail_medium;
  }

  vm.feedable = vm.feedItem.feedable_type !== 'update';

  function removeFeedItem(commentId) {
    vm.comment = commentId;
    vm.$emit('removeFeedItem', vm.comment);
  }

  function closeComment() {
    vm.commentOpen = false;
  }

  function clickComment() {
    if (vm.commentOpen) {
      vm.commentOpen = false;
    } else {
      vm.commentOpen = true;
      // not sure we should clear a comment just if they accidentally click comment again
      // vm.form.commentBody = '';
      vm.focusComment = true;
    }
  }

  vm.deleteComment = function (commentId, feedable) {
    $scope.commentId = commentId;
    $scope.feedable = feedable;

    scFlowModalService.open({
      templateUrl: 'global/objects/scBlock/shared/feed/delete-comment-modal',
      context: $scope,
      maxWidth: 320
    });
  };

  vm.keyPressed = function (event, commentId, feedable) {
    if (event.keyCode === accessibilityService.CONSTANTS.ENTER_KEY || event.keyCode === accessibilityService.CONSTANTS.SPACE_KEY) {
      if (commentId) vm.confirmDelete(commentId, feedable);else $scope.$root.flowModal.close(false);
      document.querySelector('.activity_comment-input').focus();
    }
  };

  vm.confirmDelete = function (id, isFeedItem) {
    $scope.loading = true;

    if (isFeedItem) {
      $http.delete('/frs-api/feed-items/' + id).then(function () {
        $scope.removeFeedItem(id);
      }, function () {});
    } else {
      $http.delete('/frs-api/comments/' + id).then(function () {
        if (vm.feedItem.feedable_type == 'update') {
          $scope.removeFeedItem(id);
        } else {
          var comments = vm.feedItem.comments;

          _.remove(comments, function (el) {
            return el.id == id;
          });

          vm.feedItem.comments_count -= 1;
          vm.feedItem.visibleComments -= 1;
        }
      }, function () {});
    }

    scFlowModalService.close();
    $scope.loading = false;
  };

  function showMoreComments() {
    var itemQuery = {
      per_page: 10,
      page: page,
      sort: 'created_at:desc',
      filter: 'created_at<' + currentTimeISO
    };

    $http.get('/frs-api/feed-items/' + vm.feedItem.id + '/comments', { params: itemQuery }).then(function (response) {
      var collection = response.data;
      var comments = vm.feedItem.comments;
      if (page === 1) {
        comments.push.apply(comments, _toConsumableArray(collection.data.slice(comments.length)));
      } else {
        comments.push.apply(comments, _toConsumableArray(collection.data));
      }

      page += 1;
    }, function () {});
  }

  /**
   * @param {FeedItem}
   */
  function submitComment() {
    if (!vm.form.commentBody) {
      return;
    }

    var content = vm.form.commentBody;

    vm.commentOpen = false;
    vm.form.commentBody = '';

    var comment = {
      body: content,
      member: vm.me,
      provisional: true
    };

    var newLength = vm.feedItem.comments.unshift(comment);
    vm.feedItem.visibleComments += 1;
    vm.feedItem.comments_count += 1;

    var cmtIndex = newLength - 1;

    $http.post('frs-api/feed-items/' + vm.feedItem.id + '/comments', {
      body: content
    }).then(function (response) {
      _.merge(comment, response.data);
      comment.provisional = false;
    }, function () {
      vm.feedItem.comments.splice(cmtIndex, 1);
      vm.feedItem.comments_count -= 1;
      vm.feedItem.visibleComments -= 1;

      vm.commentOpen = true;
      vm.form.commentBody = content;
    });
  }
}
})();