(function(){
'use strict';

globalConfig.$inject = ["$compileProvider", "$httpProvider", "loadGoogleMapsAPIProvider", "pickADateProvider", "UIRouterMetatagsProvider", "usSpinnerConfigProvider", "$urlMatcherFactoryProvider"];
angular.module('classy').config(globalConfig);

function globalConfig($compileProvider, $httpProvider, loadGoogleMapsAPIProvider, pickADateProvider, UIRouterMetatagsProvider, usSpinnerConfigProvider, $urlMatcherFactoryProvider) {
  var fbid = _.get(SC, 'fbid', 0);
  $urlMatcherFactoryProvider.strictMode(false);
  $httpProvider.interceptors.push('interceptorFactory');

  $httpProvider.interceptors.push(['$q', '$log', '$rootScope', 'EmbeddedGiving', function ($q, $log, $rootScope, EmbeddedGiving) {
    return {
      responseError: function responseError(rejection) {
        if (rejection.config.method != 'GET') {
          var errorMsg = "I'm sorry, you've encountered an error: ";

          if (rejection.data && rejection.data.error) {
            var rejectionError = void 0;

            // Display error, if error is string and does not involve a SQL
            // error or is under 200 characters.
            // Otherwise, instruct to check console for more details.
            if (_.isString(rejection.data.error) && rejection.data.error.indexOf('SQLSTATE') < 0 && rejection.data.error.length <= 200) {
              rejectionError = rejection.data.error;
            } else if (_.isObject(rejection.data.error)) {
              rejectionError = _.isString(_.values(rejection.data.error)[0][0]) ? _.values(rejection.data.error)[0][0] : 'Check console for more details';
            } else {
              rejectionError = 'Check console for more details';
            }
            errorMsg += '&nbsp;(' + rejectionError + ').&nbsp;';
          }
          errorMsg += " Please contact <a href='https://www.classy.org/help' target='_blank'>customer support</a>.";

          var isAnalyticsError = _.get(rejection, 'config.url', '').includes('analytics');

          $log.error('\n>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ERROR >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>\n', rejection, '\n>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');

          if (!isAnalyticsError && !EmbeddedGiving.isEnabled) {
            $rootScope.SC.status.banner = {
              type: 'error',
              msg: errorMsg
            };
          }
        }

        return $q.reject(rejection);
      }
    };
  }]);

  $httpProvider.defaults.xsrfCookieName = 'CSRF-TOKEN';

  usSpinnerConfigProvider.setTheme('mediumWhite', {
    radius: 6,
    width: 4,
    length: 5,
    lines: 10,
    color: '#ffffff',
    top: '50%'
  });
  usSpinnerConfigProvider.setTheme('smallGreen', {
    radius: 4,
    width: 3,
    length: 4,
    lines: 10,
    color: '#74d8c9',
    top: '42%'
  });

  pickADateProvider.setOptions({
    format: 'mmmm d, yyyy',
    selectYears: true
  });

  // This allows us to use our social share links event though angular considers them unsafe
  $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|sms|imessage|mailto|tel):/);

  // Default meta tag settings
  UIRouterMetatagsProvider.setDefaultTitle('Classy').setStaticProperties({
    'fb:app_id': fbid,
    'og:site_name': 'Classy',
    'og:type': 'website',
    'twitter:card': 'summary'
  }).setOGURL(true);

  // Set proper API keys/IDs
  loadGoogleMapsAPIProvider.setKey(SC.gapi);
}
})();