(function(){
'use strict';

angular.module('classy').directive('scPercentageDisplay', scPercentageDisplay);

function scPercentageDisplay() {
  return {
    require: 'ngModel',

    scope: {
      min: '<?',
      max: '<?'
    },

    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, ngModel) {
      ngModel.$parsers.push(function (value) {
        return Number(value.replace(/[^0-9.]/g, ''));
      });
      ngModel.$formatters.push(function () {
        return ngModel.$invalid ? ngModel.$viewValue : ngModel.$modelValue + '%';
      });

      /* ------------------------------------------------------------------ *
       * Max and min validators.
       * ------------------------------------------------------------------ */
      if (!_.isUndefined(scope.min)) {
        ngModel.$validators.min = function (value) {
          return value >= scope.min;
        };
      }

      if (!_.isUndefined(scope.max)) {
        ngModel.$validators.max = function (value) {
          return value <= scope.max;
        };
      }

      element.on('change', function () {
        ngModel.$processModelValue();
      });
    }
  };
}
})();