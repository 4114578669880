(function(){
'use strict';

designationsService.$inject = ["$http", "$q"];
function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

angular.module('classy').service('designationsService', designationsService);

function designationsService($http, $q) {
  var service = {};

  service.createCampaignDesignation = function (campaignId, designationId, data) {
    return $http({
      method: 'POST',
      url: '/frs-api/campaign/' + campaignId + '/designation/' + designationId,
      data: data,
      loadingScope: true
    });
  };

  service.updateCampaignDesignation = function (campaignId, designationId, data) {
    return $http({
      method: 'PUT',
      url: '/frs-api/campaign/' + campaignId + '/designation/' + designationId,
      data: data,
      loadingScope: true
    });
  };

  service.deleteCampaignDesignation = function (campaignId, designationId) {
    return $http({
      method: 'DELETE',
      url: '/frs-api/campaign/' + campaignId + '/designation/' + designationId,
      loadingScope: true
    });
  };

  service.getUnassignedCampaignDesignations = function (campaignId, params) {
    return $http({
      method: 'GET',
      url: '/frs-api/campaigns/' + campaignId + '/unassigned-designations',
      loadingScope: true,
      params: params
    });
  };

  service.getCampaignDesignations = function (campaignId, params) {
    return $http({
      method: 'GET',
      url: '/frs-api/campaigns/' + campaignId + '/designations',
      loadingScope: true,
      params: params
    });
  };

  service.getDesignationGroups = function (campaignId, params) {
    return $http({
      method: 'GET',
      url: '/frs-api/campaigns/' + campaignId + '/groups',
      loadingScope: true,
      params: params
    });
  };

  service.getDesignationsForGroup = function (groupId, params) {
    return $http({
      method: 'GET',
      url: '/frs-api/groups/' + groupId + '/designations',
      loadingScope: true,
      params: params
    });
  };

  service.getGroupedCampaignDesignations = function (campaignId, filters) {
    var designations = [];
    return service.getCampaignDesignations(campaignId, filters).then(function (designationsResp) {
      designations = designationsResp.data.data;
      var uniqueGroups = [].concat(_toConsumableArray(new Set(designations.map(function (d) {
        return d.designation_group_id;
      }))));
      return Promise.all(uniqueGroups.map(function (groupId) {
        return service.getDesignationGroups(campaignId, { filter: 'id=' + groupId });
      }));
    }).then(function (groupsResp) {
      return groupsResp.map(function (gr) {
        var group = gr.data.data[0];
        group.designations = designations.filter(function (d) {
          return d.designation_group_id === group.id;
        });
        return group;
      }).sort(function (a, b) {
        return a.sequence - b.sequence;
      });
    });
  };

  service.createDesignationGroup = function (campaignId, data) {
    return $http({
      method: 'POST',
      url: '/frs-api/campaigns/' + campaignId + '/groups',
      loadingScope: true,
      data: data
    });
  };

  service.deleteDesignationGroup = function (groupid) {
    return $http({
      method: 'DELETE',
      url: '/frs-api/groups/' + groupid,
      loadingScope: true
    });
  };

  service.updateDesignationGroup = function (groupid, data) {
    return $http({
      method: 'PUT',
      url: '/frs-api/groups/' + groupid,
      loadingScope: true,
      data: data
    });
  };

  service.getOrganizationDesignations = function (orgId, perPage, page, filter) {
    return $http({
      method: 'GET',
      url: '/frs-api/organizations/' + orgId + '/designations',
      loadingScope: true,
      params: {
        per_page: perPage,
        page: page,
        filter: filter || null
      }
    });
  };

  service.handleBatch = function (campaignId, designations) {
    var numberOfChunks = Math.ceil(designations.length / 50);
    var addDesignationsToChunk = function addDesignationsToChunk(_, i) {
      var start = i * 50;
      var end = start + 50;
      return designations.slice(start, end);
    };
    var chunks = Array(numberOfChunks).fill().map(addDesignationsToChunk);

    return chunks.reduce(function (prev, curr) {
      return prev.then(function () {
        return $http({
          method: 'POST',
          url: '/frs-api/campaign/' + campaignId + '/designation-batch',
          data: {
            designations: curr
          }
        });
      });
    }, $q.resolve());
  };

  service.parseSortOrder = function (designationSortOrder) {
    if (designationSortOrder === 'OLD-NEW') {
      return 'created_at:asc';
    } else if (designationSortOrder === 'NEW-OLD') {
      return 'created_at:desc';
    } else if (designationSortOrder === 'A-Z') {
      return 'name:asc';
    }
    return null;
  };

  return service;
}
})();