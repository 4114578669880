(function(){
'use strict';

/*
 * EmbeddedGivingLocations houses all logic interacting with the URL bar.
 * For EG, this consists of managing its 2 primary params:
 * 1) 'eg'
 * 2) 'egp'
 * 'egp' stands for "Embedded Giving Parameter" and is coupled with the 3 primary views.
 */
angular.module('classy').service('EmbeddedGivingLocations', ["$location", "$state", "EmbeddedGiving", function ($location, $state, EmbeddedGiving) {
  // Looks at url for param 'egp' and returns its view
  // If 'egp' param does not exist, then returns default view (defined by Manager)
  this.getViewByParam = function () {
    var param = $location.search().egp;
    var view = null;

    if (param) {
      view = EmbeddedGiving.getViewByParam(param); // NOTE: can return undefined, which is why this isn't an if/else statemtn
    }
    if (!view) {
      view = EmbeddedGiving.defaultView;
    }

    return view;
  };

  // Update Embedded Giving 'egp' param
  // NOTE: only updates param on change
  this.updateParam = function (param) {
    var currentParam = $location.search().egp;
    if (currentParam !== param) {
      $state.go('frs.donation.checkout', { egp: param }, { notify: false });
    }
  };
}]);
})();