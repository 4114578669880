(function(){
'use strict';

angular.module('classy').constant('EG_EVENTS', {
  donation: 'embedded-giving:modal:root',
  donationList: 'embedded-giving:modal:payment-methods',
  donationDetails: 'embedded-giving:modal:payment-method-details',
  contact: 'embedded-giving:modal:contact',
  fullAddress: 'embedded-giving:modal:contact-address',
  recurringNudge: 'embedded-giving:modal:recurring-nudge',
  thankYou: 'embedded-giving:modal:thank-you',
  faq: 'embedded-giving:modal:faq',
  // Events from SDK
  modalOpen: 'embedded-giving:modal:track-event:open',
  modalClose: 'embedded-giving:modal:track-event:close'
}).constant('EG_STATUSES', {
  disabled: 'disabled',
  modal: 'modal',
  inline: 'inline'
});
})();