(function(){
'use strict';

scFacebookService.$inject = ["$q", "$window", "scFacebookSDKService"];
angular.module('classy').service('scFacebook', scFacebookService);

function scFacebookService($q, $window, scFacebookSDKService) {
  var fbId = _.get(SC, 'organization.domain_mask.facebook_app_id', 0) || _.get(SC, 'fbid', 0);

  function popupVars(width, height) {
    var availWidth = $window.innerWidth || document.documentElement.clientWidth || window.screen.width;

    var availHeight = $window.innerHeight || document.documentElement.clientHeight || window.screen.height;

    var left = _.round((availWidth - width) / 2);
    var top = _.round((availHeight - height) / 2);
    var str = 'toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no';
    str += ',left=' + left;
    str += ',top=' + top;
    str += ',height=' + height;
    str += ',width=' + width;
    return str;
  }

  this.authenticate = function (fbAppId, fbFundraiser) {
    var deferred = $q.defer();
    var api = void 0;
    scFacebookSDKService.initFacebook(fbAppId).then(function (FB) {
      api = FB;
      FB.getLoginStatus(function (response) {
        if (response.status === 'connected') {
          deferred.resolve(api);
        } else {
          FB.login(function (loginResponse) {
            if (loginResponse.status === 'connected') {
              deferred.resolve(api);
            } else {
              deferred.reject();
            }
          }, { scope: fbFundraiser ? 'manage_fundraisers' : 'public_profile' });
        }
      });
    });
    return deferred.promise;
  };

  this.getProfilePicture = function () {
    var deferred = $q.defer();
    this.authenticate(fbId).then(function (FB) {
      FB.api('/me/picture?redirect=false&type=square&height=300&width=300', function (response) {
        deferred.resolve(response);
      });
    }).catch(function () {
      deferred.reject();
    });
    return deferred.promise;
  };

  this.share = function (url, title) {
    var fullUrl = 'http://www.facebook.com/sharer/sharer.php';
    fullUrl += '?u=' + encodeURIComponent(url);
    if (title) {
      fullUrl += '&title=' + encodeURIComponent(title);
    }
    $window.open(fullUrl, 'facebook_share', popupVars(555, 380));
    return $q.resolve();
  };

  this.postToFeed = function (options) {
    var deferred = $q.defer();
    options.method = 'feed';
    options.display = 'popup';

    scFacebookSDKService.initFacebook(fbId).then(function (FB) {
      FB.ui(options, function (response) {
        deferred.resolve(response);
      });
    });
  };
}
})();