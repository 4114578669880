(function(){
'use strict';

angular.module('classy').component('cpPaypalWidget', {
  require: {
    ngModelCtrl: 'ngModel'
  },
  template: '\n    <div class="cp-paypal-widget">\n      <button\n        data-tracked-element="donation-page_checkout_paypal-btn" \n        type="button"\n        ng-click="$ctrl.openModal()"\n        ng-class="{\'logged-in\': $ctrl.model.status === $ctrl.constants.STATUS.SUCCESS}"\n        class="payment-method-select-btn__paypal"\n        id="btPaypal"\n        ng-show="$ctrl.model.status !== $ctrl.constants.STATUS.INACTIVE"\n      ><img ng-src="/static/global/images/payments/logo-paypal.svg" alt="PayPal"</button>\n    </div>\n  ',
  bindings: {
    model: '<',
    onReady: '&',
    onSuccess: '&',
    onCancel: '&',
    currency: '<'
  },
  controller: ["$window", "cpAPI", "cpConstants", "scDigitalWalletsService", "$rootScope", function cpPaypalWidget($window, cpAPI, cpConstants, scDigitalWalletsService, $rootScope) {
    var _this = this;

    this.constants = cpConstants;
    this.config = {};

    this.$onChanges = function () {
      if (this.currency) {
        this.setupPaypal();
      }
    };

    this.openModal = function () {
      _this.onCancel();
      if (!_this.paypal) return;
      _this.paypal.initAuthFlow();
    };

    $rootScope.$on('openPaymentModal', function (_event, paymentMethod) {
      if (paymentMethod === _this.constants.PAYMENT_METHODS.PAYPAL) {
        _this.openModal();
      }
    });

    this.setupPaypal = function () {
      var _this2 = this;

      cpAPI.getBraintreeToken(this.currency).then(function (token) {
        $window.braintree.setup(token, 'custom', {
          paypal: {
            headless: true,
            onCancelled: _this2.onPaypalCancel
          },
          onPaymentMethodReceived: function onPaymentMethodReceived(params) {
            _this2.model.status = cpConstants.STATUS.SUCCESS;
            _this2.model.nonce = params.nonce;
            _this2.onSuccess({ params: params });

            _this2.ngModelCtrl.$setValidity('required', true);
            _this2.ngModelCtrl.$setDirty();
          },
          onReady: function onReady(params) {
            _this2.paypal = params.paypal;
            _this2.teardown = params.teardown;
            _this2.onReady();
          }
        });
      });

      this.onPaypalCancel = function () {
        _this2.model.status = cpConstants.STATUS.READY;
        _this2.model.nonce = null;
        _this2.onCancel();

        _this2.ngModelCtrl.$setViewValue(null);
        _this2.ngModelCtrl.$setPristine();
        _this2.ngModelCtrl.$setUntouched();
      };

      scDigitalWalletsService.addOnDigitalWalletsSubmitListener(function () {
        if (_this2.model.status === cpConstants.STATUS.SUCCESS) {
          _this2.onPaypalCancel();
        }
      });
    };
  }]
});
})();