(function(){
'use strict';

scCurrency.$inject = ["$filter"];
var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

/**
 * CURRENCY VALIDATION GUIDELINES
 *
 * (1) Do not prevent keystrokes.
 *
 * (2) On blur, if the entered text contains any numbers, strip other
 *     characters and format as currency.
 *
 * (3) On blur, if the entered text contains no numbers, retain entered text
 *     but invalidate.
 *
 * (4) Fail only one validator at a time.
 *
 * (5) Format 0 to "0" rather than "0.00".
 */
angular.module('classy').directive('scCurrency', scCurrency);

function scCurrency($filter) {
  return {
    require: 'ngModel',

    scope: {
      min: '<?',
      max: '<?',
      allowNull: '<?',
      round: '<?roundCurrency',
      symbol: '@?'
    },

    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, ngModel) {
      var SYMBOL = scope.symbol || '';

      /* ------------------------------------------------------------------ *
       * parseViewValue
       *
       * Attempt to store view value as a number. If this is not possible,
       * return undefined to trigger ngModel.$error.parse.
       * ------------------------------------------------------------------ */

      function parseViewValue(value) {
        var parsed = parseFloat(value.replace(/[^0-9.]/g, ''));

        // No value entered; empty but not a parse error.
        if (value === '') {
          return null;
        }

        // Value entered but not parsable; parse error.
        if (_.isNaN(parsed)) {
          return undefined;
        }

        // Parsable value and we need to round to integer.
        if (scope.round) {
          return Math.round(parsed);
        }

        // Parsable value and we need to round to hundredth of integer.

        return Math.round(parsed * 100) / 100;
      }

      /* ------------------------------------------------------------------ *
       * formatModelValue
       *
       * Format model as currency for display in the input.
       * ------------------------------------------------------------------ */

      function formatModelValue(value) {
        switch (typeof value === 'undefined' ? 'undefined' : _typeof(value)) {
          // Format and round numbers (0 is always rounded).
          case 'number':
            return $filter('currency')(value, SYMBOL, scope.round ? 0 : 2);

          // Attempt to interpret stored strings. Strings that cannot be
          // interpreted are rendered as-is.
          case 'string':
            {
              var converted = parseFloat(value);
              if (value === '') {
                return '';
              } else if (_.isNaN(converted)) {
                return value;
              }
              return formatModelValue(converted);
            }

          // Clear other data types.
          default:
            return '';
        }
      }

      /* ------------------------------------------------------------------ *
       * formatViewValue
       *
       * Called on blur.
       * ------------------------------------------------------------------ */

      function formatViewValue() {
        // For parsing errors, keep the view value as-is.
        if (ngModel.$error.parse) {
          return;
        }

        // Otherwise, run the view value through both parsing and formatting
        // pipelines. This ensures that values that fail min or max are still
        // auto-formatted on blur, just like valid values.
        var newVal = formatModelValue(parseViewValue(ngModel.$viewValue));
        ngModel.$setViewValue(newVal);
        ngModel.$render();
      }

      /* ------------------------------------------------------------------ *
       * Basic lifecycle
       * ------------------------------------------------------------------ */

      // All non-numeric empty values count as "empty."
      ngModel.$isEmpty = function (value) {
        return value === undefined || value === null || value === '';
      };

      // Convert entered text to a number.
      ngModel.$parsers.push(parseViewValue);

      // Convert model value to a currency-formatted string.
      ngModel.$formatters.push(formatModelValue);

      // Reformat to currency on blur.
      element.on('blur', formatViewValue);

      /* ------------------------------------------------------------------ *
       * Replicate max and min validators
       * ------------------------------------------------------------------ */

      if (!_.isUndefined(scope.min)) {
        ngModel.$validators.min = function (value) {
          if (scope.allowNull && value === null) return true;
          if (ngModel.$isEmpty(value)) return null;
          return value >= scope.min;
        };
      }

      if (!_.isUndefined(scope.max)) {
        ngModel.$validators.max = function (value) {
          if (scope.allowNull && value === null) return true;
          if (ngModel.$isEmpty(value)) return null;
          return value <= scope.max;
        };
      }
    }
  };
}
})();