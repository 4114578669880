(function(){
'use strict';

angular.module('classy').directive('scCopyright', scCopyright);

function scCopyright() {
  return {
    scope: {
      termsLink: '@',
      privacyLink: '@'
    },
    templateUrl: 'global/directives/scCopyright/scCopyright',

    link: function link(scope) {
      if (!scope.termsLink) {
        scope.termsLink = 'https://www.classy.org/terms';
      }

      if (!scope.privacyLink) {
        scope.privacyLink = 'https://www.classy.org/terms/privacy';
      }

      scope.year = new Date().getFullYear();
    }
  };
}
})();