(function(){
'use strict';

frsDonationDedicationCtrl.$inject = ["$scope", "scFlowModalService"];
angular.module('classy').controller('frsDonationDedicationCtrl', frsDonationDedicationCtrl);

function frsDonationDedicationCtrl($scope, scFlowModalService) {
  'use strict';

  /* ---------------------------------------------------------------------- *
   * Constants
   * ---------------------------------------------------------------------- */

  $scope.CONSTANTS = {
    ADDRESS_REQUIRED_ERROR_TEXT: 'Don\'t forget to include a dedication street address.',
    COUNTRY_REQUIRED_ERROR_TEXT: 'Don\'t forget to include a dedication country.',
    CITY_REQUIRED_ERROR_TEXT: 'Don\'t forget to include a dedication city.',
    EMAIL_ADDRESS_FORMAT_ERROR_TEXT: 'Please enter dedication recipient email in the format: name@domain.com.',
    NAME_REQUIRED_ERROR_TEXT: 'Don\'t forget to give us a name for the dedication.',
    OPTIONAL_RECIPIENT_EMAIL: 'Optionally send a notification email'
  };

  /* ---------------------------------------------------------------------- *
   * Setup
   * ---------------------------------------------------------------------- */

  $scope.setup = {
    country: function country() {
      return $scope.CURRENCY.code === 'CAD' ? 'CA' : 'US';
    }
  };

  /* ---------------------------------------------------------------------- *
   * UI State
   * ---------------------------------------------------------------------- */

  $scope.state = {
    dedicationFormEnabled: false,
    postcardEnabled: false
  };

  /* ---------------------------------------------------------------------- *
   * Collections
   * ---------------------------------------------------------------------- */

  $scope.list = {
    ecards: $scope.campaign.current.ecards
  };

  /* ---------------------------------------------------------------------- *
   * Display flags
   * ---------------------------------------------------------------------- */

  $scope.show = {
    dedicationForm: function dedicationForm() {
      return $scope.state.dedicationFormEnabled;
    },
    emailForm: function emailForm() {
      return $scope.campaign.current.send_dedication_emails;
    },
    postcardToggle: function postcardToggle() {
      return $scope.campaign.current.offer_dedication_postal_notifications;
    },
    postcardForm: function postcardForm() {
      return $scope.state.postcardEnabled;
    },
    states: function states() {
      return $scope.dedicationModel.country === 'US';
    },
    provinces: function provinces() {
      return $scope.dedicationModel.country === 'CA';
    },
    ecardsForm: function ecardsForm() {
      return $scope.campaign.current.allow_ecards;
    },
    requiredDedicationInfo: function requiredDedicationInfo() {
      return $scope.dedicationModel.message || $scope.dedicationModel.email_address || $scope.dedicationModel.name || $scope.dedicationModel.ecard_id;
    }
  };

  /* ---------------------------------------------------------------------- *
   * Constructed bindings
   * ---------------------------------------------------------------------- */

  $scope.build = {
    recipientInfoRequiredError: function recipientInfoRequiredError() {
      var name = $scope.UTIL.showError('dedicationForm.name', 'required') ? 'name ' : '';
      var email = $scope.UTIL.showError('dedicationForm.email_address', 'required') ? 'email ' : '';
      var and = name && email ? 'and ' : '';
      return 'Don\'t forget to include the ' + name + and + email + 'of your recipient.';
    },
    regionLabel: function regionLabel() {
      if ($scope.dedicationModel.country === 'US') {
        return 'State & Zip';
      } else if ($scope.dedicationModel.country === 'CA') {
        return 'Province & Postal Code';
      }

      return 'Postal Code';
    },
    zipPlaceholder: function zipPlaceholder() {
      if ($scope.dedicationModel.country === 'US') {
        return 'ZIP';
      }

      return 'Postal Code';
    },
    regionType: function regionType() {
      if ($scope.dedicationModel.country === 'US') {
        return 'state';
      } else if ($scope.dedicationModel.country === 'CA') {
        return 'province';
      }

      return '';
    },
    postalType: function postalType() {
      if ($scope.dedicationModel.country === 'US') {
        return 'ZIP code';
      }

      return 'postal code';
    },
    statePostalCodeError: function statePostalCodeError() {
      var state = $scope.UTIL.showError('dedicationForm.state', 'required') ? $scope.build.regionType() : '';
      var postalCode = $scope.UTIL.showError('dedicationForm.postal_code', 'required') ? $scope.build.postalType() : '';
      var and = state && postalCode ? ' and ' : '';

      return 'Don\'t forget to include a ' + state + and + postalCode + '.';
    },
    previewLink: function previewLink() {
      // TODO: Surely Angular has an app for that
      var stripTags = function stripTags(input) {
        return input.replace(/(<([^>]+)>)/gi, '');
      };

      var message = void 0;

      if ($scope.dedicationModel.ecard_message) {
        message = stripTags($scope.dedicationModel.ecard_message).replace(/(?:\r\n|\r|\n)/g, '<br />');
      } else {
        message = 'Your Message here.';
      }

      $scope.activeEcard = _.filter($scope.list.ecards, function (ecard) {
        return ecard.id == $scope.dedicationModel.ecard_id;
      })[0];

      $scope.emailMessage = message;

      scFlowModalService.open({
        templateUrl: 'global/objects/scBlock/types/donation/dedication/ecard-preview-modal',
        context: $scope,
        maxWidth: 520
      });
    }
  };

  /* ---------------------------------------------------------------------- *
   * API
   * ---------------------------------------------------------------------- */

  $scope.template = function (name) {
    return 'global/objects/scBlock/types/frs-donation/components/dedication/partials/' + name;
  };

  $scope.selectEcard = function (id) {
    $scope.dedicationModel.ecard_id = id;
  };

  /* ---------------------------------------------------------------------- *
   * Init
   * ---------------------------------------------------------------------- */

  $scope.dedicationModel = {
    type: 'honor',
    honoree_name: null,
    name: null,
    email_address: null,
    address: null,
    city: null,
    state: null,
    country: $scope.setup.country(),
    postal_code: null,
    ecard_id: null,
    ecard_message: null
  };

  $scope.$watch('state.dedicationFormEnabled', function (enabled) {
    if (enabled) {
      $scope.MODEL.dedication = $scope.dedicationModel;
      $scope.FORM.$addControl($scope.dedicationForm);
      // PAY-1289: Propagate validation to parent form once added
      _.forEach(['name', 'email_address', 'honoree_name', 'country', 'address', 'city', 'state', 'postal_code'], function (key) {
        if ($scope.dedicationForm[key]) {
          $scope.dedicationForm[key].$validate();
        }
      });
    } else {
      $scope.MODEL.dedication = null;
      $scope.FORM.$removeControl($scope.dedicationForm);
    }
  });
}
})();