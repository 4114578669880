(function(){
'use strict';

frsDonationPaymentCtrl.$inject = ["$scope", "$timeout", "$window", "scCampaignsService", "scMembersService", "scOrganizationsService", "scCurrencyService", "scFeeOnTopService", "scDigitalWalletsService"];
angular.module('classy').controller('frsDonationPaymentCtrl', frsDonationPaymentCtrl);

function frsDonationPaymentCtrl($scope, $timeout, $window, scCampaignsService, scMembersService, scOrganizationsService, scCurrencyService, scFeeOnTopService, scDigitalWalletsService) {
  'use strict';

  /* ---------------------------------------------------------------------- *
   * Setup
   * ---------------------------------------------------------------------- */

  var org = scOrganizationsService.active;
  $scope.isPaymentAuthSuccess = false;
  $scope.$on('checkPaymentAuthStatus', function (_event, isPaymentAuthSuccess) {
    $scope.isPaymentAuthSuccess = isPaymentAuthSuccess;
    if (isPaymentAuthSuccess) {
      $scope.MODEL.company_name = null;
    }
  });

  $scope.$on('donationPage:recalculateFees', function () {
    getEstimates($scope.MODEL.items[0].raw_final_price, $scope.MODEL.fee_on_top, $scope.MODEL.payment.raw_currency_code, scFeeOnTopService.isAmex, $scope.MODEL.billing_country !== 'US');
  });

  $scope.$on('feesCalculated', function () {
    if ($scope.MODEL.fee_on_top) {
      var feeSum = scFeeOnTopService.getFeesByType('donation');
      var donationLessFees = $scope.MODEL.items[0].raw_final_price;
      var grossAdjustment = scFeeOnTopService.fees.donation.grossAdjustment;

      var maxDecimals = scCurrencyService.getAllowedDecimals($scope.MODEL.payment.raw_currency_code);

      var summaryAmount = parseFloat(feeSum) + parseFloat(donationLessFees);
      summaryAmount = summaryAmount.toFixed(maxDecimals);

      $scope.META.summaryAmount = parseFloat(summaryAmount);
      $scope.MODEL.fee_on_top_amount = parseFloat(feeSum);
      $scope.MODEL.gross_adjustment = grossAdjustment;
    } else {
      $scope.META.summaryAmount = $scope.MODEL.items[0].raw_final_price;
      $scope.MODEL.gross_adjustment = {};
      $scope.MODEL.fee_on_top_amount = null;
    }

    if ($scope.META.summaryAmount > 0) $scope.META.fixedDonationAmount = true;
    $scope.globalState.feeProcessing = false;
  });

  $scope.META.showPaymentDetails = false;
  $scope.META.digitalWalletType = '';
  $scope.showDigitalWallets = function (show) {
    $scope.META.digitalWalletsEnabled = show;
  };
  scDigitalWalletsService.addShowDigitalWalletsListener($scope.showDigitalWallets);

  $scope.PREFLIGHT.payment = function () {
    if (scDigitalWalletsService.isDigitalWalletSubmission) {
      return $scope.API.setModelPaymentMethodGateway(scDigitalWalletsService.isDigitalWalletSubmission);
    } else {
      return $scope.API.setModelPayment();
    }
  };

  $scope.setup = {
    applicationId: function applicationId() {
      return scOrganizationsService.getTag('classyPayApplicationId');
    },
    billingFirstName: function billingFirstName() {
      return $scope.CONTEXT.autofill.first || _.get(scMembersService, 'active.current.first_name') || '';
    },
    billingLastName: function billingLastName() {
      return $scope.CONTEXT.autofill.last || _.get(scMembersService, 'active.current.last_name') || '';
    },
    country: function country() {
      if (org.multiCurrencyDisabled()) {
        return org.current.country === 'CA' || org.current.country === 'CAN' ? 'CA' : 'US';
      }

      return scCurrencyService.getBrowserLocale() || ($scope.CURRENCY.code === 'CAD' ? 'CA' : 'US');
    },
    address: function address() {
      return $scope.CONTEXT.autofill.street || '';
    },
    city: function city() {
      return $scope.CONTEXT.autofill.city || '';
    },
    state: function state() {
      return $scope.CONTEXT.autofill.state || '';
    },
    postalCode: function postalCode() {
      return $scope.CONTEXT.autofill.zip || '';
    },
    feeOnTop: function feeOnTop() {
      return $scope.campaign.current.classy_mode_checked_by_default || false;
    },
    feeOnTopAmount: function feeOnTopAmount() {
      return 0.0;
    }
  };

  /* ---------------------------------------------------------------------- *
   * Constants
   * ---------------------------------------------------------------------- */
  $scope.CONSTANTS = {
    ADDRESS_REQUIRED_ERROR_TEXT: 'Don\'t forget to provide a billing address.',
    COUNTRY_REQUIRED_ERROR_TEXT: 'Please select your country.',
    CITY_REQUIRED_ERROR_TEXT: 'Don\'t forget to provide a billing city.',
    CVV_PATTERN_ERROR_TEXT: 'Please enter a valid 3 or 4 digit security code.',
    CVV_REQUIRED_ERROR_TEXT: 'Please enter the 3 or 4 digit security code on your card.',
    CVV_TOOLTIP_TEXT: 'For MasterCard or Visa it is the last three digits in the signature area on the back of your card. For American Express it is the four digits on the front of the card.',
    PAN_ENTER_CARD_NUMBER_ERROR_TEXT: 'Please enter your card number.',
    PAN_VALID_CARD_NUMBER_ERROR_TEXT: 'Please enter a valid credit card number.',
    RECAPTCHA_REQUIRED_ERROR_TEXT: 'Please verify that you are not a robot.'
  };

  $scope.countryResitrictedText = _.get(scOrganizationsService, 'active.current.restricted_country_information.drop_down_text');
  /* ---------------------------------------------------------------------- *
   * UI State
   * ---------------------------------------------------------------------- */

  $scope.state = {
    hideBillingAddressAutocomplete: false,
    debounce: null
  };

  /* ---------------------------------------------------------------------- *
   * Display flags
   * ---------------------------------------------------------------------- */

  $scope.show = {
    recaptcha: function recaptcha() {
      return _.get($scope, 'organization.current.showRecaptcha') && $scope.public;
    },
    classyMode: function classyMode() {
      return scCampaignsService.active.current.classy_mode_enabled;
    },
    states: function states() {
      return $scope.MODEL.billing_country === 'US';
    },
    provinces: function provinces() {
      return $scope.MODEL.billing_country === 'CA';
    },
    genericLevel1: function genericLevel1() {
      return !_.includes(['US', 'CA'], $scope.MODEL.billing_country);
    },
    billingAddressAutocomplete: function billingAddressAutocomplete() {
      return $scope.block.billing_address_autocomplete && !$scope.state.hideBillingAddressAutocomplete;
    },
    companyName: function companyName() {
      return scCampaignsService.active.current.corporate_donation_enabled && !$scope.isPaymentAuthSuccess;
    }
  };

  /* ---------------------------------------------------------------------- *
   * Constructed bindings
   * ---------------------------------------------------------------------- */

  $scope.build = {
    classyModeAppeal: function classyModeAppeal() {
      return $scope.campaign.current.classy_mode_appeal || '\n        <p>I\'d like to cover the processing fee so 100% of my donation goes to ' + scOrganizationsService.active.current.name + '.</p>\n      ';
    },
    stripTagsFromAppeal: function stripTagsFromAppeal() {
      // remove any HTML tags from classy mode appeal
      var appeal = this.classyModeAppeal();
      return appeal.replace(/(<([^>]+)>)/gi, '');
    },
    regionLabel: function regionLabel() {
      if ($scope.MODEL.billing_country === 'US') {
        return 'State & Zip';
      } else if ($scope.MODEL.billing_country === 'CA') {
        return 'Province & Postal Code';
      }

      return 'Region & Postal Code';
    },
    zipPlaceholder: function zipPlaceholder() {
      if ($scope.MODEL.billing_country === 'US') {
        return 'ZIP';
      }

      return 'Postal Code';
    },
    regionType: function regionType() {
      if ($scope.MODEL.billing_country === 'US') {
        return 'state';
      } else if ($scope.MODEL.billing_country === 'CA') {
        return 'province';
      }

      return '';
    },
    zipType: function zipType() {
      if ($scope.MODEL.billing_country === 'US') {
        return 'ZIP code';
      }

      return 'postal code';
    },
    regionZipError: function regionZipError() {
      var zipTerm = $scope.build.zipType();
      if ($scope.UTIL.showError('billing_state', 'required') || $scope.UTIL.showError('billing_postal_code', 'required')) {
        var region = $scope.UTIL.showError('billing_state', 'required') ? $scope.build.regionType() : '',
            zip = $scope.UTIL.showError('billing_postal_code', 'required') ? zipTerm : '',
            and = region && zip ? ' and ' : '';
        return 'Don\'t forget to include a billing ' + region + and + zip + '.';
      } else if ($scope.UTIL.showError('billing_postal_code', 'minlength') || $scope.UTIL.showError('billing_postal_code', 'maxlength')) {
        return 'Please enter a billing ' + zipTerm + ' between 2 and 10 characters.';
      }

      return undefined;
    },
    nameError: function nameError() {
      var first = $scope.UTIL.showError('billing_first_name', 'required') ? 'first' : '',
          last = $scope.UTIL.showError('billing_last_name', 'required') ? 'last' : '',
          and = first && last ? ' and ' : '';

      return 'Please enter your billing ' + first + and + last + ' name.';
    }
  };

  /* ------------------------------------------------------------------ *
   * API
   * ------------------------------------------------------------------ */

  $scope.template = function (name) {
    return 'global/objects/scBlock/types/frs-donation/components/payment/partials/' + name;
  };

  $scope.parseBillingAddressAutocomplete = function (response) {
    if (_.isUndefined(response)) {
      return;
    }

    // Clear MODEL.
    if (!response) {
      _.merge($scope.MODEL, {
        billing_address1: null,
        billing_address2: null,
        billing_postal_code: null,
        billing_city: null,
        billing_state: null,
        billing_country: null
      });
    } else {
      // Update billing fields and switch to manual entry so the donor can
      // "check their work".

      _.merge($scope.MODEL, {
        billing_address1: response.address,
        billing_postal_code: response.postalCode,
        billing_city: response.city,
        billing_state: response.state,
        billing_country: response.country
      });
      $scope.state.hideBillingAddressAutocomplete = true;
    }
  };

  $scope.useManualAddress = function () {
    $scope.state.hideBillingAddressAutocomplete = true;
  };

  /* ------------------------------------------------------------------ *
   * Init
   * ------------------------------------------------------------------ */

  _.merge($scope.MODEL, {
    application_id: $scope.setup.applicationId(),
    billing_first_name: $scope.setup.billingFirstName(),
    billing_last_name: $scope.setup.billingLastName(),
    billing_address1: $scope.setup.address(),
    billing_address2: '',
    billing_city: $scope.setup.city(),
    billing_state: $scope.setup.state(),
    billing_postal_code: $scope.setup.postalCode(),
    billing_country: $scope.setup.country(),
    fee_on_top: $scope.setup.feeOnTop(),
    fixed_fot_percent: scFeeOnTopService.fixedFotPercent,
    fixed_fot_enabled: scFeeOnTopService.fixedFotEnabled && $scope.setup.feeOnTop(),
    fee_on_top_amount: $scope.setup.feeOnTopAmount(),
    gross_adjustment: {}
  });

  $scope.api = {};

  $scope.$watchGroup(['META.member_first_name', 'META.member_last_name'], function (newVals, oldVals) {
    if ($scope.MODEL.billing_first_name === oldVals[0]) {
      $scope.MODEL.billing_first_name = newVals[0];
    }
    if ($scope.MODEL.billing_last_name === oldVals[1]) {
      $scope.MODEL.billing_last_name = newVals[1];
    }
  });

  $scope.$watchGroup(['MODEL.items[0].raw_final_price', 'MODEL.fee_on_top', 'MODEL.payment.raw_currency_code', 'MODEL.billing_country', 'MODEL.payment.method'], function (newVals) {
    var amount = newVals[0];
    var fot = newVals[1];
    var curr = newVals[2];
    var isAmex = scFeeOnTopService.isAmex;
    var isIntl = newVals[4] !== 'US';

    if ($scope.state.debounce) {
      $timeout.cancel($scope.state.debounce);
    }

    $scope.MODEL.fixed_fot_enabled = fot && scFeeOnTopService.fixedFotEnabled;

    if (!amount || !fot) {
      $scope.META.summaryAmount = amount;
      $scope.globalState.feeProcessing = false;
      $scope.MODEL.gross_adjustment = {};
      $scope.MODEL.fee_on_top_amount = null;
      return;
    }
    $scope.globalState.feeProcessing = true;

    // Pass in raw amount and raw currency. Estimation endpoint does
    // conversions for m11y for us
    var debounce = $timeout(function () {
      getEstimates(amount, fot, curr, isAmex, isIntl);
    }, 750);
    $scope.state.debounce = debounce;
  });

  $scope.$watch('META.showCompanyName', function (isEnabled) {
    if (!isEnabled) {
      $scope.MODEL.company_name = null;
    }
  });

  $scope.$on('fixedFotPercent:updated', function (_event, newFixedFotPercent) {
    $scope.MODEL.fixed_fot_percent = newFixedFotPercent;
    getEstimates($scope.MODEL.items[0].raw_final_price, $scope.MODEL.fee_on_top, $scope.MODEL.payment.raw_currency_code, scFeeOnTopService.isAmex, $scope.MODEL.billing_country !== 'US');
  });

  function getEstimates(amount, fot, userCurr, isAmex, isIntl) {
    // eslint-disable-next-line camelcase
    var payment_method = $scope.API.getPaymentMethod();

    scFeeOnTopService.calculateFees({
      type: 'donation',
      fot: fot,
      amount: amount,
      overrides: {
        currency: userCurr,
        processor_name: $scope.SC.processors[payment_method].name,
        payment_method: payment_method,
        amex: isAmex,
        international: isIntl
      }
    });
  }

  if ($scope.demo) {
    $scope.$watch('campaign.current.classy_mode_checked_by_default', function (newVal) {
      $scope.MODEL.fee_on_top = newVal;
    });
  }

  // eslint-disable-next-line no-unused-vars
  $scope.$on('donation.failure', function (e, status) {
    $scope.API.reloadTokenexIframe();
    if (SC.organization.showRecaptcha) {
      $window.grecaptcha.enterprise.reset();
    }
  });

  $scope.$watch('META.showPaymentDetails', function (showPaymentDetails) {
    if (showPaymentDetails && $scope.show.recaptcha()) {
      $scope.Donation = {}; // TODO: Amend scRecaptcha
      $window.initRecaptcha = function () {
        $scope.$apply(function () {
          $scope.$broadcast('sc-recaptcha');
        });
      };
      var s = document.createElement('script');
      s.src = 'https://www.google.com/recaptcha/enterprise.js?onload=initRecaptcha&render=explicit';
      s.setAttribute('async', '');
      s.setAttribute('defer', '');
      document.body.appendChild(s);
    }
  });
}
})();