(function(){
'use strict';

angular.module('classy').service('EmbeddedGivingMessenger', ["$log", "$timeout", "$window", "scAnalytics", function ($log, $timeout, $window, scAnalytics) {
  var self = this;
  var EG_MSG = 'embedded-giving';
  var EG_DON = 'donation:set';
  var EG_EVENT = 'track-event';
  var EG_NAV = 'navigate';

  this.init = function ($scope) {
    var _this = this;

    if (!$scope) {
      $log.warn('[EmbeddedGivingMessenger:processMessage] $scope is required');
      return;
    }
    this.$scope = $scope;
    this.campaign = $scope.campaign;
    this.model = $scope.MODEL;
    this.meta = $scope.META;

    __initScopeWatchersForState();

    $window.addEventListener('message', __processMessage);

    // Remove window event listener on scope destroy to avoid mem leaks
    this.$scope.$on('$destroy', function () {
      $window.removeEventListener('message', __processMessage);
      delete _this.$scope;
    });
  };

  function __initScopeWatchersForState() {
    self.$scope.$watchGroup(['MODEL.frequency', 'META.summaryAmount', 'MODEL.payment.raw_currency_code', 'MODEL.billing_postal_code', 'META.member_first_name', 'META.member_last_name', 'MODEL.member_email_address'], function () {
      self.postMessageDonationState();
    });
  }

  function __processMessage(msg) {
    if (!msg || !msg.data) {
      $log.debug('[EmbeddedGivingMessenger:processMessage] (invalid - empty)');
      return;
    }

    // Only work with EG messages (check for prefix)
    var msgData = msg.data || '';
    if (_.isString(msgData) && msgData.indexOf(EG_MSG) >= 0) {
      __handleMessage(msgData);
    }
  }

  /* Message Handlers */
  function __handleMessage(egMsg) {
    if (!egMsg || !_.isString(egMsg)) {
      return;
    }

    if (egMsg.indexOf(EG_EVENT) >= 0) {
      __handleEventMessage(egMsg);
    } else if (egMsg.indexOf(EG_NAV) >= 0) {
      __handleNavigateMessage(egMsg);
    } else if (egMsg.indexOf(EG_DON) >= 0) {
      __handleDonationMessage(egMsg);
    }
  }

  function __handleEventMessage(eMsg) {
    $log.debug('[EmbeddedGivingMessenger:__handleEventMessage] event message: ', eMsg);
    scAnalytics.track(eMsg);
  }

  // TODO DI these constants from eg.js
  // Format: 'embedded-giving:navigate:{{destination}}'
  function __handleNavigateMessage(nMsg) {
    $log.debug('[EmbeddedGivingMessenger:__handleNavigateMessage] navigate message: ', nMsg);
    if (!nMsg || !nMsg.length) {
      $log.debug('[EmbeddedGivingMessenger:__handleNavigateMessage] (invalid - empty)');
      return;
    }

    var msgTokens = nMsg.split(':');
    if (msgTokens && msgTokens.length <= 2) {
      $log.debug('[EmbeddedGivingMessenger:__handleNavigateMessage] (invalid dest): ', msgTokens);
      return;
    }

    var navTokens = msgTokens.slice(2);
    var navKey = navTokens && navTokens.length && navTokens[0];
    if (!navKey) {
      $log.debug('[EmbeddedGivingMessenger:__handleNavigateMessage] (empty destination)');
      return;
    }

    // Use $timeout here to force an angular digest cycle
    if (navKey === 'faq') {
      var isOpening = navTokens.length === 1;
      $timeout(function () {
        navigateFAQ(isOpening);
      });
    } else if (navKey === 'do') {
      $timeout(function () {
        navigateDonationRoot();
      });
    }
    // TODO scope.apply() instead?
  }

  // Format: 'embedded-giving:donatoin:set?key=value&keyB=valueB'
  function __handleDonationMessage(dMsg) {
    $log.debug('[EmbeddedGivingMessenger:__handleDonationMessage] message: ', dMsg);

    var params = dMsg.split('?');
    if (!params || !params.length === 2) {
      $log.debug('[EmbeddedGivingMessenger:__handleDonationMessage] invalid message: ', dMsg);
      return;
    }

    params = params[1].split('&');
    var paramsObj = {
      currency: null,
      recurring: null,
      amount: null,
      zip: null,
      email: null,
      first: null,
      last: null
    };
    var token = void 0,
        key = void 0,
        val = void 0;
    for (var i = 0; i < params.length; i += 1) {
      token = params[i].split('=');
      key = token[0];
      val = token[1];
      if (_.has(paramsObj, key)) {
        paramsObj[key] = val;
      }
    }

    // self.$scope.$apply();
    $timeout(function () {
      // TODO should we set value to empty? should we verify values?
      if (paramsObj.currency) {
        self.$scope.MODEL.payment.raw_currency_code = paramsObj.currency;
      }
      // set to monthly or one-time
      self.$scope.MODEL.frequency = +paramsObj.recurring ? 'monthly' : 'one-time';

      if (paramsObj.amount) {
        self.$scope.MODEL.items[0].raw_final_price = +paramsObj.amount;
        // self.$scope.FORM.amount = +paramsObj.amount;
        // const amount = _.get(this.model, 'items[0].raw_final_price'); // this.meta.summaryAmount;
      }
      if (paramsObj.zip) {
        self.$scope.MODEL.billing_postal_code = paramsObj.zip;
      }
      if (paramsObj.email) {
        self.$scope.MODEL.member_email_address = paramsObj.email;
      }
      if (paramsObj.first) {
        self.$scope.META.member_first_name = paramsObj.first;
      }
      if (paramsObj.last) {
        self.$scope.META.member_last_name = paramsObj.last;
      }
    });
  }

  /* Navigation */
  function navigateFAQ(isOpening) {
    if (!self.$scope || !_.isFunction(self.$scope.openFAQOverlay) || !_.isFunction(self.$scope.closeFAQOverlay)) {
      $log.debug('[[EmbeddedGivingMessenger:navigateFAQ] required $scope not present');
      return;
    }

    if (isOpening) {
      $log.debug('[[EmbeddedGivingMessenger:navigateFAQ] opening FAQ');
      self.$scope.openFAQOverlay();
    } else {
      $log.debug('[[EmbeddedGivingMessenger:navigateFAQ] closing FAQ');
      self.$scope.closeFAQOverlay();
    }
  }

  function navigateDonationRoot() {
    if (!self.$scope || !_.isFunction(self.$scope.goDonationRoot)) {
      $log.debug('[[EmbeddedGivingMessenger:navigateDonationRoot] required $scope not present');
      return;
    }

    $log.debug('[[EmbeddedGivingMessenger:navigateDonationRoot] navigating donation root');
    self.$scope.goDonationRoot();
  }

  /* Post Message Functions */
  function __postMessage(msg) {
    if (!$window.parent) {
      $log.warn('[EmbeddedGivingMessenger:processMessage] no parent window');
      return;
    }

    $log.log('[EmbeddedGivingMessenger:postMessage] posting message to parent window: ', msg);
    $window.parent.postMessage(msg, '*');
  }

  this.postMessage = __postMessage;
  // Debounce to avoid spamming on each keypress that triggers a "donation state change"
  this.postMessageDebounce = _.debounce(__postMessage, 500);

  this.postMessageDonationState = function () {
    if (!this.campaign || !this.campaign.current || !this.campaign.current.id) {
      $log.warn('[EmbeddedGivingMessenger:postMessageDonationState] no campaign');
      return;
    }
    var msg = EG_MSG + ':' + this.campaign.current.id + ':donation:modal:state?';
    var params = [];

    // Supported donation params
    var currency = this.model.payment.raw_currency_code;
    var frequency = this.model.frequency;
    var amount = _.get(this.model, 'items[0].raw_final_price'); // this.meta.summaryAmount;
    var zip = this.model.billing_postal_code;
    var email = this.model.member_email_address;
    var first = this.meta.member_first_name;
    var last = this.meta.member_last_name;

    // Add optional donation state params
    if (currency) {
      params.push('currency=' + currency);
    }
    if (frequency) {
      params.push(frequency === 'monthly' ? 'recurring=1' : 'recurring=0');
    }
    if (amount) {
      params.push('amount=' + amount);
    }
    if (zip) {
      params.push('zip=' + zip);
    }
    if (email) {
      params.push('email=' + email);
    }
    if (first) {
      params.push('first=' + first);
    }
    if (last) {
      params.push('last=' + last);
    }

    this.postMessageDebounce(msg + params.join('&'));
  };

  this.postMessageDonationComplete = function () {
    if (!this.campaign || !this.campaign.current || !this.campaign.current.id) {
      $log.warn('[EmbeddedGivingMessenger:postMessageDonationComplete] campaign not present');
      return;
    }

    this.postMessage(EG_MSG + ':' + this.campaign.current.id + ':donation:modal:complete');
  };
}]);
})();