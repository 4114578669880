(function(){
'use strict';

/**
 * scImgFroala
 *
 * A Froala plugin that handles image uploading through the assets API.
 */
scImgAssetFroalaUploadFactory.$inject = ["$filter", "$parse", "$timeout", "$rootScope", "scImgAssetsService"];
scImgAssetFroalaUploadRun.$inject = ["scImgAssetFroalaUpload"];
angular.module('classy').factory('scImgAssetFroalaUpload', scImgAssetFroalaUploadFactory).run(scImgAssetFroalaUploadRun);

function scImgAssetFroalaUploadFactory($filter, $parse, $timeout, $rootScope, scImgAssetsService) {
  function scImgAssetFroalaUpload(editor) {
    // The upload button has to be set to use our plugin anyway, so we'll
    // just check for that (rather than create and watch a separate flag in
    // the editor options).
    function pluginActive() {
      var toolbarButtonFields = ['toolbarButtons', 'toolbarButtonsMD', 'toolbarButtonsSM', 'toolbarButtonsXS'];

      // test to see if any of the toolbarButtons options includes the
      // scImgAssetFroalaUpload plugin
      return toolbarButtonFields.some(function (fieldName) {
        return (editor.opts[fieldName] || '').includes('scImgAssetFroalaUpload');
      });
    }

    // Every editor using the plugin has to maintain a list of assets to
    // interpolate into the content. It does this by examining the ng-model
    // on the editor element and appending __froalaAssets.
    function buildMediaModel(modelStr, scope) {
      var bracketTest = /(.*)\[(.*?)\]$/;
      var bracketResult = bracketTest.exec(modelStr);
      if (bracketResult) {
        var finalProp = $parse(bracketResult[2])(scope);
        return bracketResult[1] + '[\'' + finalProp + '__froalaAssets\']';
      }
      return modelStr + '__froalaAssets';
    }

    // A utility used by other tools to get, setup, or update media list.
    function getParser() {
      var scope = editor.$oel.scope();
      var model = editor.$oel.data('ngModel');
      var mediaModel = buildMediaModel(model, scope);
      return {
        model: mediaModel,
        value: $parse(mediaModel)(scope),
        assign: function assign(value) {
          $parse(mediaModel).assign(scope, value);
          return $parse(mediaModel)(scope);
        }
      };
    }

    function setupMediaList() {
      var parser = getParser();
      if (!parser.value) {
        parser.assign({
          assets: {
            __media: 'assetList',
            list: []
          }
        });
      }
    }

    function getMediaList() {
      var mediaList = getParser().value;
      return _.get(mediaList, 'assets.list');
    }

    function cleanMediaList() {
      var html = editor.html.get();
      if (!html) {
        return;
      }

      var mediaList = getMediaList();
      if (!mediaList) {
        return;
      }

      mediaList.forEach(function (assetId, index) {
        if (html.indexOf(assetId) < 0) {
          mediaList.splice(index, 1);
        }
      });
    }

    // Update image URLs in case asset storage scheme has changed
    // since the user last loaded the page. Why use a timeout?
    // Because Froala does not understand the meaning of "initialized"
    // and editor.html.get() returns nothing. :( However, in 99.99% of
    // cases the URL stored in the HTML will still be correct, so the
    // user shouldn't experience a delay in normal editing.
    function linkExisting() {
      $timeout(function () {
        var html = editor.html.get();
        var linked = $filter('scImgAssetRef')(html);
        editor.html.set(linked);
      }, 2000);
    }

    // Create file input.
    function buildUploader() {
      editor.scImgAssetUploader = jQuery("<input type='file' accept='image/*' style='display:none;'/>").appendTo('body').on('change', function () {
        if (!jQuery(this)[0].files.length) {
          return;
        }

        var file = jQuery(this)[0].files[0];
        $rootScope.$apply(function () {
          handleUpload(file);
        });
      });

      return editor.scImgAssetUploader;
    }

    function checkValidFile(file) {
      if (!/image\/[jpeg|gif|png]/.test(file.type)) {
        return -1;
      }
      return 0;
    }

    // Remove file input.
    function destroyUploader() {
      editor.scImgAssetUploader.remove();
    }

    // File input click handler. (File extracted by scImgAssetFroalaUpload
    // command.)
    function handleUpload(file) {
      // check for valid file extensions
      if (checkValidFile(file) === -1) {
        $rootScope.SC.status.banner = {
          type: 'error',
          msg: 'Please upload a JPEG, GIF, or PNG file.'
        };
        return;
      }
      var asset = scImgAssetsService.build(file);
      var tempId = 'img_' + _.uniqueId() * new Date();

      editor.html.insert('<img src=\'/static/global/images/preloader.gif\' id=\'' + tempId + '\' style=\'width:50px; height:50px;\' />', false);

      asset.on('save', function () {
        // Insert saved image
        var temp = jQuery('#' + tempId);
        temp.attr('style', '');
        temp.attr('src', asset.sizes.full);
        temp.data('sc-img-asset-ref', asset.assetId);

        // Store in media list
        // Calling setup here so that we don't change the model without
        // user interaction, which can cause unexpected deepDiff issues.
        setupMediaList();
        var mediaList = getMediaList();
        mediaList.push(asset.assetId);
        editor.events.trigger('contentChanged');

        // rebuild the uploader.
        destroyUploader();
        buildUploader();
      });
    }

    function handleImageDrop(image) {
      if (!pluginActive()) return;
      handleUpload(image);
    }

    return {
      // public methods
      handleImageDrop: handleImageDrop,

      // Can't test what you can't access ...
      /* eslint-disable no-underscore-dangle */
      _pluginActive: pluginActive,
      _buildMediaModel: buildMediaModel,
      _getParser: getParser,
      _setupMediaList: setupMediaList,
      _getMediaList: getMediaList,
      _cleanMediaList: cleanMediaList,
      _linkExisting: linkExisting,
      _buildUploader: buildUploader,
      _destroyUploader: destroyUploader,
      _handleUpload: handleUpload,
      _checkValidFile: checkValidFile,

      // F'real though
      _init: function _init() {
        editor.events.on('contentChanged', function () {
          if (!pluginActive()) {
            return;
          }

          cleanMediaList();
        });

        editor.events.on('initialized', function () {
          if (!pluginActive()) {
            return;
          }

          linkExisting();
          buildUploader();
          editor.$oel.scope().$on('$destroy', function () {
            destroyUploader();
          });
        });
      }

      /* eslint-enable no-underscore-dangle */

    };
  }
  return scImgAssetFroalaUpload;
}

function scImgAssetFroalaUploadRun(scImgAssetFroalaUpload) {
  var Froala = jQuery.FroalaEditor;

  Froala.PLUGINS.scImgAssetFroalaUpload = scImgAssetFroalaUpload;

  Froala.DefineIcon('scImgAssetFroalaUpload', { NAME: 'picture-o' });

  Froala.RegisterCommand('scImgAssetFroalaUpload', {
    title: 'Upload Image',
    undo: true,
    focus: true,
    refreshAfterCallback: true,
    callback: function callback() {
      var editor = this;
      editor.scImgAssetUploader.trigger('click');
    }
  });
}
})();