(function(){
'use strict';

mobileMenuActions.$inject = ["$rootScope", "$state", "$window", "fundraiseSwitchFlow", "registrationEntityFlow", "scAuth", "scFundraisingPagesService", "scFundraisingTeamsService", "scPagesService", "scOrganizationsService", "scSupporterNavService"];
angular.module('classy').service('mobileMenuActions', mobileMenuActions);

function mobileMenuActions($rootScope, $state, $window, fundraiseSwitchFlow, registrationEntityFlow, scAuth, scFundraisingPagesService, scFundraisingTeamsService, scPagesService, scOrganizationsService, scSupporterNavService) {
  /* ------------------------------------------------------------------------ *
   * Profile action
   *
   * Sends user to their profile.
   * ------------------------------------------------------------------------ */

  this.profile = function () {
    return {
      label: 'My Profile',
      figure: {
        type: 'icon',
        icon: 'ci ci-profile'
      },
      action: function action() {
        $window.location.href = '/profile/' + scOrganizationsService.active.current.id;
      }
    };
  };

  /* ------------------------------------------------------------------------ *
   * Logout action
   * ------------------------------------------------------------------------ */

  this.logout = function () {
    return {
      label: 'Log Out',
      figure: {
        type: 'icon',
        icon: 'ci ci-logout'
      },
      action: function action() {
        scAuth.logout();
      }
    };
  };

  /* ------------------------------------------------------------------------ *
   * Login action
   * ------------------------------------------------------------------------ */

  this.login = function (api) {
    return {
      label: 'Log In',
      figure: {
        type: 'icon',
        icon: 'ci ci-login'
      },
      action: function action() {
        api.close();
        scAuth.login({ callback: $window.location.href });
      }
    };
  };

  /* ------------------------------------------------------------------------ *
   * Start Fundraising action
   *
   * Pops up the fundraiser switch modal.
   * ------------------------------------------------------------------------ */

  this.startFundraising = function (api) {
    return {
      label: 'Start Fundraising',
      figure: {
        type: 'icon',
        icon: 'ci ci-fundraising-cash'
      },
      action: function action() {
        api.close();
        fundraiseSwitchFlow();
      }
    };
  };

  /* ------------------------------------------------------------------------ *
   * Register action
   *
   * Pops up the register switch modal.
   * ------------------------------------------------------------------------ */

  this.register = function (api) {
    return {
      label: 'Register',
      figure: {
        type: 'icon',
        icon: 'ci ci-fundraising-cash'
      },
      action: function action() {
        api.close();
        registrationEntityFlow();
      }
    };
  };

  /* ------------------------------------------------------------------------ *
   * Donate action
   *
   * Sends the user to the current campaign's donation page.
   * ------------------------------------------------------------------------ */

  this.donate = function (api) {
    var pageType = 'donation';
    var entityLabel = 'Campaign';
    if (_.get(scFundraisingTeamsService, 'active.current.id')) {
      pageType = 'teamDonation';
      entityLabel = 'Team';
    }
    if (_.get(scFundraisingPagesService, 'active.current.id')) {
      pageType = 'fundraiserDonation';
      entityLabel = 'Fundraiser';
    }
    return {
      label: 'Donate to ' + entityLabel,
      figure: {
        type: 'icon',
        icon: 'ci ci-profile-coin'
      },
      action: function action() {
        if (!($state.includes('frs.donation') && !$state.includes('frs.donation.thankYou'))) {
          $window.location.href = scPagesService.getUrl({
            pageType: pageType,
            includeBase: true
          }).url;
        }
        api.close();
      }
    };
  };

  /* ------------------------------------------------------------------------ *
   * Campaign Actions
   *
   * Returns an array of actions having to do with the current campaign
   * ------------------------------------------------------------------------ */
  this.campaign = function (api) {
    var actions = [];

    if (api.campaignType == 'reg_w_fund') {
      actions.push(this.register(api), this.donate(api));
    } else {
      actions.push(this.startFundraising(api), this.donate(api));
    }
  };

  /* ------------------------------------------------------------------------ *
   * User Fundraising Pages Actions
   *
   * Returns a list of the fundraising pages if they exist or some actions to
   * create one if there are none, or the visitor is not logged in.
   *
   * ------------------------------------------------------------------------ */
  this.userFundraisingPages = function (api) {
    var actions = [];
    var spa = !$state.includes('frs.donation');
    var pages = scSupporterNavService.getFundraisingPages();

    pages.forEach(function (page) {
      actions.push({
        label: page.current.alias,
        figure: {
          type: 'image',
          src: _.get(page, 'current.logo.src', '/static/global/images/individual_default_image.png')
        },
        action: function action() {
          api.close();
          if (spa) {
            $state.go('frs.fundraiser.index', {
              fundraisingPageId: page.current.id
            });
          } else {
            $window.location.href = scPagesService.getUrl({
              pageType: 'fundraiser',
              includeBase: true,
              fundraiserId: page.current.id
            }).url;
          }
        }
      });
    });

    // If there are additional fundraising pages to load (more than the initial page size)
    // then we push on the loadMore action, which will ultimates trigger the members service
    // to fetch the next available page and add it to the fundraising pages service cache
    if (scSupporterNavService.canLoadMorePages()) {
      actions.push(this.loadMore('pages'));
    }

    return actions;
  };

  /* ------------------------------------------------------------------------ *
   * User Fundraising Teams Actions
   *
   * Returns a list of the fundraising pages if they exist and the visitor is
   * logged in, nothing otherwise.
   *
   * ------------------------------------------------------------------------ */
  this.userFundraisingTeams = function (api) {
    var actions = [];
    var spa = !$state.includes('frs.donation');
    var teams = scSupporterNavService.getFundraisingTeams();

    teams.forEach(function (team) {
      actions.push({
        label: team.current.name,
        figure: {
          type: 'image',
          src: _.get(team, 'current.logo.src', '/static/global/images/team_default_image.png')
        },
        action: function action() {
          api.close();
          if (spa) {
            $state.go('frs.team.index', {
              fundraisingTeamId: team.current.id
            });
          } else {
            $window.location.href = scPagesService.getUrl({
              pageType: 'team',
              includeBase: true,
              teamId: team.current.id
            }).url;
          }
        }
      });
    });

    // If there are additional fundraising teams to load (more than the initial page size)
    // then we push on the loadMore action, which will ultimates trigger the members service
    // to fetch the next available page and add it to the fundraising teams service cache
    if (scSupporterNavService.canLoadMoreTeams()) {
      actions.push(this.loadMore('teams'));
    }

    return actions;
  };

  /* ------------------------------------------------------------------------ *
   * Share action
   *
   * Triggers the share overlay.
   * ------------------------------------------------------------------------ */

  this.share = function (api) {
    return {
      label: 'Share this page',
      figure: {
        type: 'icon',
        icon: 'ci ci-share-o',
        style: {
          'font-size': '19px'
        }
      },
      action: function action() {
        api.close();
        $rootScope.$broadcast('scSocialButtons.open');
      }
    };
  };

  /* ------------------------------------------------------------------------ *
   * Load More Actions
   *
   * Used for pagination on the fundraising pages and teams action groups.
   * ------------------------------------------------------------------------ */
  this.canLoadMoreTeams = function () {
    return scSupporterNavService.canLoadMoreTeams();
  };
  this.canLoadMorePages = function () {
    return scSupporterNavService.canLoadMorePages();
  };
  this.loadMore = function (type) {
    return {
      label: 'Load more...',
      action: function action() {
        return type === 'teams' ? scSupporterNavService.loadMoreTeams() : scSupporterNavService.loadMoreFundraisingPages();
      }
    };
  };
}
})();