(function(){
'use strict';

scThankYouBlock.$inject = ["$rootScope", "$stateParams", "$filter", "scMembersService", "scFundraisingTeamsService", "scFundraisingPagesService", "scOrganizationsService", "scCampaignsService", "scSocialShareLinks", "scBlock", "scTransientsService", "scFroalaOptions", "scFlowModalService", "scCurrencyService", "scThemesService", "scTaxEntitiesService", "scProgressDataFactory", "accessibilityService", "$state", "CHANNEL", "scBanner"];
angular.module('classy').directive('scThankYouBlock', scThankYouBlock);

function scThankYouBlock($rootScope, $stateParams, $filter, scMembersService, scFundraisingTeamsService, scFundraisingPagesService, scOrganizationsService, scCampaignsService, scSocialShareLinks, scBlock, scTransientsService, scFroalaOptions, scFlowModalService, scCurrencyService, scThemesService, scTaxEntitiesService, scProgressDataFactory, accessibilityService, $state, CHANNEL, scBanner) {
  return scBlock({
    controller: ['$scope', function ($scope) {
      $scope.primaryColor = scThemesService.active.current.styles.primaryColor;
      $scope.showManagePane = true;
      $scope.headerOptions = scFroalaOptions({ type: 'edit-inline' }, { ariaLabel: 'Thankyou Page Body Headline' });
      $scope.subheaderOptions = scFroalaOptions({ type: 'edit-inline-subheader' }, { ariaLabel: 'Thankyou Page Body Sub Headline' });
    }],

    link: function link(scope) {
      scope.$stateParams = $stateParams;
      SC.transactionId = $stateParams.tid;
      scope.thankYouText = _.get(SC, 'team.thank_you_text', undefined);
      var previousModalTid = sessionStorage.getItem('modalTid');
      var hasBeenShown = previousModalTid && previousModalTid === $stateParams.tid;

      // Share on Facebook modal
      if ($stateParams.tid && !hasBeenShown) {
        scFlowModalService.open({
          templateUrl: 'global/objects/scBlock/types/thank-you/share-modal',
          context: scope,
          maxWidth: 520
        }, {
          animate: false,
          elementToFocusOnClose: '.thank-you_headline'
        });
        sessionStorage.setItem('modalTid', $stateParams.tid);
      }

      scope.Organization = scOrganizationsService.active.current;

      scope.hasDonationInfo = true;

      if (scope.demo) {
        $rootScope.SC.status.banner = {};
        scope.transaction = {
          id: 44444,
          comment: 'Thanks for all you do',
          frequency: 'one-time',
          display_currency_code: _.get(scCampaignsService, 'active.current.raw_currency_code', scCurrencyService.getDefaultCurrency()),
          raw_total_gross_amount: 42.42,
          recur_until: '31/12/2021'
        };
      } else {
        scope.transaction = scTransientsService.get('transaction');
        if (!scope.transaction) {
          scope.transaction = {};
          scope.hasDonationInfo = false;
        } else if (!scCampaignsService.active.multiCurrencyDisabled()) {
          scTaxEntitiesService.getTaxEntity(scope.transaction.raw_currency_code).then(function (response) {
            scTaxEntitiesService.setCurrentEntity(response);
          });
        }

        if (_.get(scope, 'transaction.dedication')) {
          var type = scope.transaction.dedication.type.toLowerCase();
          var text = type == 'in honor of' || type == 'honor' ? 'In honor of' : 'In memory of';
          scope.dedicationText = text + ' ' + scope.transaction.dedication.honoree_name;
        }
      }

      var myEmail = _.get(scMembersService, 'active.current.email_address');
      var registered = scope.transaction.memberRegistered;
      var frequency = scope.transaction.frequency;
      scope.showManagePane = !!registered;

      scope.phone_contact = scCampaignsService.active.current.contact_phone;
      scope.email_contact = scCampaignsService.active.current.contact_email;

      function setReceivingEntity(data) {
        var _scProgressDataFactor = scProgressDataFactory.getEntityProgress(data),
            totalRaised = _scProgressDataFactor.totalRaised,
            goal = _scProgressDataFactor.goal;

        scope.receivingEntity = Object.assign({}, data, {
          goalReached: totalRaised >= goal,
          isTeam: data.type === 'team'
        });
      }

      scope.donationDestination = 'campaign';

      scope.showChargedAmount = !scCampaignsService.active.multiCurrencyDisabled() && scope.transaction.charged_currency_code != scope.transaction.raw_currency_code;

      if (scope.transaction.fundraising_page_id) {
        scope.donationDestination = 'fundraiser';
        scFundraisingPagesService.fetch(scope.transaction.fundraising_page_id, true).then(function (page) {
          page.current.type = 'fundraiser';
          setReceivingEntity(page.current);
        });
      } else if (scope.transaction.fundraising_team_id) {
        scope.donationDestination = 'team';
        scFundraisingTeamsService.fetch(scope.transaction.fundraising_team_id, true).then(function (team) {
          team.current.type = 'team';
          setReceivingEntity(team.current);
        });
      }

      scope.closeModal = function (keyboardEvent) {
        if (keyboardEvent && !accessibilityService.isValidKeyBoardEvent(keyboardEvent)) return;

        scFlowModalService.close();

        $('.thank-you_headline').focus();
      };

      var feeText = scope.transaction.fee_on_top ? ' plus fees' : '';

      // If multicurrency is enabled & the raw currency is different than the
      // charged currency, display the raw gross amount, adding 'plus fees' at the
      // end if donor chooses to pay the fees. We display the charged amount on
      // the next line.
      scope.convertedAmount = '' + $filter('scFormatCurrency')(scope.transaction.raw_total_gross_amount, scope.transaction.raw_currency_code, 2) + feeText;

      if (!scope.demo) {
        scSocialShareLinks.getSocialData().then(function (data) {
          scope.fbShare = data.facebook_share;
        });
      }

      scope.build = {
        manageData: function manageData() {
          if (registered && !myEmail) {
            return {
              text: 'To manage your donation at any time, please log in by clicking the "Log In" link below.',
              href: _.get(SC, 'ucl') ? '/auth' : '/login',
              buttonText: 'Log In'
            };
          }

          return {
            text: frequency === 'one-time' ? 'You may set up a password for your donor profile and access receipts for all of your donations by clicking “Manage Donation” below.' : 'You may make changes to your recurring donation or update payment information from your account below or email confirmation.',

            href: '/profile/' + scope.Organization.id + '/donation-history',
            buttonText: 'Manage Donation'
          };
        }
      };
      scope.isPreview = function () {
        return $state.is('admin.manage.campaign.step.setup') || $state.is('admin.manage.campaign.step.design.views.content-blocks') || $state.is('admin.manage.campaign.step.design.views.block') || _.get($state, 'params.preview', false);
      };

      /* Double the donation integration */
      if (SC.organization.has_double_the_donation_employer_match) {
        scCampaignsService.active.getCampaignChannel(CHANNEL.DTD).then(function (resp) {
          scope.isEmployerMatchOnThankyouPageEnabled = _.get(resp, '[0].metadata.employer_match_on_thankyou_page', null);
        }).catch(function () {
          scBanner('There was an error fetching the campaign channel status.', 'error');
        });
      }
    }
  });
}
})();