(function(){
'use strict';

createAnalytics.$inject = ["$q", "$log", "scUserAgent", "scAnalyticsUtils", "bugsnagClient", "gaService", "ga4Service", "gtmService", "adwordsService", "fbqService", "heapService", "capiService"];
angular.module('classy').factory('scAnalytics', createAnalytics);

/**
 * ensureAnalyzable
 *
 * Runtime interface-like utility method. It ensures that any analytics service that wishes to tap into
 * scAnalytics follows a strict pattern. If any of the methods listed in the requiredMethods array are
 * missing from the service, an error will be thrown. Never should code make it to production that does
 * not obey this interface.
 *
 * @param services - the services being checked for validity
 */
function ensureAnalyzable(services) {
  if (SC.env !== 'development') return;
  var requiredMethods = ['loaded', // MUST RETURN A PROMISE
  'recordPageView', 'track', 'registerSuperProperties', 'identifyMember', 'eventBeacon'];

  services.forEach(function (service) {
    if (typeof service.name !== 'string') throw new Error('Service missing name field');
    requiredMethods.forEach(function (method) {
      if (typeof service[method] !== 'function') {
        throw new Error(service.name + ' missing required method: ' + method);
      }
    });
  });
}

function createAnalytics($q, $log, scUserAgent, scAnalyticsUtils, bugsnagClient,
/**
 * analytics services that follow the ensureAnalyzable interface.
 */
gaService, ga4Service, gtmService, adwordsService, fbqService, heapService, capiService) {
  var scAnalytics = {};

  /* Setup
  ========================================================= */
  var onPageViewRecordedCallbacks = [];
  var services = [gaService, ga4Service, gtmService, heapService, adwordsService, fbqService, capiService];
  ensureAnalyzable(services);

  /*
   * Public Interface Methods
   *
   * All services need to have these methods defined or they will throw an error during setup
   ========================================================================================== */
  scAnalytics.recordPageView = function () {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    if (scUserAgent.isBot()) return;
    var data = scAnalyticsUtils.buildPageViewData(options);
    data.event_id = scAnalyticsUtils.generateEventId();
    var promises = services.map(function (service) {
      return service.loaded().then(function () {
        return service.recordPageView(data);
      }).catch(handleError('recordPageView', service.name));
    });

    $q.all(promises).finally(function () {
      onPageViewRecordedCallbacks.forEach(function (callback) {
        return callback();
      });
    });
  };

  scAnalytics.registerSuperProperties = function () {
    if (scUserAgent.isBot()) return;
    services.forEach(function (service) {
      service.loaded().then(function () {
        return service.registerSuperProperties();
      }).catch(handleError('registerSuperProperties', service.name));
    });
  };

  scAnalytics.identifyMember = function (id, data) {
    if (!id || scUserAgent.isBot()) return;
    services.forEach(function (service) {
      service.loaded().then(function () {
        return service.identifyMember(id, data);
      }).catch(handleError('identifyMember', service.name));
    });
  };

  scAnalytics.track = function (event, data) {
    if (scUserAgent.isBot()) return;

    if (_.has(data, 'new')) data.new = JSON.stringify(data.new);
    if (_.has(data, 'old')) data.old = JSON.stringify(data.old);
    if (data) data.event_id = scAnalyticsUtils.generateEventId();

    services.forEach(function (service) {
      service.loaded().then(function () {
        return service.track(event, data);
      }).catch(handleError('track', service.name));
    });
  };

  scAnalytics.eventBeacon = function () {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    if (scUserAgent.isBot()) return;
    services.forEach(function (service) {
      service.loaded().then(function () {
        return service.eventBeacon(data);
      }).catch(handleError('eventBeacon', service.name));
    });
  };

  /* Public (non-interface) Methods
  ========================================================= */
  scAnalytics.setCampaign = function (campaign) {
    scAnalyticsUtils.setCampaign(campaign);
  };

  scAnalytics.registerPageViewCallback = function (cb) {
    onPageViewRecordedCallbacks.push(cb);
  };

  scAnalytics.timeTo = function (options) {
    gaService.timing({
      timingCategory: 'TT: ' + options.event,
      timingValue: options.time,
      timingVar: options.preloaded,
      timingLabel: options.session
    });
  };

  scAnalytics.formSubmitTiming = function (options) {
    gaService.timing({
      timingCategory: 'v2: ' + options.form + ' Form Submit',
      timingValue: options.time,
      timingVar: options.status,
      timingLabel: new Date().getTime()
    });
  };

  /* Private Methods
  ========================================================= */
  function handleError(methodName, serviceName) {
    return function (error) {
      if (error) {
        $log.error(error);
        bugsnagClient.notify(error, {
          service: serviceName,
          method: methodName
        });
      }
    };
  }

  return scAnalytics;
}
})();