(function(){
'use strict';

createGa.$inject = ["$q", "$window", "scAnalyticsUtils", "scOrganizationsService", "globalExperimentSwitch", "scBlocksService"];
angular.module('classy').factory('gaService', createGa);

function createGa($q, $window, scAnalyticsUtils, scOrganizationsService, globalExperimentSwitch, scBlocksService) {
  /* Setup
  ========================================================= */
  var gaService = { name: 'google_analytics' };
  var debugLog = scAnalyticsUtils.debugLog,
      isPublicPage = scAnalyticsUtils.isPublicPage,
      isFunnelStartEvent = scAnalyticsUtils.isFunnelStartEvent,
      isFunnelConversionEvent = scAnalyticsUtils.isFunnelConversionEvent,
      isEmbeddedGivingEvent = scAnalyticsUtils.isEmbeddedGivingEvent,
      getCampaign = scAnalyticsUtils.getCampaign,
      getRegistrationQuantity = scAnalyticsUtils.getRegistrationQuantity;

  /* Interface Methods
  ========================================================= */

  gaService.registerSuperProperties = function () {};
  gaService.identifyMember = function () {};

  gaService.loaded = function () {
    return $q(function (resolve) {
      SC.onGaLoaded(function () {
        resolve();
      });
    });
  };

  gaService.recordPageView = function (data) {
    gaPageView(_.extend(_.cloneDeep(data), { integration: 'classy' }));

    if (isPublicPage()) {
      if (clientGAIntegration()) {
        gaPageView(_.extend(_.cloneDeep(data), { integration: 'client' }));
      }
    }
  };

  gaService.track = function (event, data) {
    if (isFunnelStartEvent(event)) {
      data.integration = 'classy';
      gaFunnelStart(event, data);
    }

    if (isFunnelConversionEvent(event)) {
      trackTransactionEvent(event, _.extend(_.cloneDeep(data), { integration: 'classy' }));

      if (clientGAIntegration()) {
        trackTransactionEvent(event, _.extend(_.cloneDeep(data), { integration: 'client' }));
      }
    }

    if (isEmbeddedGivingEvent(event)) {
      trackEmbeddedGivingEvent(event, _.extend(_.cloneDeep(data), { integration: 'classy' }));

      if (clientGAIntegration()) {
        trackEmbeddedGivingEvent(event, _.extend(_.cloneDeep(data), { integration: 'client' }));
      }
    }
  };

  gaService.eventBeacon = function (data) {
    var parsedData = Object.assign({}, data, {
      hitType: 'event',
      eventCategory: data.category || 'frs',
      eventAction: data.action || '',
      eventLabel: data.label || '',
      meta: data.meta || {}
    });

    delete parsedData.category;
    delete parsedData.action;
    delete parsedData.label;

    sendEventBeacon(_.extend(parsedData, { integration: 'classy' }));

    if (clientGAIntegration()) {
      sendEventBeacon(_.extend(parsedData, { integration: 'client' }));
    }
  };

  /* Public (non-interface) Methods
  ========================================================= */
  gaService.timing = function (options) {
    gaService.loaded().then(function () {
      options.timingLabel = options.timingLabel ? options.timingLabel.toString() : '';
      debugLog('timing', options);
      $window.ga(classyGANameSpace() + 'send', 'timing', options.timingCategory, options.timingVar, options.timingValue, options.timingLabel);
    });
  };

  return gaService;

  /* Private Methods
  ========================================================= */
  function gaPageView(options) {
    var nameSpace = getNamespace(options.integration);
    debugLog('ga page view', options);
    $window.ga(nameSpace + 'set', options);
    $window.ga(nameSpace + 'send', 'pageview');
  }

  function clientGAIntegration() {
    return SC.clientGAId;
  }

  function classyGANameSpace() {
    return 'classyTrack.';
  }

  function clientGANameSpace() {
    return 'clientTrack.';
  }

  function getNamespace(integration) {
    return integration === 'classy' ? classyGANameSpace() : clientGANameSpace();
  }

  function sendEventBeacon(options) {
    var nameSpace = getNamespace(options.integration);
    debugLog('ga event beacon', options);
    $window.ga(nameSpace + 'send', options);
  }

  function gaFunnelStart(_event, data) {
    var nameSpace = getNamespace(data.integration);

    var organizationId = _.get(data, 'organizationId', null);
    var paymentProcessor = _.get(data, 'paymentProcessor', 'unknown');
    var customQLength = _.get(data, 'customQLength', '0');
    var campaignType = _.get(data, 'campaignType', 'unknown');
    var campaignId = _.get(data, 'campaignId', 'unknown');
    var donationTarget = _.get(data, 'donationTarget', 'unknown');
    var donationBlock = scBlocksService.getBlockByType('donation');

    debugLog('gaFunnelStart', data);
    window.ga(nameSpace + 'set', 'dimension1', '' + organizationId);
    window.ga(nameSpace + 'set', 'dimension2', paymentProcessor);
    window.ga(nameSpace + 'set', 'dimension3', '' + customQLength);
    window.ga(nameSpace + 'set', 'dimension4', campaignType);
    window.ga(nameSpace + 'set', 'dimension5', '' + campaignId);
    window.ga(nameSpace + 'set', 'dimension6', donationTarget);

    // Check that the experiment is toggled on, org is on ClassyPay,
    // and we're not overriding this experiment
    if (globalExperimentSwitch.RECURRING_EXPERIMENT && scOrganizationsService.hasPay() && donationBlock.current.recurring_donation_experiment) {
      window.ga(nameSpace + 'set', 'dimension11', 'recurring optimization');
    }
  }

  function buildTransactionItems(options) {
    var registrations = options.items || [];
    var campaign = getCampaign();
    var allCampaignRegistrations = campaign.current.ticketTypes || [];
    var items = [];

    _.forEach(registrations, function (registration) {
      var registrationTypeMatch = _.find(allCampaignRegistrations, function (ticket) {
        return ticket.id === registration.product_id;
      });

      var registrationName = _.get(registrationTypeMatch, 'name', null);
      var registrationPrice = _.get(registrationTypeMatch, 'price', 0);
      var registrationQuantity = getRegistrationQuantity(registration);

      var dataObject = {
        id: options.transactionId,
        name: registrationName,
        sku: registration.id.toString(),
        category: 'Ticket',
        price: registrationPrice,
        quantity: registrationQuantity
      };

      items.push(dataObject);
    });

    return items;
  }

  function trackTransactionEvent(event) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var nameSpace = getNamespace(options.integration);
    var amount = options.amount || 0;
    var rawCurrencyCode = options.raw_currency_code;
    var donationAmount = options.donation_amount;
    var rwfCompletionEvent = event === 'registration/create/complete';

    var transactionId = rwfCompletionEvent ? options.registration_transaction_id : options.transactionId;

    debugLog('ga_conversion', options);
    window.ga(nameSpace + 'set', 'metric1', 1);
    window.ga(nameSpace + 'set', 'dimension12', rawCurrencyCode);
    window.ga(nameSpace + 'require', 'ecommerce');
    window.ga(nameSpace + 'ecommerce:addTransaction', {
      id: transactionId,
      affiliation: 'Classy',
      revenue: amount,
      shipping: '0',
      tax: '0'
    });

    if (!rwfCompletionEvent || donationAmount > 0) {
      var dataObject = {
        id: transactionId,
        name: 'Donation',
        sku: '1',
        category: rwfCompletionEvent ? 'Additional Donation' : 'Direct Donation',
        price: rwfCompletionEvent ? donationAmount : amount,
        quantity: 1
      };
      window.ga(nameSpace + 'ecommerce:addItem', dataObject);
    }

    if (rwfCompletionEvent) {
      var items = buildTransactionItems(Object.assign(options, { transactionId: transactionId }));

      items.forEach(function (registration) {
        window.ga(nameSpace + 'ecommerce:addItem', registration);
      });
    }

    window.ga(nameSpace + 'ecommerce:send');
  }

  function trackEmbeddedGivingEvent(event, data) {
    debugLog('ga_embedded-giving', data);
    var opts = {
      hitType: 'event',
      eventCategory: 'embedded-giving',
      eventAction: event,
      meta: data
    };
    $window.ga(getNamespace(data.integration) + 'send', opts);
  }
}
})();