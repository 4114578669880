(function(){
'use strict';

angular.module('classy').controller('frsDonationDonorCtrl', ["$scope", "scMembersService", "scOrganizationsService", "scFroalaOptions", "CHAR_LIMITS", function ($scope, scMembersService, scOrganizationsService, scFroalaOptions, CHAR_LIMITS) {
  'use strict';

  /* ---------------------------------------------------------------------- *
   * Setup
   * ---------------------------------------------------------------------- */

  $scope.setup = {
    optionalQuestions: function optionalQuestions() {
      var optionalQuestions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      _.forEach(optionalQuestions, function (qDef) {
        if (qDef.location === 'donation_page' && qDef.is_deleted === false) {
          var answerModel = {
            question_id: qDef.id,
            answer: undefined
          };
          $scope.list.optionalQuestions[qDef.tag] = qDef;
          $scope.MODEL.answers.push(answerModel);
          $scope.META.answers[qDef.tag] = function () {
            // Note: We check args.length here rather than whether the first value is undefined
            // because we may need to be able to set the answer to undefined
            if (arguments.length) {
              answerModel.answer = arguments.length <= 0 ? undefined : arguments[0];
            }

            return answerModel.answer;
          };
        }
      });

      // add legacy defaults to prefix/title question when enum options are missing from response
      if ($scope.list.optionalQuestions.prefix && !($scope.list.optionalQuestions.prefix.enum_options || []).length) {
        $scope.list.optionalQuestions.prefix.enum_options = ['Mr.', 'Ms.', 'Mrs.', 'Miss', 'Dr.'].map(function (option) {
          return {
            label: option
          };
        });
      }

      // add legacy defaults to gender question when enum options are missing from response
      if ($scope.list.optionalQuestions.gender && !($scope.list.optionalQuestions.gender.enum_options || []).length) {
        $scope.list.optionalQuestions.gender.enum_options = ['Male', 'Female', 'Prefer not to state/Other'].map(function (option) {
          return {
            label: option
          };
        });
      }
    },
    customQuestions: function customQuestions() {
      var customQuestions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      _.forEach(customQuestions, function (qDef) {
        if (qDef.location === 'donation_page' && qDef.is_deleted === false) {
          var answerModel = {
            question_id: qDef.id,
            question_type: qDef.type,
            answer: undefined
          };
          $scope.list.customQuestions.push(qDef);
          $scope.MODEL.answers.push(answerModel);
          $scope.META.answers[qDef.id] = function () {
            // Note: We check args.length here rather than whether the first value is undefined
            // because we may need to be able to set the answer to undefined
            if (arguments.length) {
              answerModel.answer = arguments.length <= 0 ? undefined : arguments[0];
            }

            return answerModel.answer;
          };
        }
      });
      $scope.list.customQuestions.sort(function (a, b) {
        return a.weight - b.weight;
      });
    },
    memberFirstName: function memberFirstName() {
      return $scope.CONTEXT.autofill.first || _.get(scMembersService, 'active.current.first_name') || '';
    },
    memberLastName: function memberLastName() {
      return $scope.CONTEXT.autofill.last || _.get(scMembersService, 'active.current.last_name') || '';
    },
    memberEmailAddress: function memberEmailAddress() {
      return $scope.CONTEXT.autofill.email || _.get(scMembersService, 'active.current.email_address') || '';
    },
    memberOptIn: function memberOptIn() {
      return $scope.campaign.current.opt_in_checked_by_default;
    },
    memberPhone: function memberPhone() {
      return $scope.CONTEXT.autofill.phone || '';
    }
  };

  /* ---------------------------------------------------------------------- *
   * Constants
   * ---------------------------------------------------------------------- */
  $scope.CONSTANTS = {
    ANONYMOUS_DONATION_TOOLTIP_TEXT: 'Only the nonprofit will see your name so they can send receipts with tax information.',
    ANONYMOUS_DONATION_CHECKBOX_LABEL: 'Hide my name from the public.',
    ANONYMOUS_DONATION_AMOUNTS_LABEL: 'Hide my donation amount from the public.',
    BLOG_ADDRESS_REQUIRED_ERROR_TEXT: 'Please enter a blog address.',
    CELL_NUMBER_REQUIRED_ERROR_TEXT: 'Please enter your cell number.',
    CHARACTER_LIMIT_ERROR_TEXT: 'Please enter 1000 characters or less.',
    COMPANY_REQUIRED_ERROR_TEXT: 'Please enter a company.',
    DOLLAR_AMOUNT_VALID_ERROR_TEXT: 'Please enter a valid dollar amount.',
    EMAIL_ADDRESS_REQUIRED_ERROR_TEXT: 'Don\'t forget your email address.',
    EMAIL_ADDRESS_FORMAT_ERROR_TEXT: 'Please enter in the format: name@domain.com.',
    FIELD_REQUIRED_ERROR_TEXT: 'This field is required.',
    GENDER_REQUIRED_ERROR_TEXT: 'Please select a gender.',
    PRONOUN_REQUIRED_ERROR_TEXT: 'Please select a pronoun.',
    NUMBERS_ONLY_ERROR_TEXT: 'Please enter numbers only.',
    PHONE_NUMBER_VALID_ERROR_TEXT: 'Please enter a valid phone number.',
    PHONE_NUMBER_REQUIRED_ERROR_TEXT: 'Don\'t forget your phone number.',
    TITLE_REQUIRED_ERROR_TEXT: 'Please select a title.',
    SUFFIX_REQUIRED_ERROR_TEXT: 'Please select a suffix.',
    WEBSITE_REQUIRED_ERROR_TEXT: 'Please enter a website.',
    CHARACTER_LIMIT_255_MESSAGE: 'Please limit your answer to 255 or less characters.'
  };

  $scope.phoneValidationRegex = /^[0-9]*$/;

  /* ---------------------------------------------------------------------- *
   * Collections
   * ---------------------------------------------------------------------- */

  $scope.list = {
    optionalQuestions: {},
    customQuestions: []
  };

  /* ---------------------------------------------------------------------- *
   * Display flags
   * ---------------------------------------------------------------------- */

  $scope.show = {
    anonymous: function anonymous() {
      return !$scope.campaign.current.hide_anonymous_donations;
    },
    anonymousDonationAmounts: function anonymousDonationAmounts() {
      return $scope.campaign.current.hide_donation_amounts;
    },
    prefix: function prefix() {
      return $scope.list.optionalQuestions.prefix;
    },
    middleName: function middleName() {
      return $scope.list.optionalQuestions.middle_name;
    },
    suffix: function suffix() {
      return $scope.list.optionalQuestions.suffix;
    },
    optIn: function optIn() {
      return !$scope.campaign.current.hide_donor_opt_in;
    },
    comment: function comment() {
      return !$scope.campaign.current.hide_donation_comments;
    },
    cellPhone: function cellPhone() {
      return $scope.list.optionalQuestions.cellphone;
    },
    homePhone: function homePhone() {
      return $scope.list.optionalQuestions.homephone;
    },
    mgive: function mgive() {
      return !!$scope.organization.current.mgive_settings && $scope.list.optionalQuestions.cellphone && $scope.list.optionalQuestions.text_opt_in;
    },
    company: function company() {
      return $scope.list.optionalQuestions.company_name;
    },
    blog: function blog() {
      return $scope.list.optionalQuestions.blog;
    },
    website: function website() {
      return $scope.list.optionalQuestions.website;
    },
    gender: function gender() {
      return $scope.list.optionalQuestions.gender;
    },
    pronoun: function pronoun() {
      return $scope.list.optionalQuestions.pronoun;
    },
    birthdate: function birthdate() {
      return $scope.list.optionalQuestions.birthdate;
    },
    customQuestions: function customQuestions() {
      return $scope.list.customQuestions.length;
    }
  };

  $scope.get = {
    homephone: function homephone(field) {
      return _.get($scope.list.optionalQuestions, 'homephone.' + field, false);
    }
  };

  /* ---------------------------------------------------------------------- *
   * Constructed bindings
   * ---------------------------------------------------------------------- */

  $scope.build = {
    mgiveMessage: function mgiveMessage() {
      return $scope.organization.current.mgive_settings.messaging;
    },
    nameError: function nameError() {
      // This will be fun to unit test
      var first = $scope.UTIL.showError('member_first_name', 'required') ? 'first' : '',
          middle = $scope.UTIL.showError('optional_middle_name', 'required') ? 'middle' : '',
          last = $scope.UTIL.showError('member_last_name', 'required') ? 'last' : '',
          comma = first && middle && last ? ', ' : '',
          and1 = first && (middle || last) && !comma ? ' and ' : '',
          and2 = middle && last ? ' and ' : '';

      return 'Be sure to provide your ' + first + comma + and1 + middle + comma + and2 + last + ' name.';
    },
    birthdateError: function birthdateError() {
      var month = $scope.UTIL.showError('optional_birthdate', 'monthRequired') ? 'month' : '',
          day = $scope.UTIL.showError('optional_birthdate', 'dayRequired') ? 'day' : '',
          year = $scope.UTIL.showError('optional_birthdate', 'yearRequired') ? 'year' : '',
          and1 = month && (day || year) ? ' and ' : '',
          and2 = day && year ? ' and ' : '',
          birthday = !month && !day && !year ? 'birthdate' : '';

      return 'Please select a ' + (birthday || month + and1 + day + and2 + year) + '.';
    }
  };

  /* ---------------------------------------------------------------------- *
   * API
   * ---------------------------------------------------------------------- */

  $scope.template = function (name) {
    return 'global/objects/scBlock/types/frs-donation/components/donor/partials/' + name;
  };

  /* ---------------------------------------------------------------------- *
   * Preflight
   * ---------------------------------------------------------------------- */

  $scope.PREFLIGHT.parseMemberName = function () {
    $scope.MODEL.member_first_name = $scope.META.member_first_name;
    $scope.MODEL.member_last_name = $scope.META.member_last_name;
    $scope.MODEL.member_name = $scope.META.member_first_name + ' ' + $scope.META.member_last_name;
  };

  $scope.PREFLIGHT.filterAnswers = function () {
    var acceptableFalsyValues = [0, false];
    $scope.MODEL.answers = _.filter($scope.MODEL.answers, function (obj) {
      return obj.answer || acceptableFalsyValues.includes(obj.answer);
    });
  };

  /* ---------------------------------------------------------------------- *
   * Init
   * ---------------------------------------------------------------------- */

  _.merge($scope.MODEL, {
    answers: [],
    comment: null,
    member_name: null,
    member_email_address: $scope.setup.memberEmailAddress(),
    member_phone: $scope.setup.memberPhone(),
    is_anonymous: false,
    opt_in: $scope.setup.memberOptIn(),
    opt_in_wording: _.get(scOrganizationsService, 'active.current.opt_in_wording')
  });

  _.merge($scope.META, {
    answers: {},
    member_first_name: $scope.setup.memberFirstName(),
    member_last_name: $scope.setup.memberLastName()
  });

  $scope.setup.optionalQuestions(_.get($scope, 'campaign.current.questions.optional'));
  $scope.setup.customQuestions(_.get($scope, 'campaign.current.questions.custom'));
  $scope.froalaCommentOptions = scFroalaOptions({ type: 'textarea-counter' }, {
    charCounterCount: true,
    charCounterMax: CHAR_LIMITS.DEFAULT_PAGE_COMMENT,
    htmlAllowedAttrs: ['aria-.*'],
    ariaLabel: 'Leave a comment'
  });
  $scope.froalaCustomQuestionsOptions = scFroalaOptions({ type: 'textarea-counter' }, {
    charCounterCount: true,
    charCounterMax: CHAR_LIMITS.DEFAULT_PAGE_CUSTOM_QUESTION
  });
}]);
})();