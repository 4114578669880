(function(){
'use strict';

angular.module('classy').component('vanityUrlField', {
  require: {
    form: '^form'
  },
  template: '\n      <div class="vanity-url-field__label">\n        <form-label\n        required="$ctrl.required"\n        _spec="label">\n          {{:: $ctrl.label }}\n          <div class="form__label url-preview">\n            <span class="domain" ng-bind="$ctrl.previewUrl()"></span><span class="vanity" ng-hide="$ctrl.model">ExampleShortUrl</span><span class="vanity" ng-show="$ctrl.model" ng-bind="$ctrl.model"></span>\n          </div>\n        </form-label>\n      </div>\n\n      <div class="form__input-wrapper">\n\n        <input class="form__text-input--dark"\n        ng-model="$ctrl.model"\n        ng-model-options="{ getterSetter: $ctrl.getterSetter }"\n        ng-required="$ctrl.required"\n        sc-vanity\n        sc-vanity-type="{{:: $ctrl.entityType }}"\n        sc-validity-reporter\n        sc-validity-classes\n        ng-disabled="$ctrl.disableFlag"\n        type="text"\n        name="{{:: $ctrl.name }}"\n        placeholder="{{:: $ctrl.placeholder }}"\n        id="vanityUrlField"\n        _spec="input"\n        aria-label="Set your vanity URL"/>\n\n        <i class="fa fa-check-circle form__async-validation-icon--valid"\n        data-ng-class="{ visible: $ctrl.urlValid() }"\n        _spec="valid"></i>\n\n        <i class="fa fa-times-circle form__async-validation-icon--invalid"\n        data-ng-class="{ visible: $ctrl.urlInvalid() }"\n        _spec="invalid"></i>\n\n      </div>\n\n      <form-error\n      name="$ctrl.name"\n      messages="$ctrl.errorMessages"\n      _spec="errors"></form-error>\n    ',
  bindings: {
    campaignId: '<',
    entityType: '<',
    model: '=',
    errorMessages: '<?',
    getterSetter: '<?',
    label: '<?',
    name: '<?',
    required: '<?',
    disableFlag: '=?'
  },
  controller: function VanityUrlFieldCtrl() {
    var _this = this;

    var DEFAULTS = {
      errorMessages: {
        required: 'This field is required.',
        slugAvailable: 'Sorry, that URL is not available.'
      },
      getterSetter: false,
      label: 'Set your vanity URL',
      name: 'vanityUrl',
      placeholder: 'Start typing',
      required: false
    };

    this.$onInit = function () {
      _.defaultsDeep(_this, DEFAULTS);
    };

    this.urlValid = function () {
      return _.get(this.form, [this.name, '$modelValue']) && !_.get(this.form, [this.name, '$pending', 'slugAvailable']) && !_.get(this.form, [this.name, '$error', 'slugAvailable']) && !_.get(this.form, [this.name, '$pristine']);
    };

    this.urlInvalid = function () {
      return _.get(this.form, [this.name, '$error', 'slugAvailable']);
    };

    this.previewUrl = function () {
      var currUrl = document.location.origin;
      if (_.has(SC, 'organization.domain_mask.value')) {
        return 'https://' + SC.organization.domain_mask.value + '/';
      }
      if (currUrl.indexOf('www.classy.org') >= 0 || currUrl.indexOf('classy-test.org') >= 0) {
        currUrl = 'https://give.classy.org';
      }
      return currUrl + '/';
    };
  }
});
})();