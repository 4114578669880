(function(){
'use strict';

donationAmountCtrl.$inject = ["$scope", "scGetQueryParams", "RECURRING_LEVELS"];
angular.module('classy').controller('donationAmountCtrl', donationAmountCtrl);

function donationAmountCtrl($scope, scGetQueryParams, RECURRING_LEVELS) {
  // allow user to customize preset amounts via URL params

  var ONE_TIME_DEFAULT = RECURRING_LEVELS.ONE_TIME_DEFAULT,
      RECURRING_DEFAULT = RECURRING_LEVELS.RECURRING_DEFAULT,
      RECURRING_FIXED_AMOUNT = RECURRING_LEVELS.RECURRING_FIXED_AMOUNT,
      RECURRING_ONLY = RECURRING_LEVELS.RECURRING_ONLY,
      ONE_TIME_ONLY = RECURRING_LEVELS.ONE_TIME_ONLY,
      ONE_TIME_FIXED_AMOUNT = RECURRING_LEVELS.ONE_TIME_FIXED_AMOUNT;


  $scope.maxDonation = 10000000;
  $scope.minDonation = 1;

  /**
   * $scope.buttonPresets comes from the parent donationMainCtrl.js. Charities chan choose to preset
   * the donation amount button values by passing query params like so:
   *
   * ?preset1=100&preset2=200&preset3=300&preset4=400
   */
  if (!_.isEmpty($scope.buttonPresets)) {
    $scope.block['donation-levels'].forEach(function (value, index) {
      var presetValue = $scope.buttonPresets['preset' + (index + 1)];

      if (presetValue) {
        value.displayAmount = presetValue;
        value.amount = presetValue;
      }
    });
  }

  /**
   * Some old preset values might not have displayAmount set, so we do this for backwards compatibility
   */
  _.forEach($scope.block['donation-levels'], function (level) {
    level.displayAmount = angular.copy(level.presetAmount) || angular.copy(level.amount);
  });

  /**
   * If ?amount=# is passed in the query string, that amount is used as the default donation amount
   */

  var _scGetQueryParams = scGetQueryParams(),
      presetAmount = _scGetQueryParams.amount;

  $scope.Donation.amount = parseInt(presetAmount, 10);

  /**
   * The charity can set a default donation amount for the block itself, which is used to prefill the amount field
   */
  if ($scope.block['donation-default'] !== 'Other' && !$scope.Donation.amount) {
    $scope.Donation.amount = $scope.block['donation-default'];
  }

  /**
   * Recurring donations are DISABLED for this legacy-submit donation block, we're just
   * looking at the recurring-level stuff to determine if there should be a fixed amount
   */
  switch ($scope.block['recurring-level']) {
    case ONE_TIME_DEFAULT:
    case RECURRING_DEFAULT:
    case RECURRING_ONLY:
    case ONE_TIME_ONLY:
      $scope.fixedAmount = false;
      break;

    case RECURRING_FIXED_AMOUNT:
    case ONE_TIME_FIXED_AMOUNT:
      $scope.fixedAmount = true;
      break;

    default:
      $scope.fixedAmount = false;
  }

  $scope.Donation.amountChanged = false;
  /*
   * Select Donation click event
   */
  $scope.selectDonation = function (amount) {
    $scope.Donation.amount = amount == 'Other' ? '' : parseInt(amount, 10);
    $scope.Donation.amountChanged = true;

    return false;
  };

  $scope.donationLevelStyle = function () {
    var styles = {
      'background-color': $scope.theme.styles.primaryColor
    };

    return styles;
  };

  $scope.donationLevelClass = function (amount) {
    if (amount === 'Other' && $scope.Donation.amount === '' && $scope.Donation.amountChanged) {
      return { selected: true };
    }

    return {
      selected: amount == $scope.Donation.amount
    };
  };
}
})();