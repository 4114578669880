(function(){
'use strict';

scIntlService.$inject = ["$log", "scUserAgent"];
angular.module('classy').service('scIntlService', scIntlService);

function scIntlService($log, scUserAgent) {
  // See https://classydev.atlassian.net/browse/FRS-6087
  // The polyfill service we use for our Intl dependency parses useragents. However, the Prerender
  // UserAgent string causes the service to think that the browser supports Intl, so our polyfill
  // is empty. In order to avoid issues where we're plugging UserAgent data directly into
  // everywhere that uses Intl, we wrap the calls to Intl in this service, which will at least
  // return *something* somewhat reasonable, even if it's not really a well-rendered currency
  // number. Since this code should only be hit by Prerender, this is not a significant problem.
  return {
    NumberFormat: function NumberFormat(language, options) {
      if (scUserAgent.isBot()) {
        $log.log('Intl library was unavailable, falling back to rendering bare currency code');
        // return an object shaped like the Intl object would return so that code calling
        // this method will work the same regardless of whether Intl is present or not
        return { format: function format(value) {
            return '' + options.currency + value;
          } };
      }

      return new Intl.NumberFormat(language, options);
    }
  };
}
})();