(function(){
'use strict';

cpAPI.$inject = ["$http", "scOrganizationsService"];
angular.module('classy').service('cpAPI', cpAPI);

function cpAPI($http, scOrganizationsService) {
  this.getTokenexData = function () {
    return payGetToken('tokenexCC', { origin: origin }).then(function (response) {
      return response.data;
    });
  };

  this.getBraintreeToken = function (currency) {
    var applicationId = this.getApplicationId();
    return payGetToken('braintree', {
      applicationId: applicationId,
      currency: currency
    }).then(function (response) {
      return response.data;
    });
  };

  this.getBitpayInvoice = function (amount, email) {
    var applicationId = this.getApplicationId();
    return payGetToken('bitpay', {
      applicationId: applicationId,
      amount: amount,
      email: email
    }).then(function (response) {
      return response.data;
    });
  };

  this.getStripeData = function (currency) {
    var applicationId = this.getApplicationId();
    return payGetToken('stripe', {
      applicationId: applicationId,
      currency: currency
    }).then(function (response) {
      return response.data;
    });
  };

  this.getStripePublishableKey = function () {
    return payGetToken('stripePublishableKey').then(function (response) {
      return response.data;
    });
  };

  this.getPlaidToken = function (currency) {
    var applicationId = this.getApplicationId();
    return payGetToken('plaid', {
      applicationId: applicationId,
      currency: currency
    }).then(function (response) {
      return response.data;
    });
  };

  this.getPayPalCommerceData = function () {
    var currency = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'USD';

    var applicationId = this.getApplicationId();
    return payGetToken('paypal', {
      applicationId: applicationId,
      currency: currency
    }).then(function (response) {
      return response.data;
    });
  };

  this.getPayPalBillingAgreementToken = function () {
    var applicationId = this.getApplicationId();
    return payGetToken('paypalBillingAgreement', {
      applicationId: applicationId
    }).then(function (response) {
      return response.data;
    });
  };

  this.getAccountRoutingTokenExConfig = function (origin) {
    return payGetToken('tokenexAch', { origin: origin }).then(function (response) {
      return response.data;
    });
  };

  this.getApplicationId = function () {
    // DO NOT CONVERT TO NUMBER - it is too large and JS will round it.
    return scOrganizationsService.getTag('classyPayApplicationId');
  };

  /* Pay Api Helpers
  ========================================================= */
  function payGetToken(paymentMethod) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var payHost = SC.pay;
    return $http({
      url: payHost + '/token/' + paymentMethod,
      method: 'GET',
      params: params,
      // Note that Classy Pay has no concept of CSRF, but if we don't include
      // this header, then Angular will automatically add it, and the API
      // call will fail.
      headers: {
        'csrf-token': undefined
      }
    });
  }
}
})();