(function(){
'use strict';

angular.module('classy').directive('egResizer', ["$window", function ($window) {
  return {
    restrict: 'EA',
    link: function link(scope, elem) {
      var win = angular.element($window);
      var MAX_HEIGHT = 620;
      var MAX_WIDTH = 480;

      var MIN_HEIGHT = 450;
      var MIN_WIDTH = 320;

      function __init() {
        __resize();

        win.on('resize', __resize);

        scope.$on('$destroy', function () {
          win.off('resize', __resize);
        });
      }

      function __resize() {
        var newHeight = void 0,
            newWidth = void 0;
        var height = $window.innerHeight;
        var width = $window.innerWidth;

        if (height > MAX_HEIGHT) {
          newHeight = MAX_HEIGHT;
        } else if (height < MIN_HEIGHT) {
          newHeight = MIN_HEIGHT;
        } else {
          newHeight = height;
        }

        if (width > MAX_WIDTH) {
          newWidth = MAX_WIDTH;
        } else if (width < MIN_WIDTH) {
          newWidth = MIN_WIDTH;
        } else {
          newWidth = width;
        }

        elem.css({
          height: newHeight,
          width: newWidth
        });
      }

      __init();
    }
  };
}]);
})();