(function(){
'use strict';

angular.module('classy').component('cpAchTokenizer', {
  require: {
    ngModelCtrl: 'ngModel'
  },
  template: '<div aria-labelledby="bank-account-routing-label" data-tracked-element="donation-page_checkout_account-number-input" class="cp-ach-tokenizer-control__iframe {{$ctrl.isLoading ? \'loading\' : \'\'}} {{$ctrl.showErrorMsg ? \'sc-form-error\' : \'\'}}" id="accountNumberContainer" data-sc-form-error="highlight if accountNumber fails">\n              <div ng-style="{display: $ctrl.isLoading ? \'none\' : \'block\'}" id="account-number-iframe-container"></div>\n              <span class="cp-ach-tokenizer__status-loading" aria-hidden="true"></span>\n            </div>\n            <p class="form__error" data-sc-form-error="show if accountNumber fails customRegEx" ng-hide="$ctrl.showErrorMsg" aria-live="polite">{{$ctrl.errorMsgs[\'customRegEx\']}}</p>\n            <p class="form__error" data-sc-form-error="show if accountNumber fails format" ng-hide="$ctrl.showErrorMsg" aria-live="polite">{{$ctrl.errorMsgs[\'format\']}}</p>\n            <p class="form__error" data-sc-form-error="show if accountNumber fails required" ng-hide="$ctrl.showErrorMsg" aria-live="polite">{{$ctrl.errorMsgs[\'required\']}}</p>\n            <p class="form__error" data-sc-form-error="hide if accountNumber fails" ng-if="$ctrl.showErrorMsg" aria-live="polite" ng-bind="$ctrl.errorMessage"></p>',
  bindings: {
    onFocus: '<'
  },
  controller: ["cpAPI", "$window", "$scope", "$timeout", function controller(cpAPI, $window, $scope, $timeout) {
    var _this = this;

    var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    this.$onInit = function () {
      _this.id = 'account-number-iframe-container';
      _this.isLoading = true;

      _this.buildIframe();
      _this.errorMsgs = {
        required: 'Account number is required.',
        format: 'Account number should be greater than 4 and less than 17 digits.',
        customRegEx: 'Account number should be greater than 4 and less than 17 digits.'
      };
      _this.showErrorMsg = false;
      $scope.$on('tokenizer', function (_event, data) {
        if (data === 'reset') {
          _this.iframe.reset();
        }
      });
    };

    this.updateErrorMessage = function (validation) {
      _this.errorMessage = _this.errorMsgs[validation.validator];
      _this.showErrorMsg = !validation.isValid && _this.ngModelCtrl.$dirty;
    };

    /** -------------------------------------------------------------------- *
     * Setup
     * -------------------------------------------------------------------- */

    this.buildIframe = function () {
      _this.ngModelCtrl.$setViewValue(null);
      _this.ngModelCtrl.$setPristine();
      _this.ngModelCtrl.$setUntouched();
      var origin = $window.location.origin;

      cpAPI.getAccountRoutingTokenExConfig(origin).then(function (data) {
        data.styles = {
          base: '\n              width:85%;\n              height:38px;\n              background:transparent;\n              border:0;\n              line-height:20px;\n              vertical-align:middle;\n              font-size:16px;\n              font-family:\'Mulish\',Arial,Helvetica,sans-serif;\n              color: #444b55;\n              outline:none;\n              padding:0 10px;\n              font-weight:400;\n            ',
          placeholder: '\n              color: #444b55;\n              opacity:0.5;\n              font-family:"Mulish",Arial,Helvetica,sans-serif;\n              font-weight:400;\n            '
        };
        data.font = 'Mulish';
        data.placeholder = 'Account number';

        // eslint-disable-next-line no-undef
        _this.iframe = new TokenEx.Iframe(_this.id, data);
        _this.iframe.on('focus', _this.handleFocus);
        _this.iframe.on('blur', _this.handleBlur);
        _this.iframe.on('validate', _this.handleValidate);
        _this.iframe.on('tokenize', _this.handleToken);
        _this.iframe.on('change', _this.handleChange);
        _this.iframe.on('load', _this.handleLoad);

        _this.iframe.load();
      });
    };

    /** -------------------------------------------------------------------- *
     * Event Handlers
     * -------------------------------------------------------------------- */
    this.handleToken = function (tokenData) {
      _this.ngModelCtrl.$setViewValue(tokenData.token);
      _this.ngModelCtrl.$setValidity('required', true);
    };

    this.handleValidate = function (validation) {
      _this.ngModelCtrl.$setValidity('customRegEx', true);
      _this.ngModelCtrl.$setValidity('required', true);
      _this.ngModelCtrl.$setValidity('format', true);
      if (isIE11) {
        // This was a fix for CL-10063 (an ie11 bug).
        // I decided to code defensively here and only execute when ie11
        _this.ngModelCtrl.$setValidity('origin', true);
      }
      if (!validation.isValid) {
        _this.ngModelCtrl.$setValidity(validation.validator, validation.isValid);
      }
      _this.ngModelCtrl.$syncValidity();
      _this.updateErrorMessage(validation);
    };

    this.handleBlur = function () {
      _this.onFocus(false);
    };

    this.handleFocus = function () {
      _this.onFocus(true);
    };

    this.handleChange = function () {
      _this.ngModelCtrl.$setDirty();
      _this.iframe.tokenize();
      _this.iframe.validate();
    };

    this.handleLoad = function () {
      // Using $timeout here to execute isLoading on the >current digest cycle.
      // This was done because on ie11 (sometimes) the iframe loader stayed visible.
      // Based on my experience with AngularJS, I believe this is a synchronization
      // between the browser (ie11 here) and AngularJS digest cycle.
      $timeout(function () {
        _this.isLoading = false;
      });
    };
  }]
});
})();