(function(){
'use strict';

angular.module('classy').service('EmbeddedGivingPaths', ["EmbeddedGivingPayPaths", function (EmbeddedGivingPayPaths) {
  var egPath = 'global/embedded-giving/';
  var componentsPath = egPath + 'components/';
  var partialsPath = egPath + 'views/partials/';
  var staticImagesPath = '/static/global/images/embedded-giving/';

  this.root = egPath + 'views/eg.views';

  this.views = {
    donatingMask: egPath + 'views/eg.views.donating-mask',
    donation: egPath + 'views/eg.views.donation', // donation consists of [1...M] partials ng-included
    faq: egPath + 'views/eg.views.faq',
    thankYou: egPath + 'views/eg.views.thank-you'
  };

  this.partials = {
    donationRoot: partialsPath + 'eg.views.partials.donation-root',
    donationPaymentMethods: partialsPath + 'eg.views.partials.donation-payment-methods',
    donationRecurringNudge: partialsPath + 'eg.views.partials.donation-recurring-nudge', // optional step
    donationAddress: partialsPath + 'eg.views.partials.donation-address', // optional step, configured via SDK. asks for full member address, required
    donationContact: partialsPath + 'eg.views.partials.donation-contact',
    donationDtD: partialsPath + 'eg.views.partials.donation-dtd', // option partial for "double the donation" (included in contact view)
    faqOverlay: partialsPath + 'eg.views.partials.faq-overlay' // Nests the FAQ view in an overlay (without modifying params)
  };

  this.pay = EmbeddedGivingPayPaths.views;

  this.images = {
    recurringNudge: staticImagesPath + 'embedded-giving-recurring-nudge.svg'
  };

  this.components = {
    campaignWhitelisting: componentsPath + 'campaign-whitelisting/eg.components.campaign-whitelisting'
  };
}]);
})();