(function(){
'use strict';

/* eslint-disable no-console */
// TODO pull this logic out into view/ctrl
angular.module('classy').component('egPayPayments', {
  templateUrl: 'global/embedded-giving/pay/views/payments/eg.pay.views.payments', // same folder as this
  bindings: {
    payment: '<',
    parentModel: '<',
    meta: '<',
    feeProcessing: '<',
    submitDigitalWallets: '<',
    api: '=',
    hidePostal: '<',
    disabledPaymentMethods: '<',
    currency: '<'
  },
  controller: ["$log", "$rootScope", "$state", "$timeout", "$q", "cpConstants", "EG_EVENTS", "EmbeddedGivingPay", "EmbeddedGivingUtil", "scAnalytics", "scDigitalWalletsService", "scOrganizationsService", "scFeeOnTopService", "scThemesService", "metaService", function controller($log, $rootScope, $state, $timeout, $q, cpConstants, EG_EVENTS, EmbeddedGivingPay, EmbeddedGivingUtil, scAnalytics, scDigitalWalletsService, scOrganizationsService, scFeeOnTopService, scThemesService, metaService) {
    var _this = this;

    var STATUS = cpConstants.STATUS,
        EVENTS = cpConstants.EVENTS,
        PAYMENT_METHODS = cpConstants.PAYMENT_METHODS;

    this.STATUS = STATUS;
    this.PAYMENT_METHODS = PAYMENT_METHODS;
    this.EVENTS = EVENTS;

    this.isTrial = scOrganizationsService.getOrgStatus() === 'trial';
    this.theme = scThemesService.active.current;

    this.paymentTypeInitStatus = function (type) {
      return _.get(_this.payment, type + '.status') || STATUS.INACTIVE;
    };

    this.$onInit = function () {
      _this.parentModel = _this.parentModel || {};
      _this.disabledPaymentMethods = _this.disabledPaymentMethods || [];
      _this.isDonationCheckoutPage = !!$state.current.name.includes('frs.donation.checkout');

      // TODO move to EGPayDAL?
      _this.api = _this.api || {};
      _this.api.setModelPayment = _this.setModelPayment.bind(_this);
      _this.api.getPaymentLabel = _this.getPaymentLabel.bind(_this);
      _this.api.reloadTokenexIframe = _this.reloadTokenexIframe.bind(_this);
      _this.api.getPaymentMethod = _this.getPaymentMethod.bind(_this);
      _this.api.setModelPaymentMethodGateway = _this.setModelPaymentMethodGateway.bind(_this);
      _this.api.showFeeOnTop = _this.showFeeOnTop.bind(_this);

      _this.getProcessors().then(function (processors) {
        _this.processors = processors;
        _this.isPaypalReady = _.get(_this.payment.paypal, 'status', null) !== null;
        _this.syncPaymentMethodStatuses(_this.isPaypalReady);
      });

      scOrganizationsService.hasACHAccountRouting().then(function (result) {
        var isStripeACH = _.get(_this.processors, 'ACH.name', null) === 'STRIPE';
        _this.achAccountRoutingEnabled = result && isStripeACH;
      });

      _this.paymentDetails = '';
    };

    this.$onChanges = function (_ref) {
      var currency = _ref.currency;

      // don't worry about first uninitialized value or when currency is undefined
      if (!currency || currency.isFirstChange()) return;
      // if org doesn't even have ach, we don't need to do anything
      if (!_.has(_this.processors, PAYMENT_METHODS.ACH) && !_.has(_this.processors, PAYMENT_METHODS.PAYPAL_COMMERCE) && !_.has(_this.processors, PAYMENT_METHODS.VENMO)) return;

      if (currency.currentValue !== 'USD') {
        // if ach or paypal commerce was selected, lets clear the values and hide the bottom of the form
        var paymentMethod = _this.getPaymentMethod();
        if (paymentMethod === PAYMENT_METHODS.ACH || paymentMethod === PAYMENT_METHODS.PAYPAL_COMMERCE || paymentMethod === PAYMENT_METHODS.VENMO) {
          _this.hidePaymentDetails();
          _this.syncPaymentMethodStatuses();
        }
        _this.disabledPaymentMethods.push(PAYMENT_METHODS.ACH);
        _this.disabledPaymentMethods.push(PAYMENT_METHODS.PAYPAL_COMMERCE);
        _this.disabledPaymentMethods.push(PAYMENT_METHODS.VENMO);

        _this.payment.ach.status = STATUS.INACTIVE;
        _this.payment.paypal_commerce.status = STATUS.INACTIVE;
        _this.payment.venmo.status = STATUS.INACTIVE;
      } else {
        // remove ACH and PayPal Commerce from disabled payment methods
        _this.disabledPaymentMethods = _this.disabledPaymentMethods.filter(function (paymentMethod) {
          return paymentMethod !== PAYMENT_METHODS.ACH && paymentMethod !== PAYMENT_METHODS.PAYPAL_COMMERCE && paymentMethod !== PAYMENT_METHODS.VENMO;
        });

        // this.payment.ach.status = STATUS.READY;
        // this.payment.paypal_commerce.status = STATUS.READY;
        _this.syncPaymentMethodStatuses();
      }
    };

    this.syncPaymentMethodStatuses = function (isPaypalReady) {
      _this.payment.ach = {
        status: _this.getPaymentMethodStatus(_this.processors, PAYMENT_METHODS.ACH)
      };

      _this.payment.cc = {
        status: _this.getPaymentMethodStatus(_this.processors, PAYMENT_METHODS.CC)
      };

      // Only override status on reinitializing processors since we could end up deleting
      // api properties set by stripeWidget component (like getSource)
      _this.payment.stripe = Object.assign(_this.payment.stripe || {}, {
        status: _this.getPaymentMethodStatus(_this.processors, PAYMENT_METHODS.STRIPE)
      });

      _this.payment.paypal_commerce = {
        status: _this.getPaymentMethodStatus(_this.processors, PAYMENT_METHODS.PAYPAL_COMMERCE)
      };

      _this.payment.venmo = {
        status: _this.getPaymentMethodStatus(_this.processors, PAYMENT_METHODS.VENMO)
      };

      /* Adding this condition to prevent Paypal button from appearing twice */
      if (!isPaypalReady || _this.payment.paypal.status === STATUS.PENDING) {
        _this.payment.paypal = {
          status: _this.getPaymentMethodStatus(_this.processors, PAYMENT_METHODS.PAYPAL)
        };
      }

      scFeeOnTopService.setAmex(false);
    };

    this.isValidDonationAmount = function () {
      return _this.amount && _this.amount > 0;
    };

    this.showDigitalWalletsListener = function (show) {
      _this.showDW = show;
    };

    scDigitalWalletsService.addShowDigitalWalletsListener(this.showDigitalWalletsListener);

    scDigitalWalletsService.addOnDigitalWalletsSubmitListener(function () {
      _this.syncPaymentMethodStatuses();
    });

    this.getProcessors = function () {
      return EmbeddedGivingPay.getPaymentProcessors();
    };

    this.getPaymentMethod = function () {
      // TODO why this order?
      if (_this.paymentTypeInitStatus('paypal') === STATUS.SUCCESS) {
        return PAYMENT_METHODS.PAYPAL;
      } else if (_this.paymentTypeInitStatus('paypal_commerce') === STATUS.SUCCESS) {
        return PAYMENT_METHODS.PAYPAL_COMMERCE;
      } else if (_this.paymentTypeInitStatus('venmo') === STATUS.SUCCESS) {
        return PAYMENT_METHODS.VENMO;
      } else if (_this.paymentTypeInitStatus('ach') === STATUS.SUCCESS) {
        return PAYMENT_METHODS.ACH;
      } else if (_this.show.stripe()) {
        return PAYMENT_METHODS.STRIPE;
      }
      return PAYMENT_METHODS.CC;
    };

    this.getPaymentMethodStatus = function (processors, paymentMethod) {
      if (processors[paymentMethod] && !_this.disabledPaymentMethods.includes(paymentMethod)) {
        return STATUS.READY;
      } else {
        return STATUS.INACTIVE;
      }
    };

    this.getPaymentLabel = function () {
      // move to model
      switch (_this.getPaymentMethod()) {
        case PAYMENT_METHODS.STRIPE:
        case PAYMENT_METHODS.CC:
          {
            return 'Card Number';
          }
        case PAYMENT_METHODS.ACH:
          {
            return 'Bank Info';
          }
        case PAYMENT_METHODS.PAYPAL_COMMERCE:
        case PAYMENT_METHODS.PAYPAL:
          {
            return 'PayPal Account';
          }
        case PAYMENT_METHODS.VENMO:
          {
            return 'Venmo Account';
          }
        default:
          return 'Payment';
      }
    };

    this.setModelPayment = function () {
      var paymentMethod = _this.setModelPaymentMethodGateway();
      var paymentPromise = void 0;

      switch (paymentMethod) {
        case PAYMENT_METHODS.CC:
          {
            paymentPromise = _this.getTokenexToken();
            break;
          }
        case PAYMENT_METHODS.STRIPE:
          {
            paymentPromise = _this.payment.stripe.getSource();
            break;
          }
        case PAYMENT_METHODS.ACH:
        case PAYMENT_METHODS.PAYPAL:
        case PAYMENT_METHODS.PAYPAL_COMMERCE:
        case PAYMENT_METHODS.VENMO:
          {
            paymentPromise = $q.resolve();
            break;
          }
        default:
          return $q.reject(new Error('invalid payment method'));
      }

      return paymentPromise;
    };

    // we only show feeOnTop in the footer if the donation page only takes credit card,
    // or if they take credit card and have digital wallets enabled, but browser/user does not support DW
    this.showFeeOnTop = function () {
      return _this.isStripeOrCCOnly() || _this.isStripeOrCCAndDigitalWalletsOnly() && !_this.showDw;
    };

    /* Internal Methods
      ========================================================= */
    this.isStripeOrCCOnly = function () {
      return !_.has(_this.processors, PAYMENT_METHODS.DW) && !_.has(_this.processors, PAYMENT_METHODS.ACH) && !_.has(_this.processors, PAYMENT_METHODS.PAYPAL) && !_.has(_this.processors, PAYMENT_METHODS.PAYPAL_COMMERCE) && !_.has(_this.processors, PAYMENT_METHODS.VENMO);
    };

    this.isStripeOrCCAndDigitalWalletsOnly = function () {
      return _.has(_this.processors, PAYMENT_METHODS.DW) && !_.has(_this.processors, PAYMENT_METHODS.ACH) && !_.has(_this.processors, PAYMENT_METHODS.PAYPAL) && !_.has(_this.processors, PAYMENT_METHODS.PAYPAL_COMMERCE) && !_.has(_this.processors, PAYMENT_METHODS.VENMO);
    };

    this.isStripeOrCCAndACHOnly = function () {
      return _.has(_this.processors, PAYMENT_METHODS.ACH) && !_.has(_this.processors, PAYMENT_METHODS.DW) && !_.has(_this.processors, PAYMENT_METHODS.PAYPAL) && !_.has(_this.processors, PAYMENT_METHODS.PAYPAL_COMMERCE) && !_.has(_this.processors, PAYMENT_METHODS.VENMO);
    };

    this.isStripeOrCCAndPayPalCommerceOnly = function () {
      return _.has(_this.processors, PAYMENT_METHODS.PAYPAL_COMMERCE) && !_.has(_this.processors, PAYMENT_METHODS.ACH) && !_.has(_this.processors, PAYMENT_METHODS.DW) && !_.has(_this.processors, PAYMENT_METHODS.PAYPAL) && !_.has(_this.processors, PAYMENT_METHODS.VENMO);
    };

    this.isStripeOrCCAndVenmoOnly = function () {
      return _.has(_this.processors, PAYMENT_METHODS.VENMO) && !_.has(_this.processors, PAYMENT_METHODS.ACH) && !_.has(_this.processors, PAYMENT_METHODS.DW) && !_.has(_this.processors, PAYMENT_METHODS.PAYPAL) && !_.has(_this.processors, PAYMENT_METHODS.VENMO);
    };

    this.showPaymentDetails = function () {
      scAnalytics.track(EG_EVENTS.donationDetails, EmbeddedGivingUtil.getAnalyticsEventData());
      _this.meta.showPaymentDetails = true;
    };

    this.hidePaymentDetails = function () {
      // need here because ctrl fxns dont watch
      scAnalytics.track(EG_EVENTS.donation, EmbeddedGivingUtil.getAnalyticsEventData());
      _this.meta.showPaymentDetails = false;
    };

    this.showCardInputs = function () {
      _this.payment.cc.status = STATUS.READY;
      _this.payment.stripe.status = STATUS.READY;
      _this.syncPaymentMethodStatuses();
      _this.showPaymentDetails();
      _this.paymentDetails = '';
      $rootScope.$broadcast('checkPaymentAuthStatus', false);
      $rootScope.$broadcast('donationPage:recalculateFees');
    };

    this.showAccountRouting = function () {
      _this.syncPaymentMethodStatuses();
      _this.payment.ach.status = STATUS.SUCCESS;
      _this.payment.cc.status = STATUS.PENDING;
      _this.payment.stripe.status = STATUS.PENDING;

      _this.showPaymentDetails();
      _this.paymentDetails = '';
      $rootScope.$broadcast('checkPaymentAuthStatus', true);
      $rootScope.$broadcast('donationPage:recalculateFees');
    };

    this.resetAccountRouting = function () {
      _this.payment.ach = {
        status: STATUS.SUCCESS
      };
    };

    this.setModelPaymentMethodGateway = function () {
      var isDigitalWallets = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      var paymentMethod = isDigitalWallets ? PAYMENT_METHODS.STRIPE : _this.getPaymentMethod();
      _this.payment.method = paymentMethod;
      _this.payment.gateway = _this.processors[paymentMethod];
      return paymentMethod;
    };

    // NOTE: cc + stripe / paypal + paypal commerce are the only payment methods that are intrinsically linked with the other
    // This means, one cannot be shown simultaneously with the other. That is why the logic for their show()
    // methods references properties of each other.
    this.show = {
      cc: function cc() {
        var stripeCurrencyOverride = _.get(_this.processors, [PAYMENT_METHODS.STRIPE, 'currency']);
        var ccCurrencyOverride = _.get(_this.processors, [PAYMENT_METHODS.CC, 'currency']);
        if (_this.paymentTypeInitStatus('cc') !== STATUS.READY) return false;

        // There is a chance the org still has an old processor active, but under the same currency as
        // Stripe, so we need to hide the tokenex iframe.
        if (_this.currency === stripeCurrencyOverride) return false;

        // If the currency matches for CC but not Stripe, we assume the org wants to show tokenex for this
        // specific currency
        if (_this.currency === ccCurrencyOverride) return true;

        // If the currency is not the default currency for CC BUT the org is not on Stripe,
        // then we show tokenex anyway, as the currency will be converted to the org's
        // default currency
        return _this.paymentTypeInitStatus('stripe') !== STATUS.READY;
      },
      stripe: function stripe() {
        var stripeCurrencyOverride = _.get(_this.processors, [PAYMENT_METHODS.STRIPE, 'currency']);
        var ccCurrencyOverride = _.get(_this.processors, [PAYMENT_METHODS.CC, 'currency']);

        if (_this.paymentTypeInitStatus('stripe') !== STATUS.READY) return false;

        if (_this.currency === stripeCurrencyOverride) return true;

        // Stripe always supercedes other processors for credit card transactions UNLESS that
        // processor explicitly lists the selected currency as it's default currency
        return _this.currency !== ccCurrencyOverride;
      },
      achButton: function achButton() {
        return _this.paymentTypeInitStatus('ach') !== STATUS.INACTIVE && !_this.achAccountRoutingEnabled;
      },
      ach: function ach() {
        return _this.paymentTypeInitStatus('ach') === STATUS.SUCCESS && !_this.achAccountRoutingEnabled;
      },
      accountRoutingButton: function accountRoutingButton() {
        return _this.paymentTypeInitStatus('ach') !== STATUS.INACTIVE && _this.achAccountRoutingEnabled;
      },
      accountRouting: function accountRouting() {
        return [STATUS.SUCCESS].includes(_this.paymentTypeInitStatus('ach')) && _this.achAccountRoutingEnabled;
      },
      paypal: function paypal() {
        var paypalCurrencyOverride = _.get(_this.processors, [PAYMENT_METHODS.PAYPAL, 'currency']);

        // if paypal is inactive, dont show
        if (_this.paymentTypeInitStatus('paypal') === STATUS.INACTIVE) return false;
        // if bt paypal is active and paypal commerce is inactive, always show bt paypal
        if (_this.paymentTypeInitStatus('paypal_commerce') === STATUS.INACTIVE) return true;

        // If paypal commerce is active and currency is USD, default to PayPal Commerce
        if (_this.currency === 'USD') return false;

        // If the currency is not USD and matches for BT PayPal show BT PayPal
        if (_this.currency === paypalCurrencyOverride) return true;

        // If its not inactive, show
        return _this.paymentTypeInitStatus('paypal') !== STATUS.INACTIVE;
      },
      paypalCommerce: function paypalCommerce() {
        return _this.currency === 'USD' && _this.paymentTypeInitStatus('paypal_commerce') !== STATUS.INACTIVE;
      },
      venmo: function venmo() {
        return _this.currency === 'USD' && _this.paymentTypeInitStatus('venmo') !== STATUS.INACTIVE;
      }
    };

    this.loggedIn = function () {
      return [_this.paymentTypeInitStatus('paypal'), _this.paymentTypeInitStatus('ach')].includes(STATUS.SUCCESS);
    };

    this.getRelevantCurrency = function () {
      return _this.meta.unsupportedTaxCompliance ? _.get(_this.meta, 'estimatedCharge.currency', false) : _this.payment.raw_currency_code;
    };

    this.on = function (event, cb) {
      var cbs = _.get(_this.eventCallbacks, event, []);
      cbs.push(cb);
      _.set(_this.eventCallbacks, event, cbs);
    };

    this.once = function (event, cb) {
      cb.once = true;
      _this.on(event, cb);
    };

    this.emit = function (event) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      $timeout(function () {
        var cbs = _.get(_this.eventCallbacks, event, []);
        _.forEach(cbs, function (cb) {
          return cb.apply(undefined, args);
        });
        _.remove(cbs, 'once');
      });
    };

    /* Tokenex
      ========================================================= */
    this.handleTokenexCard = function (brand, lastFour) {
      _this.payment.cc = _this.payment.cc || {};
      _this.payment.cc.card_type = brand;
      _this.payment.cc.card_last_four = lastFour;
    };

    this.handleTokenexToken = function (token) {
      this.activeSubmit.resolve(token);
    };

    this.getTokenexToken = function () {
      _this.activeSubmit = $q.defer();
      $rootScope.$broadcast('tokenizer', 'tokenize');
      return _this.activeSubmit.promise.catch(function (err) {
        // TODO @hvnt remove banner? this is being thrown per the iframe issue.
        $rootScope.SC.status.banner = {
          type: 'error',
          msg: 'Please check your payment information and try again.',
          timeout: false
        };
        return $q.reject(err);
      });
    };

    this.reloadTokenexIframe = function () {
      $rootScope.$broadcast('tokenizer', 'reset');
      if (_this.getPaymentMethod() === PAYMENT_METHODS.ACH && _this.achAccountRoutingEnabled) {
        _this.resetAccountRouting();
      } else {
        _this.syncPaymentMethodStatuses();
      }
    };

    /* Stripe
      ========================================================= */
    // stripe is handled internally by the cpStripeWidget. The
    // only external method needed is payment.stripe.getSource
    // which is called by this.setModelPayment during form
    // submission

    /* ACH
      ========================================================= */
    this.onAchReady = function () {
      $log.log('ACH Ready');
      _this.payment.paypal.status = STATUS.READY;
    };

    this.onAchSuccess = function (lastFour, accountType) {
      $timeout(function () {
        _this.paymentDetails = accountType.charAt(0).toUpperCase() + accountType.slice(1) + ' Account ending in ' + lastFour;
        $log.log('ACH Success');
        _this.showPaymentDetails();
        $rootScope.$broadcast('checkPaymentAuthStatus', true);
        $rootScope.$broadcast('donationPage:recalculateFees');
        _this.payment.cc.status = STATUS.PENDING;
        _this.payment.stripe.status = STATUS.PENDING;
      });
    };

    this.onAchCancel = function () {
      $log.log('ACH Cancel');
      $rootScope.$broadcast('checkPaymentAuthStatus', false);
      $rootScope.$broadcast('donationPage:recalculateFees');
      _this.syncPaymentMethodStatuses();
    };

    /* PayPal
      ========================================================= */
    this.onPaypalReady = function () {
      $log.log('PayPal Ready');
      _this.payment.paypal.status = _this.payment.paypal.status === STATUS.PENDING ? STATUS.READY : _this.payment.paypal.status;
    };

    // Todo: handle hiding billing address autocomplete on paypal success
    this.onPaypalSuccess = function (params) {
      $timeout(function () {
        $log.log('PayPal Success');
        _this.paymentDetails = params.details.email;
        _this.showPaymentDetails();
        _this.payment.cc.status = STATUS.PENDING;
        _this.payment.stripe.status = STATUS.PENDING;
        Object.assign(_this.payment.paypal, params.details);

        if (_this.parentModel) {
          _.forEach({
            billing_first_name: 'firstName',
            billing_last_name: 'lastName',
            billing_address1: 'billingAddress.streetAddress',
            billing_address2: 'billingAddress.extendedAddress',
            billing_postal_code: 'billingAddress.postalCode',
            billing_city: 'billingAddress.locality',
            billing_state: 'billingAddress.region',
            billing_country: 'countryCode',
            member_email_address: 'email'
          }, function (ppKey, modelKey) {
            if (!_this.parentModel[modelKey]) {
              _this.parentModel[modelKey] = _.get(params.details, ppKey);
            }
          });
        }
        $rootScope.$broadcast('checkPaymentAuthStatus', true);
        $rootScope.$broadcast('donationPage:recalculateFees');
      });
    };

    this.onPaypalCommerceSuccess = function (_ref2) {
      var details = _ref2.details,
          fundingSource = _ref2.fundingSource;

      $timeout(function () {
        $log.log('PayPal Commerce Success');
        _this.syncPaymentMethodStatuses();
        _this.paymentDetails = details.email;
        _this.showPaymentDetails();
        _this.payment.cc.status = STATUS.PENDING;
        _this.payment.stripe.status = STATUS.PENDING;

        var shippingAddress = _.get(details, 'shippingAddress', null);

        if (fundingSource && fundingSource === 'venmo') {
          Object.assign(_this.payment.venmo, details);
        } else {
          Object.assign(_this.payment.paypal_commerce, details);
        }

        if (_this.parentModel && shippingAddress) {
          if (metaService.getMetaParam('member_first_name') === '') {
            metaService.setMetaParam('member_first_name', details.firstName);
          }

          if (metaService.getMetaParam('member_last_name') === '') {
            metaService.setMetaParam('member_last_name', details.lastName);
          }

          _this.parentModel.member_name = _this.parentModel.member_name === '' ? details.firstName + ' ' + details.lastName : _this.parentModel.member_name;
          _this.parentModel.member_email_address = _this.parentModel.member_email_address === '' ? details.email : _this.parentModel.member_email_address;

          _this.parentModel.billing_first_name = details.firstName;
          _this.parentModel.billing_last_name = details.lastName;

          _this.parentModel.billing_address1 = shippingAddress.address_line_1 || shippingAddress.line1;

          _this.parentModel.billing_address2 = shippingAddress.address_line_2 || shippingAddress.line2;

          _this.parentModel.billing_city = shippingAddress.admin_area_2 || shippingAddress.city;
          _this.parentModel.billing_country = shippingAddress.country_code;
          _this.parentModel.billing_postal_code = shippingAddress.postal_code;
          _this.parentModel.billing_state = shippingAddress.admin_area_1 || shippingAddress.state;
        }

        $rootScope.$broadcast('donationPage:recalculateFees');
      });
    };

    this.onPaypalCancel = function () {
      $log.log('PayPal Cancel');
      _this.syncPaymentMethodStatuses();

      $rootScope.$broadcast('checkPaymentAuthStatus', false);
      $rootScope.$broadcast('donationPage:recalculateFees');
    };

    this.onPaypalCommerceCancel = function () {
      $log.log('PayPal Commerce Cancel');
      _this.syncPaymentMethodStatuses();
      _this.hidePaymentDetails();

      $rootScope.$broadcast('checkPaymentAuthStatus', false);
      $rootScope.$broadcast('donationPage:recalculateFees');
    };
  }]
});
})();