(function(){
'use strict';

/**
 * @name scTemplateLockingService
 * @kind factory
 *
 * @description
 * Gives you the lock status of fields in the template. Defaults to 'false' for campaigns not created from template.
 */
angular.module('classy').factory('scTemplateLockingService', scTemplateLockingService);

function scTemplateLockingService() {
  var get$oidFromCompNameAndId = function get$oidFromCompNameAndId(compName, id) {
    if (!compName || !id) {
      return null;
    }
    var componentSetInstances = _.get(SC.campaign.campaignTemplateData, 'component_set_instances', []);
    var compSetInst = componentSetInstances.find(function (inst) {
      return inst.campaign_id === SC.campaign.id;
    });
    if (compSetInst) {
      var componentInstances = _.get(compSetInst, 'component_instances.' + compName, []);
      var inst = componentInstances.find(function (_inst) {
        return _inst.id === id;
      });
      if (inst) {
        return inst.component_item_id.$oid;
      }
    }
    return null;
  };
  var getIndexById = function getIndexById(compName, id, _$oid) {
    var $oid = _$oid || get$oidFromCompNameAndId(compName, id);
    if ($oid) {
      var componentItems = _.get(SC.campaign.campaignTemplateData, 'components.' + compName + '.component_items', []);
      return componentItems.findIndex(function (item) {
        return item._id === $oid;
      });
    }
    return -1;
  };

  var findIndexByType = function findIndexByType(compName, type) {
    var componentItems = _.get(SC.campaign.campaignTemplateData, 'components.' + compName + '.component_items', []);
    return componentItems.findIndex(function (item) {
      return item.value.type === type;
    });
  };

  var getItemLock = function getItemLock(item, path) {
    var _path = '';
    if (!path) {
      return item.locked;
    }
    return path.split('.').reduce(function (prev, curr) {
      _path = _path ? _path + '.' + curr : curr;
      return prev || _.get(item, 'item_attributes.' + _path + '.locked');
    }, item.locked);
  };

  var getLock = function getLock(componentName, componentItemPath, findBy) {
    if (!componentName) {
      throw new Error('componentName is required');
    }
    if (SC.campaign && SC.campaign.campaignTemplateData) {
      var components = _.get(SC.campaign.campaignTemplateData, 'components', {});
      var component = components[componentName];
      if (component.locked) {
        return component.locked;
      }
      if (componentItemPath && !findBy) {
        throw new Error('fundBy parameter is required');
      }
      var type = findBy.type,
          index = findBy.index,
          id = findBy.id,
          $oid = findBy.$oid;

      var _idx = -1;
      if (type) {
        _idx = findIndexByType(componentName, type);
      } else {
        _idx = Number.isInteger(index) && index >= 0 ? index : getIndexById(componentName, id, $oid);
      }
      if (_idx > -1) {
        var item = _.get(component, 'component_items[' + _idx + ']');
        return getItemLock(item, componentItemPath);
      }
    }
    return false;
  };
  return {
    getLock: getLock
  };
}
})();