(function(){
'use strict';

/**
 * @kind factory
 * @name scOrganizationModel
 *
 * @description
 * A organization constructor with some common
 * prototype methods.
 */
scOrganizationModelFactory.$inject = ["$http"];
angular.module('classy').factory('scOrganizationModel', scOrganizationModelFactory);

function scOrganizationModelFactory($http) {
  function scOrganizationModel(data) {
    this.saved = _.cloneDeep(data);
    this.current = _.cloneDeep(data);
  }

  scOrganizationModel.prototype.update = function () {
    var _this = this;

    var diffObject = scOrganizationModel.differenceObject(this.current, this.saved);

    return $http.put('/frs-api/organizations/' + this.saved.id, diffObject).then(function (response) {
      _.merge(_this.saved, response.data);
      _.merge(_this.current, response.data);
    });
  };

  /**
   *  I grabbed this functionality from methods pre-existing in scObjectModel because including it
   *  as a dependency was causing a "circular dependency" problem. In the future, if feasible,
   *  it would be good for someone to conduct a refactoring of the existing architecture such
   *  that all <sc...Models>  could inherit from scObjectModel.
   */
  scOrganizationModel.differenceObject = function () {
    var current = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var saved = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    // delete properties that are not stored on database
    delete saved.queries_log;
    delete saved.mongo_queries_log;
    delete saved.updated_at;
    delete current.queries_log;
    delete current.mongo_queries_log;
    delete current.updated_at;

    var diff = Object.keys(saved).filter(function (key) {
      return saved[key] !== current[key];
    });

    // eslint-disable-next-line no-unused-vars
    _.forEach(current, function (value, key) {
      if (!(key in saved)) {
        diff.push(key);
      }
    });

    var data = {};

    diff.forEach(function (key) {
      data[key] = current[key];
    });

    return data;
  };

  scOrganizationModel.prototype.getDesignations = function () {
    var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
    var perPage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
    var filter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    return $http({
      method: 'GET',
      url: '/frs-api/organizations/' + this.current.id + '/designations',
      loadingScope: true,
      params: {
        per_page: perPage,
        page: page,
        filter: filter
      }
    });
  };

  scOrganizationModel.prototype.searchDesignations = function (search, page) {
    var perPage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 20;
    var campaignId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : undefined;

    return $http({
      method: 'GET',
      url: '/frs-api/organizations/' + this.current.id + '/designations',
      loadingScope: true,
      params: {
        per_page: perPage,
        page: page,
        filter: encodeURIComponent('name*=' + search + ',is_active=true'),
        campaign_id: campaignId
      }
    });
  };

  /*
   * Discard any unsaved changes. Revert to
   * most recently saved version.
   * @param  {string} property Pass to discard changes to
   *  specific organization properties.
   */
  scOrganizationModel.prototype.discardChanges = function (property) {
    if (_.isUndefined(property)) {
      this.current = _.cloneDeep(this.saved);
    } else {
      _.set(this, 'current.' + property, _.get(this, 'saved.' + property, null));
    }
  };

  /*
   * Legacy, pulling it out caused many issues.
   */
  scOrganizationModel.prototype.currency = function () {
    return {
      code: this.current.currency,
      symbol: '$'
    };
  };

  scOrganizationModel.prototype.multiCurrencyDisabled = function () {
    // Note: this.current can be undefined in tests which have not been properly converted
    // to unit-testing style
    return this.current && (this.current.tags || []).includes('disable-multi-currency');
  };

  scOrganizationModel.prototype.preventRecurringFrequencies = function () {
    return this.current && (this.current.tags || []).includes('prevent-recurring-frequencies');
  };

  scOrganizationModel.prototype.preventOldrfForDeque = function () {
    return this.current && (this.current.tags || []).includes('prevent-oldrf-for-deque');
  };

  scOrganizationModel.prototype.getCampaigns = function (params) {
    return $http.get('/frs-api/organizations/' + this.current.id + '/campaigns', {
      loadingScope: true,
      params: params
    });
  };

  scOrganizationModel.prototype.getPaymentMethod = function () {
    return $http.get('/frs-api/organizations/' + this.current.id + '/payment-methods');
  };

  return scOrganizationModel;
}
})();