(function(){
'use strict';

/**
 * @kind directive
 * @name scSubmit
 * @scope
 *
 * @description
 * Replacement for ngSubmit that adds some standard functionality including
 * error display and scAnalytics event tracking.
 */
scSubmit.$inject = ["$window", "$parse", "$q", "$timeout", "scBanner"];
angular.module('classy').directive('scSubmit', scSubmit);

function scSubmit($window, $parse, $q, $timeout, scBanner) {
  'use strict';

  return {
    require: '^form',
    scope: {
      scSubmit: '&',
      showFormErrors: '=?scSubmitErrorFunction',
      useStatusBanner: '=?',
      waitOnPending: '<?'
    },
    replace: false,
    link: function link(scope, element, attrs, formController) {
      scope.noScroll = !!attrs.noScroll;

      formController.scSubmit = handleSubmit;

      if (_.isUndefined(scope.useStatusBanner)) {
        scope.useStatusBanner = true;
      }

      var submitOptions = void 0;

      scope.showFormErrors = function (field) {
        if (!field) {
          return (formController.$pending || formController.$invalid) && formController.$submitted;
        }

        var ngModelController = _.get(formController, field);

        if (!ngModelController) {
          return false;
        }

        // Returns true if the field is invalid AND the user has either visited
        // the field or attempted to submit the form.
        return (ngModelController.$pending || ngModelController.$invalid) && (formController.$submitted || ngModelController.$dirty && ngModelController.$touched);
      };

      // eslint-disable-next-line no-unused-vars
      scope.$on('sc-form-submit', function (event, args) {
        submitOptions = _.merge({ fromScFormSubmit: true }, args.options);

        attrs.scSubmit = args.onSubmit || attrs.scSubmit;

        if (formController.$name === args.formName) {
          handleSubmit();
        }
      });

      element.on('submit', function () {
        // [CL-7690] This change pushes the success banner down 24px
        // in order to not overlay the Lock/Edit buttons in Brick
        var isCampaignTemplateView = window.location.search.substr(1).indexOf('campaign-templating-view=true') !== -1;
        var statusBannerAlert = document.getElementsByClassName('status-banner')[0];
        if (isCampaignTemplateView && statusBannerAlert) {
          statusBannerAlert.style.marginTop = '24px';
          statusBannerAlert.style.marginBottom = '24px';
        }
        scope.$apply(handleSubmit);
      });

      /* -------------------------------------------------------------------- *
       * Submit handler
       * -------------------------------------------------------------------- */

      function handleSubmit() {
        // If elements on the form are still saving & waitOnPending is true
        // wait for form to stop pending
        if (formController.$pending && scope.waitOnPending) {
          var pendingWatch = scope.$watch(function () {
            return formController.$pending;
          }, function (pending) {
            if (!pending) {
              pendingWatch();
              return completeValidation();
            }

            return undefined;
          });
          // Continue on if pending for too long
          $timeout(function () {
            pendingWatch();
            return completeValidation();
          }, 2000);

          return undefined;
        }

        return completeValidation();
      }

      /* -------------------------------------------------------------------- *
       * Collect errors
       * -------------------------------------------------------------------- */

      var completeValidation = function completeValidation() {
        var options = submitOptions;
        submitOptions = {}; // Clear submitOptions to prevent reusing old option data.

        var erroredFields = [];

        _.forEach(formController, function (field, name) {
          // Jump to first invalid element or pending element
          if (typeof field !== 'undefined' && (field.$invalid || field.$pending)) {
            erroredFields.push(name);
            if (!scope.noScroll) {
              var errorOffsetTop = element.find('.ng-pending, .sc-invalid:first, .ng-invalid:first').offset() ? element.find('.ng-pending, .sc-invalid:first, .ng-invalid:first').offset().top : false;
              $window.scrollTo(0, errorOffsetTop - 100);
            }

            if (scope.useStatusBanner) {
              if (field.$invalid && field.$invalid.required) {
                scBanner('Oops! Please complete the required fields.', 'error');
              } else {
                scBanner('Oops! Please fix the invalid fields.', 'error');
              }
            }
          }
        });

        // If fields are all valid, proceed to submit
        if (formController.$valid && !formController.$pending && erroredFields.length <= 0) {
          cleanForms(formController);
          return $parse(attrs.scSubmit)(scope.$parent, { submitOptions: options });
        }
        formController.$setSubmitted();
        formController.$valid = false;
        formController.$invalid = true;
        return $q.resolve();
      };

      /* -------------------------------------------------------------------- *
       * Clean up forms
       * -------------------------------------------------------------------- */

      function cleanForms(form) {
        if (!form.$$parentForm) {
          // This is the null formcontroller
          return;
        }

        form.$setPristine();
        form.$setUntouched();
        cleanForms(form.$$parentForm);
      }
    }
  };
}
})();