(function(){
'use strict';

angular.module('classy').component('donationAmountRecurringRf', {
  templateUrl: 'global/components/fields/donationAmountRf/global.components.donation-amount-recurring-rf', // same folder as this,
  bindings: {
    donationContext: '=',
    frequencyLevels: '=',
    settingPageFrequency: '<',
    frequency: '=',
    recurUntil: '=',
    levels: '=',
    recurringDonationLevels: '=',
    amount: '=',
    previousFrequencyPrice: '=',
    newAmount: '<',
    currency: '=',
    preview: '<'
  },
  require: {
    form: '^form'
  },
  controller: ["$state", "$scope", "scThemesService", "scCampaignsService", "scOrganizationsService", "scBlocksService", "scCurrencyService", "scAnalytics", "accessibilityService", function controller($state, $scope, scThemesService, scCampaignsService, scOrganizationsService, scBlocksService, scCurrencyService, scAnalytics, accessibilityService) {
    var _this = this;

    var self = this;
    this.preventOldrfForDeque = scOrganizationsService.active.preventOldrfForDeque();
    this.showRecurringEndDate = scCampaignsService.active.current.hide_recurring_end_date;
    this.recurringFrequencyArray = ['monthly', 'yearly', 'quarterly', 'semi-annually', 'daily', 'weekly', 'bi-weekly'];
    // Object with recurring preset & respective frequency text value
    this.recurringLevelsWithPreset = {
      0: 'one-time',
      1: 'monthly',
      2: 'quarterly',
      3: 'semi-annually',
      4: 'yearly',
      5: 'daily',
      6: 'weekly',
      7: 'bi-weekly'
    };

    this.$onInit = function () {
      /** NEW RF CHANGES: ---------------------------------------------*/
      _this.DonationBlock = scBlocksService.getBlockByType('donation');
      _this.defaultFrequency = _this.DonationBlock.current['recurring-default']; // if not one-time or monthly, set to one
      /** _________________________________________________________________________  */

      if (!scCampaignsService.active.multiCurrencyDisabled()) {
        _this.currency = scCurrencyService.localeCurrency;
      }

      _this.hasBigButtons = !!scOrganizationsService.getTag('early-access:big-buttons', true);

      _this.today = new Date();
      _this.today.setDate(_this.today.getDate() + 1);
      _this.visible = false;
      _this.show = {
        // on scCurrencyPicker focus show dark outline
        inputFocus: false
      };

      __initAmounts();
      __initStyles();

      _this.analyticsInitialized = true;
    };

    /* If we pass through the amount params, make sure the correct button is selected, and if the
     * preset amount is not in the preset donation levels, make sure the 'Custom' button is selected
     * TODO: this logic can be reduced and simplified by ~75%, make task
     */
    function __initAmounts() {
      self.showCustomAmtSelected = false;

      // If custom is selected as default
      if (self.amount === 'Custom') {
        self.amount = '';
        self.showCustomAmtSelected = true;
      }

      // If custom is selected as default
      if (self.previousFrequencyPrice === 'Custom') {
        self.previousFrequencyPrice = '';
      }

      self.showRecurringDonationArray = !self.preventOldrfForDeque && self.frequency !== 'one-time';

      var presetAmt = _.get($state, 'params.amount', false);

      // If query paramter is at the wrong place in the URL, then it is not populated in `params.amount`
      // For example, /give/{orgId}?amount=26#!/donation/checkout
      if (!presetAmt && self.amount !== 0) {
        presetAmt = self.amount;
      }
      var donationLevels = self.showRecurringDonationArray ? self.recurringDonationLevels : self.levels;

      var filteredLevels = _.filter(donationLevels, function (level) {
        return level.display_on_page === true;
      });

      if (presetAmt && !filteredLevels.some(function (i) {
        return i.amount == presetAmt;
      })) {
        if (filteredLevels.find(function (i) {
          return i.amount === 'Custom' && i.display_on_page === true;
        })) {
          self.showCustomAmtSelected = true;
          self.otherAmount = isNaN(presetAmt) ? 0 : presetAmt;
        } else if (!filteredLevels.find(function (i) {
          return i.amount === 'Custom' && i.display_on_page === true;
        }) && filteredLevels.length && filteredLevels.length !== 1) {
          self.amount = self.showRecurringDonationArray ? self.DonationBlock.current['recurring-donation-default'] || 0 : self.DonationBlock.current['donation-default'] || 0;
        } else {
          self.amount = isNaN(presetAmt) ? 0 : presetAmt;
        }
      }
    }

    function __initStyles() {
      self.theme = scThemesService.active.current;

      // parse the primary color for its RGB value -- we'll be customizing the opacity
      self.ctaStyle = {
        'background-color': getRGBValue(self.theme.styles.primaryColor)
      };

      self.hoverStyle = function (radioButtonValue, isHover) {
        if (isHover) {
          angular.element('.hover-state_' + radioButtonValue).css({
            color: '#fff',
            'background-color': self.theme.styles.primaryColor
          });
        } else {
          var checkSelected = angular.element('.hover-state_' + radioButtonValue).hasClass('selected');

          if (!checkSelected) {
            angular.element('.hover-state_' + radioButtonValue).css({
              color: 'rgba(0, 0, 0, 0.87)',
              'background-color': getRGBValue(self.theme.styles.primaryColor)
            });
          }
        }
      };

      self.hoverFrequencyStyle = function (radioButtonValue, isHover) {
        var checkSelected = angular.element('.hover-state_' + radioButtonValue).hasClass('selected');

        if (!checkSelected) {
          angular.element('.hover-state_' + radioButtonValue).css({
            color: self.theme.styles.primaryColor,
            'background-color': isHover ? getRGBValue(self.theme.styles.primaryColor) : '#fff'
          });
        }
      };
    }

    this.showFrequencyForDeque = function (frequency) {
      return !['daily', 'weekly', 'bi-weekly'].includes(frequency);
    };

    /** NEW RF CHANGES: Show frequency dropdown in preview mode (campaign setting page) */
    this.showDropdownOnPreview = function (data) {
      _this.frequency = data;
      _this.isFreqDropdown = data !== 'one-time' && _this.recurringLevels(_this.frequencyLevels).length > 1;
      _this.showRecurringDonationArray = !_this.preventOldrfForDeque && _this.frequency !== 'one-time';
    };

    /** On change of toggle Show frequency dropdown in preview mode (campaign setting page) */
    /** __________________________________________________________ */

    this.$onChanges = function (options) {
      if (options.newAmount) {
        _this.value = options.newAmount.currentValue;
      }

      if (options.settingPageFrequency) {
        _this.freqValue = options.settingPageFrequency.currentValue;
        if (_this.analyticsInitialized) {
          scAnalytics.eventBeacon({
            category: 'frs ' + campaignType,
            action: 'click',
            label: 'Donation Frequency \u2013 ' + options.settingPageFrequency.currentValue + ' (non-text link)'
          });
        }
      }

      _this.isDonationPage = $state.params.step === 'donation';

      // Since there is 2 preview mode: one is in campaign setting donation page and second one is in donation checkout page
      // following condition is made for campaign setting donation preview mode.
      if (_this.preview && _this.isDonationPage) {
        if (_this.value || _this.value === null) {
          _this.showCustomAmtSelected = false;
        } else {
          _this.showCustomAmtSelected = true;
        }

        // NEW RF CHANGES: On change of radio(set as default) in campaign setting page, DOM will render on preview
        if (_this.freqValue && !_this.preventOldrfForDeque) {
          _this.showDropdownOnPreview(_this.freqValue);
        }
      }
    };

    /**
     * NEW RF CHANGES:------------------------------------------------
     */

    this.recurringLevels = function (levels) {
      return levels.filter(function (level) {
        return level.type !== 'one-time' && level.display_on_page;
      });
    };

    this.onlyReccurringFrequency = function (levels) {
      return !levels.some(function (level) {
        return level.type === 'one-time' && level.display_on_page;
      });
    };

    this.recurringBtnText = function () {
      return _this.recurringLevels(_this.frequencyLevels).length > 1 ? 'recurring' : _this.recurringLevels(_this.frequencyLevels)[0].type;
    };

    this.recurringBtnSelected = function (frequency) {
      return _this.recurringFrequencyArray.includes(frequency);
    };

    this.showFreqDropdown = function (freqType) {
      var levels = _this.frequencyLevels;
      if (freqType === 'one-time') {
        _this.isFreqDropdown = false;
        _this.frequency = 'one-time';
        _this.recurUntil = null;
      } else if (_.get($state, 'params.recurring', false) && freqType != 'recurring' && _this.recurringLevels(levels).length > 1) {
        _this.isFreqDropdown = _this.recurringBtnSelected(_this.frequency);
      } else if (_this.recurringLevels(levels).length <= 1) {
        _this.isFreqDropdown = false;
        _this.frequency = freqType ? _this.recurringLevels(levels)[0].type : _this.frequency;
      } else {
        _this.isFreqDropdown = !!(freqType || _this.recurringBtnSelected(_this.frequency));
        var recurringPreset = _.get($state, 'params.recurring', false);

        if (_this.defaultFrequency === 'one-time' && freqType) {
          if (recurringPreset != 0) {
            var recurringPresetfrequencyText = _this.recurringLevelsWithPreset[recurringPreset];
            var frequencyHasDisplayOnPage = _this.frequencyLevels.find(function (level) {
              return level.type == recurringPresetfrequencyText && level.display_on_page === true;
            });
            _this.frequency = frequencyHasDisplayOnPage ? recurringPresetfrequencyText : _this.recurringLevels(levels)[0].type;
          } else {
            _this.frequency = _this.recurringLevels(levels)[0].type;
          }
        } else if (recurringPreset != 0) {
          var _recurringPresetfrequencyText = _this.recurringLevelsWithPreset[recurringPreset];
          var _frequencyHasDisplayOnPage = _this.frequencyLevels.find(function (level) {
            return level.type == _recurringPresetfrequencyText && level.display_on_page === true;
          });
          _this.frequency = _frequencyHasDisplayOnPage ? _recurringPresetfrequencyText : _this.defaultFrequency;
        } else {
          _this.frequency = _this.defaultFrequency;
        }
      }
    };
    /** ______________________________________________________ */

    this.isRecurringFrequency = function (event) {
      if (_this.preventOldrfForDeque) return;

      if (event.currentTarget && event.currentTarget.classList.contains('selected')) return;

      _this.showRecurringDonationArray = !_this.preventOldrfForDeque && _this.frequency !== 'one-time';

      var donationLevels = _this.showRecurringDonationArray ? _this.recurringDonationLevels : _this.levels;

      var filteredLevels = _.filter(donationLevels, function (level) {
        return level.display_on_page === true;
      });

      // toggle internal raw_final_price & previous_frequency_price
      var temp = _this.previousFrequencyPrice;
      _this.previousFrequencyPrice = _this.amount;
      _this.amount = temp;

      // while toggle check if amount not present in array
      // also check wether other input present or not
      // and assign value accordingly
      if (!filteredLevels.some(function (i) {
        return i.displayAmount == _this.amount;
      })) {
        if (filteredLevels.find(function (i) {
          return i.amount === 'Custom' && i.display_on_page === true;
        })) {
          if (_this.amount === 'preset' || _this.amount === 'clear') {
            _this.showCustomAmtSelected = false;
            _this.otherAmount = '';
          } else {
            _this.showCustomAmtSelected = true;
            _this.otherAmount = _this.showCustomAmtSelected ? _this.amount || '' : _this.otherAmount;
          }
        } else {
          _this.showCustomAmtSelected = false;
          _this.otherAmount = '';
        }
      } else {
        _this.showCustomAmtSelected = false;
        _this.otherAmount = '';
      }
    };

    this.noOfFrequencyLevel = function (frequencyLevels) {
      return frequencyLevels.filter(function (level) {
        return level.display_on_page;
      }).length;
    };

    this.noOfDonationAmount = function (donationAmounts) {
      return donationAmounts.filter(function (item) {
        return item.display_on_page;
      }).length;
    };

    this.showCustomAmtField = function (levels) {
      var displayed = levels.some(function (cLevel) {
        return cLevel.display_on_page && cLevel.amount === 'Custom';
      });

      if (!displayed) {
        _this.showCustomAmtSelected = false;
      }
      return displayed;
    };

    function getRGBValue(color) {
      // make sure we're matching an "rgb..." or "rgba..." string,
      // and assume that whitespaces can happen anywhere.
      // capture the three groups of color values and ignore the opacity value if an RGBA value
      // is fed into this function.
      var rgbaRegex = /(?!rgba?)\s*(\d{1,3}\s*,\s*)(\d{1,3}\s*,\s*)(\d{1,3}\s*\s*)/g;
      var ogColor = color.match(rgbaRegex);

      // use 0.12 opacity on the client's primary color and stringify
      var newColor = ogColor.concat(' 0.12)').join();
      return 'rgba('.concat(newColor);
    }

    this.getAriaLabel = function (frequency) {
      var ariaLabel = void 0;
      switch (frequency) {
        case 'one-time':
        case 'monthly':
        case 'yearly':
        case 'weekly':
        case 'daily':
          ariaLabel = 'Make a ' + frequency + ' gift';
          break;
        case 'quarterly':
          ariaLabel = 'Make a gift every three months';
          break;
        case 'semi-annually':
          ariaLabel = 'Make a gift every six months';
          break;
        case 'bi-weekly':
          ariaLabel = 'Make a gift every two weeks';
          break;
        default:
          break;
      }
      return ariaLabel;
    };

    // If other than custom amount is selected as default
    this.donationLevelStyle = function (displayAmt) {
      var styles = {
        color: !_this.showCustomAmtSelected && _this.amount === displayAmt ? '#fff' : 'rgba(0, 0, 0, 0.87)',
        'background-color': !_this.showCustomAmtSelected && _this.amount === displayAmt ? _this.theme.styles.primaryColor : getRGBValue(_this.theme.styles.primaryColor),
        border: 'none',
        'max-width': '50%'
      };
      return styles;
    };

    this.recurringBtnStyle = function (setting) {
      var styles = {
        'background-color': _this.recurringFrequencyArray.includes(setting) ? _this.theme.styles.primaryColor : '#fff',
        color: _this.recurringFrequencyArray.includes(setting) ? '#fff' : _this.theme.styles.primaryColor,
        border: '2px solid ' + _this.theme.styles.primaryColor
      };
      return styles;
    };

    this.oneTimeBtnStyle = function (setting) {
      var styles = {
        'background-color': _this.frequency == setting ? _this.theme.styles.primaryColor : '#fff',
        color: _this.frequency == setting ? '#fff' : _this.theme.styles.primaryColor,
        border: '2px solid ' + _this.theme.styles.primaryColor
      };
      return styles;
    };

    var campaignType = scCampaignsService.active.current.type;

    this.selectFrequency = function (frequency, keyboardEvent, element) {
      if (keyboardEvent && !accessibilityService.isValidKeyBoardEvent(keyboardEvent)) return;
      /** NEW RF CHANGES: On tab and enter key press select the toggle frequency */
      if (keyboardEvent && keyboardEvent.keyCode === 13) {
        if (element == 'selectDropdown') {
          keyboardEvent.preventDefault();
        } else if (frequency === 'one-time') {
          _this.frequency = frequency;
          _this.isFreqDropdown = false;
        } else {
          _this.frequency = _this.defaultFrequency === 'one-time' ? _this.recurringLevels(_this.frequencyLevels)[0].type : _this.defaultFrequency;
          _this.isFreqDropdown = frequency !== 'one-time' && _this.recurringLevels(_this.frequencyLevels).length > 1;
        }
      }
    };

    this.setPreventDefault = function (event) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    };

    this.selectAmount = function (amount, keyboardEvent) {
      var selectedDonationLevels = !_this.preventOldrfForDeque && _this.frequency !== 'one-time' ? _this.recurringDonationLevels : _this.levels;

      _this.isOtherInputPresent = selectedDonationLevels.some(function (level) {
        return level.amount === 'Custom' && level.display_on_page;
      });

      if (_this.isOtherInputPresent) {
        if (amount !== 'Custom') {
          _this.otherAmount = '';
          setTimeout(function () {
            _this.form.amount.$setValidity('required', true);
            _this.form.amount.$setValidity('min', true);
            _this.form.amount.$setValidity('max', true);
          }, 50);
        }
      }

      if (keyboardEvent && !accessibilityService.isValidKeyBoardEvent(keyboardEvent)) return;
      _this.showCustomAmtSelected = amount === 'Custom';

      if (!_this.showCustomAmtSelected) {
        _this.amount = amount;
      } else {
        _this.amount = _this.otherAmount || 0;
      }
    };

    $scope.$on('updateFrequencyInPreview', function (_evt, data) {
      _this.showDropdownOnPreview(data);
    });
  }]
});
})();