(function(){
'use strict';

angular.module('classy').component('programDesignationField', {
  template: '\n      <div class="program-designation-field form_field {{ $ctrl.fieldOptionSettings.containerClass || \'full\' }}">\n        <label class="form__label" _spec=\'label\'>\n          <i\n            class="form_label-icon-ok fa fa-check-circle"\n            data-ng-if="$ctrl.fieldOptionSettings.check.enabled"\n            data-ng-show="{{ $ctrl.fieldOptionSettings.check.condition }}"\n          ></i>\n          {{::$ctrl.fieldOptionSettings.label }}\n          <span class="form_label-required" data-ng-if="$ctrl.fieldOptionSettings.required"></span>\n        </label>\n  \n        <p class="form__sublabel--shifted"\n           _spec=\'sublabel\'>\n          {{::$ctrl.fieldOptionSettings.sublabel }}\n        </p>\n        \n        <typeahead-dropdown-control               \n          ng-if="$ctrl.selected"\n          default-items="$ctrl.defaultDesignations"\n          fetch-action="$ctrl.getDesignations"\n          selected-item="$ctrl.selected"\n          element-index="{{::$ctrl.elementIndex}}"\n          on-item-selected="$ctrl.onItemSelected(item, model, label)"\n          show-group-names="$ctrl.entity.display_group_name"\n        ></typeahead-dropdown-control>\n      </div>\n    ',
  bindings: {
    fieldOptionSettings: '=',
    model: '=',
    selectedId: '=?',
    entity: '=',
    onChange: '<',
    elementIndex: '@'
    // element index is for when there are multiple typeaheads in the DOM
    // and you want to specify which to open.
    // if elementIndex is undefined, it selects the first (at index 0)
  },
  controller: ["scCampaignsService", "designationsService", function programDesignationFieldCtrl(scCampaignsService, designationsService) {
    var _this = this;

    this.sort = null;

    this.selectDesignation = function (id, name) {
      _this.selected = {
        label: name,
        value: id
      };
      _this.selectedId = id;
      _this.model = id;
    };

    this.getDesignations = function (val) {
      var forceUpdate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      _this.searchTerm = val && val.trim();
      var filter = _this.searchTerm ? 'name*=' + val + ',is_active=true' : 'is_active=true';

      if (!forceUpdate && !_this.searchTerm && _this.defaultDesignations) {
        return _this.defaultDesignations;
      }

      var designationItemsSource = _this.isCampaignContext ? _this.getCampaignDesignationItems(filter) : _this.getOrganizationDesignationItems(filter);

      return designationItemsSource.then(function (items) {
        // cache the default set so we don't have to retrieve it every time
        if (!_this.searchTerm && (forceUpdate || !_this.defaultDesignations)) {
          _this.defaultDesignations = items;
        } else {
          _this.defaultDesignations = _.union(_this.defaultDesignations, items);
        }
        return items;
      });
    };

    this.onItemSelected = function (item) {
      _this.selectDesignation(item.id, item.name);

      // send the selected designation up to the parent component
      var designation = _this.defaultDesignations.find(function (desg) {
        return desg.id === item.id;
      });
      _this.onChange(designation);
    };

    // needs to return a promise so tests can await it and assert
    this.$onInit = function () {
      _this.searchTerm = '';
      _this.onChange = _this.onChange || function () {};

      _this.isCampaignContext = Object.prototype.hasOwnProperty.call(_this.entity, 'classy_mode_enabled');

      if (_this.isCampaignContext) {
        _this.displayGroupNames = _this.entity.display_group_name;
        _this.designationSortOrder = _.get(scCampaignsService, 'active.current.sort_designation_by');
        _this.sort = designationsService.parseSortOrder(_this.designationSortOrder);
      }

      if (_this.fieldOptionSettings) {
        _this.fieldOptionSettings.forceRefresh = _this.forceRefresh.bind(_this);
      }

      return _this.initializeDefaultDesignations();
    };

    this.initializeDefaultDesignations = function () {
      // invokes the initial designations call immediately and caches the default list
      var designationId = _this.model || _this.selectedId;

      if (_this.isCampaignContext) {
        return designationsService.getCampaignDesignations(_this.entity.id, { filter: 'id=' + designationId }).then(function (selected) {
          if (selected.data.data[0]) {
            _this.selectDesignation(selected.data.data[0].id, selected.data.data[0].name);
            return;
          }

          var currentDesignation = scCampaignsService.active.current.designation;
          if (_this.isCampaignContext && currentDesignation.id) {
            _this.selectDesignation(currentDesignation.id, currentDesignation.name);
            return;
          }

          // we default to the first designation in the list if a default is somehow not set in a campaign
          // eslint-disable-next-line consistent-return
          return _this.getDesignations().then(function (designations) {
            // get the first from the list that is not a group item for the typeahead
            var firstDesignation = designations.find(function (d) {
              return !d.isGroupItem;
            });
            _this.selectDesignation(firstDesignation.id, firstDesignation.name);
          });
        });
      }

      return designationsService.getOrganizationDesignations(_this.entity.id, 1, 1, designationId ? 'id=' + designationId : 'is_default=true').then(function (resp) {
        _this.selectDesignation(resp.data.data[0].id, resp.data.data[0].name);
      });
    };

    this.buildDesignationList = function (groups) {
      var typeaheadItems = [];

      // only show group labels if the appropriate campaign setting is enabled
      groups.forEach(function (group) {
        typeaheadItems.push({
          isGroupItem: true,
          label: group.name
        });
        typeaheadItems = typeaheadItems.concat(group.designations);
      });

      return typeaheadItems;
    };

    this.forceRefresh = function () {
      _this.designationSortOrder = _this.entity.sort_designation_by;
      _this.sort = designationsService.parseSortOrder(_this.designationSortOrder);
      _this.getDesignations('', true);
    };

    this.getCampaignDesignationItems = function (filter) {
      return designationsService.getGroupedCampaignDesignations(_this.entity.id, {
        filter: filter,
        per_page: 100,
        page: 1,
        sort: _this.sort
      }).then(function (groups) {
        _this.groups = groups;
        return _this.buildDesignationList(groups);
      });
    };

    this.getOrganizationDesignationItems = function (filter) {
      return designationsService.getOrganizationDesignations(_this.entity.id, 100, 1, filter).then(function (resp) {
        return resp.data.data;
      });
    };
  }]
});
})();