(function(){
'use strict';

angular.module('classy').component('teamNameField', {
  require: {
    form: '^form'
  },
  template: '\n      <form-label\n      required="$ctrl.required"\n      _spec="label">\n        {{:: $ctrl.label }}\n      </form-label>\n\n      <div class="form__input-wrapper">\n        <input class="form__text-input--dark"\n        ng-model="$ctrl.model"\n        ng-model-options="{ getterSetter: $ctrl.getterSetter }"\n        data-name-required="false"\n        sc-validate-team-name="$ctrl.campaignId"\n        sc-validity-reporter\n        sc-validity-classes\n        ng-disabled="$ctrl.disableFlag"\n        type="text"\n        name="{{:: $ctrl.name }}"\n        placeholder="{{:: $ctrl.placeholder }}"\n        ng-required="$ctrl.required"\n        maxlength="{{::$ctrl.maxlength}}"\n        _spec="input"\n        aria-label="Name your team"/>\n\n        <i class="fa fa-check-circle form__async-validation-icon--valid"\n        data-ng-class="{ visible: $ctrl.teamNameValid() }"\n        _spec="valid"></i>\n\n        <i class="fa fa-times-circle form__async-validation-icon--invalid"\n        data-ng-class="{ visible: $ctrl.teamNameInvalid() }"\n        _spec="invalid"></i>\n\n        <span class="form__text-input__counter" ng-if="$ctrl.maxlength && $ctrl.counter">\n          <span data-ng-bind="$ctrl.model.length || 0"></span>/{{::$ctrl.maxlength}}\n        </span>\n      </div>\n\n      <form-error\n      name="$ctrl.name"\n      messages="$ctrl.errorMessages"\n      _spec="errors"></form-error>\n    ',
  bindings: {
    campaignId: '<',
    model: '=',
    errorMessages: '<?',
    getterSetter: '<?',
    label: '<?',
    name: '<?',
    required: '<?',
    disableFlag: '=?'
  },
  controller: function TeamNameFieldCtrl() {
    var DEFAULTS = {
      errorMessages: {
        required: 'Please enter a team name',
        teamNameInvalidChars: 'Commas are not allowed in team names.',
        teamNameAvailable: 'Sorry, that team name is already taken.'
      },
      getterSetter: false,
      label: 'Team name',
      name: 'teamName',
      placeholder: 'Name your team',
      required: true,
      counter: true,
      maxlength: 127
    };

    this.$onInit = function () {
      _.defaultsDeep(this, DEFAULTS);
    };

    this.teamNameValid = function () {
      return _.get(this.form, [this.name, '$modelValue']) && !_.get(this.form, [this.name, '$pending', 'teamNameAvailable']) && !_.get(this.form, [this.name, '$error', 'teamNameAvailable']) && !_.get(this.form, [this.name, '$pristine']);
    };

    this.teamNameInvalid = function () {
      return _.get(this.form, [this.name, '$error', 'teamNameAvailable']);
    };
  }
});
})();