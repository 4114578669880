(function(){
'use strict';

frsDonationFooterCtrl.$inject = ["$scope", "$window", "scCampaignsService", "cpConstants"];
angular.module('classy').controller('frsDonationFooterCtrl', frsDonationFooterCtrl);

function frsDonationFooterCtrl($scope, $window, scCampaignsService, cpConstants) {
  var PAYMENT_METHODS = cpConstants.PAYMENT_METHODS;
  /* ---------------------------------------------------------------------- *
   * Display flags
   * ---------------------------------------------------------------------- */

  $scope.show = {
    creditee: function creditee() {
      return $scope.META.creditee;
    },
    achTerms: function achTerms() {
      return $scope.API.getPaymentMethod() === PAYMENT_METHODS.ACH;
    },
    feeOnTop: function feeOnTop() {
      return $scope.API.showFeeOnTop();
    }
  };

  $scope.META.showTaxComplianceLanguage = $scope.META.taxComplianceLanguage && $scope.META.taxComplianceLanguage !== '' && !scCampaignsService.active.multiCurrencyDisabled();

  var achPaymentMethod = $window.SC.processors ? $window.SC.processors.ACH : false;
  $scope.META.stripeACHEnabled = achPaymentMethod && achPaymentMethod.name === 'STRIPE';

  /* ---------------------------------------------------------------------- *
   * Constructed bindings
   * ---------------------------------------------------------------------- */

  $scope.build = {
    logoSrc: function logoSrc() {
      if ($scope.META.creditee.logo) {
        return $scope.META.creditee.logo.sizes.full;
      } else if (_.isUndefined($scope.META.creditee.team_lead_id)) {
        return '/static/global/images/individual_default_image.png';
      }
      return '/static/global/images/team_default_image.png';
    },
    achTerms: function achTerms() {
      return $scope.META.stripeACHEnabled ? 'I agree to these <a href="https://www.classy.org/terms" target="_blank">Terms and Conditions</a>\n           and I authorize Classy to electronically debit my account.' : 'I agree to these <a href="https://www.classy.org/terms" target="_blank">Terms and Conditions</a>, as\n           well as the <a href="https://www.dwolla.com/legal/tos/" target="_blank">Terms and Conditions</a> of\n           Dwolla, our eCheck payment processor.';
    }
  };
}
})();