(function(){
'use strict';

angular.module('classy').service('EmbeddedGiving', ["$stateParams", "EmbeddedGivingPaths", "EmbeddedGivingRecurringNudge", function ($stateParams, EmbeddedGivingPaths, EmbeddedGivingRecurringNudge) {
  var _this = this;

  var self = this;
  this.isEnabled = false;
  this.root = EmbeddedGivingPaths.root;

  /*  The view 'donation' is the ONLY view with CHILDREN views
   *    - The child views are steps (forms) required to /checkout.
   *    - The # of child views is dependant on the chosen pay processor  */
  this.views = {
    donation: {
      key: 'donation',
      param: 'do',
      path: EmbeddedGivingPaths.views.donation
    },
    'thank-you': {
      key: 'thank-you',
      param: 'ty',
      path: EmbeddedGivingPaths.views.thankYou
    },
    faq: {
      key: 'faq',
      param: 'faq',
      path: EmbeddedGivingPaths.views.faq
    }
  };
  this.defaultView = this.views.donation; // default to donation

  /*  Configuration Options:
   *  Explicity defining all possible options here for readability
   *  • 'own' are options exclusive to embedded giving
   *    - These options are declared under ./frs/donation/routes.js, but are NOT supported by the DEFAULT checkout page
   *  • 'defaultCheckout' options are url param options available on the DEFAULT donation checkout page
   *    - These options are declared under ./frs/donation/routes.js  */
  this.opts = {
    eg: {
      fullAddress: false,
      recurringNudge: false
    },
    default: {
      // Donation
      preview: undefined,
      amount: undefined,
      recurring: undefined,
      currency: undefined,
      preset1: undefined,
      preset2: undefined,
      preset3: undefined,
      preset4: undefined,
      designation: undefined,
      //  Contact,
      first: undefined,
      last: undefined,
      email: undefined,
      phone: undefined,
      street: undefined,
      city: undefined,
      state: undefined,
      zip: undefined,
      //  Meta,
      return_url: undefined,
      scroll: undefined
    }
  };

  /*  $stateParams props are STRING by DEFAULT
   *  CAST ea. prop to it's appropriate type (if need)
   *  TODO: sync with DAL cache? */
  function __initOptions() {
    self.opts.eg = {
      fullAddress: !!$stateParams.egfa,
      recurringNudge: EmbeddedGivingRecurringNudge.isEnabled()
    };

    self.opts.default = {
      amount: $stateParams.amount,
      recurring: !!$stateParams.recurring, // if TRUE, defaults donation to recurring instead of one-time
      currency: $stateParams.currency,
      preset1: $stateParams.preset1,
      preset2: $stateParams.preset2,
      preset3: $stateParams.preset3,
      preset4: $stateParams.preset4,
      designation: $stateParams.designation,
      first: $stateParams.first,
      last: $stateParams.last,
      email: $stateParams.email,
      phone: $stateParams.phone,
      street: $stateParams.street,
      city: $stateParams.city,
      state: $stateParams.state,
      zip: $stateParams.zip,
      return_url: $stateParams.return_url,
      scroll: $stateParams.scroll
    };
  }

  /*  Currently, init() is only called in ./frs/donation  */
  this.init = function (config) {
    if (!_.isEmpty(config)) {
      _this.isEnabled = !!config.isEnabled;
      __initOptions(); // TODO: only when isEnabled?

      var view = _this.getViewByParam(config.param);
      if (view) {
        _this.setDefaultView(view);
      }
    }
  };

  this.getViewByParam = function (param) {
    var view = null;
    angular.forEach(_this.views, function (v) {
      if (v.param === param) {
        view = v;
      }
    });
    return view;
  };

  /*  Only used to jump to faq page before EmbeddedGivingCtrl is initialized
   *  TODO: expand for multi purpose (requires new abstractions)  */
  this.setDefaultView = function (view) {
    if (_this.hasView(view)) {
      _this.defaultView = view;
    }
  };

  this.hasView = function (view) {
    return !_.isEmpty(view) && _this.views[view.key];
  };

  this.hasEmbeddedGiving = function (campaign) {
    return campaign && campaign.hasEmbeddedGiving();
  };
}]);
})();