(function(){
'use strict';

/* Dedicate Donation */
angular.module('classy').component('egDedicateDonation', {
  templateUrl: 'global/embedded-giving/components/dedicate-donation/eg.components.dedicate-donation', // same folder as this
  bindings: {
    parentModel: '<'
  },
  controller: ["scCampaignsService", "EMAIL_VALIDATION_REGEX", function controller(scCampaignsService, EMAIL_VALIDATION_REGEX) {
    var _this = this;

    this.isOpen = false;
    this.isEnabled = !__getCampaignAttr('hide_dedications');
    this.isEmailEnabled = __getCampaignAttr('send_dedication_emails');

    // component's local dedication state
    this.dedication = {};

    this.$onInit = function () {
      _this.dedication = __getInitialDedication();
    };

    this.onClose = function () {
      _this.isOpen = false;
    };

    this.onOpen = function () {
      _this.isOpen = true;

      if (__isParentModelDedicationSet(_this.parentModel)) {
        /**
         * PS. Creating copy coz we don't want changes to the local dedication
         * to affect parentModel.dedication until the changes are explicitly saved
         * by clicking the save button.
         */
        _this.dedication = Object.assign({}, _this.parentModel.dedication);
      }
    };

    this.onSave = function () {
      if (_this.parentModel) {
        /**
         * Creating copy coz dedication and parentModel.dedication
         * must be independent objects.
         */
        _this.parentModel.dedication = Object.assign({}, _this.dedication);
        _this.onClose();
      }
    };

    this.onRemove = function () {
      if (_this.parentModel) {
        _this.parentModel.dedication = null;
        _this.dedication = __getInitialDedication();
        _this.onClose();
      }
    };

    this.isRemoveVisible = function () {
      return __isParentModelDedicationSet(_this.parentModel);
    };

    this.isSaveDisabled = function () {
      var disabled = false;

      if (!_this.dedication.honoree_first_name) {
        disabled = true;
      }

      if (!_this.dedication.honoree_last_name) {
        disabled = true;
      }

      if (_this.dedication.email_address) {
        var isEmailValid = EMAIL_VALIDATION_REGEX.test(_this.dedication.email_address);
        disabled = !isEmailValid || !_this.dedication.first_name || !_this.dedication.last_name;
      }

      return disabled;
    };

    /**
     * If the donor enters the recipient's email address, all
     * recipient fields become required.
     */
    this.isRecipientRequired = function () {
      return !!_this.dedication.email_address;
    };

    this.getToggleValue = function () {
      if (__isParentModelDedicationSet(_this.parentModel)) {
        return 'In honor of ' + _this.parentModel.dedication.honoree_first_name + ' ' + _this.parentModel.dedication.honoree_last_name;
      }

      return 'Option to add a dedication';
    };

    this.getToggleButtonLabel = function () {
      return __isParentModelDedicationSet(_this.parentModel) ? 'Edit' : 'Add';
    };

    function __getInitialDedication() {
      var isECardEnabled = __getCampaignAttr('allow_ecards');

      var eCards = __getCampaignAttr('ecards');
      var firstECard = eCards[0];

      // Use the first ecard if option is enabled
      var firstECardId = isECardEnabled && firstECard ? firstECard.id : null;

      var initialDedication = {
        /**
         * in eg, for the sake of space we don't offer "in honor|memory of" toggle
         * and default to honor only
         */
        type: 'honor',

        // honoreer fields
        honoree_first_name: null,
        honoree_last_name: null,

        // email fields
        email_address: null,
        first_name: null,
        last_name: null,

        // ecard fields
        ecard_message: null,
        ecard_id: firstECardId
      };

      return initialDedication;
    }

    function __getCampaignAttr(attr) {
      return scCampaignsService.active && scCampaignsService.active.current && scCampaignsService.active.current[attr];
    }

    function __isParentModelDedicationSet(parentModel) {
      return !!(parentModel && parentModel.dedication && parentModel.dedication.honoree_first_name && parentModel.dedication.honoree_last_name);
    }
  }]
});
})();