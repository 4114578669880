(function(){
'use strict';

// TODO: move
angular.module('classy').controller('EmbeddedGivingPayCtrl', ["$scope", "EmbeddedGivingPay", "EmbeddedGivingPayUtil", function ($scope, EmbeddedGivingPay, EmbeddedGivingPayUtil) {
  $scope.ActivePaymentMethod = null;
  $scope.ActivePaymentMethods = {};
  $scope.VisiblePaymentMethods = {};

  $scope.egPay = {
    isInitializing: true
  };

  $scope.setActivePaymentMethod = function (pm) {
    $scope.ActivePaymentMethod = pm;
  };

  $scope.setPaymentMethods = function (pms) {
    $scope.ActivePaymentMethods = EmbeddedGivingPayUtil.getValidPaymentMethods(pms);
    $scope.VisiblePaymentMethods = EmbeddedGivingPayUtil.getPaymentMethodsByStatus($scope.ActivePaymentMethods, 'ready');
  };

  // TODO refactor this, rn its a quick solution for aug 18
  $scope.syncPaymentMethods = function (statuses) {
    EmbeddedGivingPay.syncPaymentMethodStatuses(statuses);
  };

  $scope.$watchCollection('MODEL.payment', function (newVal) {
    // TODO filter out valid payment methods
    // TODO ignore if no updates
    // TODO update to paymentmethods
    $scope.syncPaymentMethods(newVal);
  });

  EmbeddedGivingPay.init().then(function () {
    $scope.egPay.isInitializing = false;
  });
}]);
})();