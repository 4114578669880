(function(){
'use strict';

frsDonationAmountCtrl.$inject = ["$timeout", "$scope", "$rootScope", "$state", "scCurrencyService", "scCampaignsService", "scTaxEntitiesService", "COUNTRY_LIST", "globalExperimentSwitch", "scOrganizationsService", "scAnalytics", "scBlocksService"];
angular.module('classy').controller('frsDonationAmountCtrl', frsDonationAmountCtrl);

function frsDonationAmountCtrl($timeout, $scope, $rootScope, $state, scCurrencyService, scCampaignsService, scTaxEntitiesService, COUNTRY_LIST, globalExperimentSwitch, scOrganizationsService, scAnalytics, scBlocksService) {
  var initialCurrencyConvertCheck = true;
  $scope.DonationBlock = scBlocksService.getBlockByType('donation');
  $scope.preventRecurringFrequency = scOrganizationsService.active.preventRecurringFrequencies();
  $scope.preventOldrfForDeque = scOrganizationsService.active.preventOldrfForDeque();
  /* ---------------------------------------------------------------------- *
   * Constants
   * ---------------------------------------------------------------------- */
  $scope.CONSTANTS = {
    DONATION_AMOUNT_REQUIRED_ERROR_TEXT: 'Please enter a donation amount.',
    DOLLAR_AMOUNT_VALID_ERROR_TEXT: 'Please enter a valid dollar amount.'
  };

  /* ---------------------------------------------------------------------- *
   * Setup
   * ---------------------------------------------------------------------- */
  $scope.setup = {
    frequency: function frequency() {
      var current = $scope.MODEL.frequency;
      if ($scope.preventRecurringFrequency) {
        switch ($scope.CONTEXT.autofill.recurring) {
          case '1':
          case 'true':
          case 'yes':
            if ($scope.state.mode !== 'one-time') {
              return 'recurring';
            }
            break;
          case '0':
          case 'false':
          case 'no':
            if ($scope.state.mode !== 'recurring' && $scope.state.mode !== 'membership') {
              return 'one-time';
            }
            break;
          default:
            return current;
        }
      } else {
        var recurringLevels = $scope.block['recurring-level'];
        switch ($scope.CONTEXT.autofill.recurring) {
          case '0':
          case 'false':
          case 'no':
            if (_.find(recurringLevels, { type: 'one-time', display_on_page: true })) {
              return 'one-time';
            }
            break;
          case '1':
          case 'true':
          case 'yes':
            if (_.find(recurringLevels, { type: 'monthly', display_on_page: true })) {
              return 'monthly';
            }
            break;
          case '2':
            if (_.find(recurringLevels, { type: 'quarterly', display_on_page: true })) {
              return 'quarterly';
            }
            break;
          case '3':
            if (_.find(recurringLevels, { type: 'semi-annually', display_on_page: true })) {
              return 'semi-annually';
            }
            break;
          case '4':
            if (_.find(recurringLevels, { type: 'yearly', display_on_page: true })) {
              return 'yearly';
            }
            break;
          case '5':
            if (_.find(recurringLevels, { type: 'daily', display_on_page: true })) {
              return 'daily';
            }
            break;
          case '6':
            if (_.find(recurringLevels, { type: 'weekly', display_on_page: true })) {
              return 'weekly';
            }
            break;
          case '7':
            if (_.find(recurringLevels, { type: 'bi-weekly', display_on_page: true })) {
              return 'bi-weekly';
            }
            break;
          default:
            return current;
        }
      }

      return undefined;
    },
    amount: function amount() {
      if (!_.isUndefined($scope.CONTEXT.autofill.amount)) {
        return +$scope.CONTEXT.autofill.amount;
      }

      // Consider frequency change in case of recurring parameter
      var frequency = $scope.setup.frequency() || $scope.MODEL.frequency;

      // If frequency is recurring, then set raw_final_price to recurring-donation-default i.e recurring frequency selected amount
      if (!$scope.preventOldrfForDeque && !$scope.preventRecurringFrequency && frequency != 'one-time') {
        return $scope.block['recurring-donation-default'] || 0;
      }

      // otherwise set raw_final_price to donation-default amount i.e one-time frequency selected amount
      return $scope.block['donation-default'] || 0;
    },

    // On initial load, set previous-frequency-price value.
    // So that while doing toggle from one-time to recurring or vice-versa, we have to show the selected amount highlighted
    // on donation page & preview page
    setPreviousFrequencyPrice: function setPreviousFrequencyPrice() {
      var frequency = $scope.setup.frequency() || $scope.MODEL.frequency;

      return frequency == 'one-time' ? $scope.block['recurring-donation-default'] || 0 : $scope.block['donation-default'] || 0;
    },
    mode: function mode() {
      var level = $scope.block['recurring-level'];

      switch (level) {
        // Recurring enabled; one-time pre-selected; choose amount
        case '1':
          $scope.state.mode = 'default';
          $scope.MODEL.frequency = 'one-time';
          break;

        // Recurring enabled; recurring pre-selected; choose amount
        case '2':
          $scope.state.mode = 'default';
          $scope.MODEL.frequency = 'recurring';
          break;

        // Recurring FORCED; fixed amount (membership)
        case '3':
          $scope.state.mode = 'membership';
          $scope.MODEL.frequency = 'recurring';
          $scope.state.fixedAmount = true;
          break;

        // Recurring FORCED; choose amount
        case '4':
          $scope.state.mode = 'recurring';
          $scope.MODEL.frequency = 'recurring';
          break;

        // Recurring DISABLED; choose amount
        case '5':
          $scope.state.mode = 'one-time';
          $scope.MODEL.frequency = 'one-time';
          break;

        // Recurring DISABLED; fixed amount (one-time)
        case '6':
          $scope.state.mode = 'one-time';
          $scope.MODEL.frequency = 'one-time';
          $scope.state.fixedAmount = true;
          break;
        default:
        // do nothing
      }
      if (Array.isArray(level)) {
        $scope.MODEL.frequency = $scope.block['recurring-default'];

        // if current frequency & previous frequency are from recurring array frequency then
        // stop swapping between raw_final_price & previous_frequency_price
        if (!$scope.preventOldrfForDeque && !$scope.preventRecurringFrequency && $scope.MODEL.items && !($scope.state.recurringArray.includes($scope.MODEL.frequency) && $scope.state.recurringArray.includes($scope.state.frequencyMode))) {
          $scope.MODEL.items[0].previous_frequency_price = $scope.MODEL.items[0].raw_final_price;
          $scope.MODEL.items[0].raw_final_price = $scope.MODEL.frequency == 'one-time' ? $scope.block['donation-default'] : $scope.block['recurring-donation-default'];
        }
        // set previous selected frequency value i.e one-time, daily etc
        $scope.state.frequencyMode = $scope.block['recurring-default'];
      }
    },
    levels: function levels() {
      var filteredLevels = $scope.state.levels;
      if (!$scope.preventRecurringFrequency) {
        filteredLevels = _.filter($scope.state.levels, function (level) {
          return level.display_on_page === true && level.amount !== 'Custom';
        });
      }
      _.forEach(filteredLevels, function (level, index) {
        if ($scope.CONTEXT.autofill['preset' + (index + 1)]) {
          if (filteredLevels.length === 1) return;else if (level.amount == $scope.block['donation-default'] && $scope.CONTEXT.autofill['preset' + (index + 1)] != level.amount && !$scope.CONTEXT.autofill.amount) {
            if (!$scope.preventOldrfForDeque && !$scope.preventRecurringFrequency) {
              // capture index of donation-default
              $scope.state.presetIndex = index;

              // If preset is changing recurring-donation-default & amount pass through parameter is absent then,
              // set value of raw_final_price & previous_frequency_price according to frequency.
              $scope.MODEL.items = [{
                raw_final_price: $scope.MODEL.frequency == 'one-time' ? 'preset' : $scope.block['recurring-donation-default'],
                previous_frequency_price: $scope.MODEL.frequency == 'one-time' ? $scope.block['recurring-donation-default'] : 'preset'
              }];
            } else {
              $scope.MODEL.items = [{
                raw_final_price: 0
              }];
            }
          }
          var preset = $scope.CONTEXT.autofill['preset' + (index + 1)];
          level.amount = Math.round(parseFloat(preset) * 100) / 100;
        }
        level.displayAmount = level.amount;
      });
    },
    recurringDonationLevels: function recurringDonationLevels() {
      var filteredLevels = $scope.state.recurringDonationLevels;
      if (!$scope.preventRecurringFrequency) {
        filteredLevels = _.filter($scope.state.recurringDonationLevels, function (level) {
          return level.display_on_page === true && level.amount !== 'Custom';
        });
      }
      _.forEach(filteredLevels, function (level, index) {
        if ($scope.CONTEXT.autofill['preset' + (index + 1)]) {
          if (filteredLevels.length === 1) return;else if (level.amount == $scope.block['recurring-donation-default'] && $scope.CONTEXT.autofill['preset' + (index + 1)] != level.amount && !$scope.CONTEXT.autofill.amount) {
            // If preset is changing recurring-donation-default & amount pass through parameter is absent then,
            // set value of raw_final_price & previous_frequency_price according to frequency.
            $scope.MODEL.items = [{
              raw_final_price: $scope.MODEL.frequency == 'one-time' ? $scope.block['donation-default'] : 'preset',
              previous_frequency_price: $scope.MODEL.frequency == 'one-time' ? 'preset' : $scope.block['donation-default']
            }];
            // if index of donation-default & index of recurring-donation-default is same,
            // then set both raw_final_price & previous_frequency_price to 0
            if ($scope.state.presetIndex == index) {
              $scope.MODEL.items = [{
                raw_final_price: 'preset',
                previous_frequency_price: 'preset'
              }];
            }
          }
          var preset = $scope.CONTEXT.autofill['preset' + (index + 1)];
          level.amount = Math.round(parseFloat(preset) * 100) / 100;
        }
        level.displayAmount = level.amount;
      });
    },
    intlEstimates: function intlEstimates() {
      if (scCampaignsService.active.multiCurrencyDisabled()) {
        return;
      }

      scTaxEntitiesService.getTaxEntity().then(function (fetchedEntity) {
        var entity = fetchedEntity;
        if (!fetchedEntity) {
          entity = {
            currency_code: 'USD',
            country: 'US'
          };
          // FRS-7231: estimatedCharge needed to show paypal button when Passport enabled
          // but there are no tax entities (only a US processor)
          // CURRENTLY: using this to spoof a US tax entity because of the above condition
          // for paypal even though paypal should be enabled for orgs that are USD and
          // have no tax entities
          // FRS-7374
          // TODO: open the above up for CAD and possibly more currencies dynamically
        }

        if (entity.country == 'USA') {
          entity.country = 'US';
        }

        if (entity.country == 'CAN') {
          entity.country = 'CA';
        }

        $scope.META.estimatedCharge = {
          countryCode: entity.country,
          currency: entity.currency_code,
          country: _.find(COUNTRY_LIST, function (c) {
            return c.value == entity.country;
          }).label
        };
      });
    },
    previewDefaults: function previewDefaults() {
      $scope.MODEL.items = [{
        raw_final_price: $scope.CONTEXT.autofill.amount
      }];

      if (!$scope.preventOldrfForDeque && !$scope.preventRecurringFrequency) {
        $scope.$watch("block['donation-default']", function (newVal, oldVal) {
          if (newVal !== oldVal && $scope.MODEL.frequency == 'one-time') {
            $scope.MODEL.items[0].raw_final_price = newVal;
          }
          if ($scope.MODEL.frequency == 'one-time') {
            $scope.MODEL.items[0].raw_final_price = newVal;
          } else {
            $scope.MODEL.items[0].previous_frequency_price = newVal;
          }
          if (newVal == 'Custom') {
            if ($scope.MODEL.frequency == 'one-time') {
              $scope.MODEL.items[0].raw_final_price = '';
            } else {
              $scope.MODEL.items[0].previous_frequency_price = '';
            }
          }
        });

        $scope.$watch("block['recurring-donation-default']", function (newVal, oldVal) {
          if (newVal !== oldVal && $scope.MODEL.frequency !== 'one-time') {
            $scope.MODEL.items[0].raw_final_price = newVal;
          }
          if ($scope.MODEL.frequency !== 'one-time') {
            $scope.MODEL.items[0].raw_final_price = newVal;
          } else {
            $scope.MODEL.items[0].previous_frequency_price = newVal;
          }
          if (newVal == 'Custom') {
            if ($scope.MODEL.frequency !== 'one-time') {
              $scope.MODEL.items[0].raw_final_price = '';
            } else {
              $scope.MODEL.items[0].previous_frequency_price = '';
            }
          }
        });
      } else {
        $scope.$watch("block['donation-default']", function (newVal, oldVal) {
          if (newVal !== oldVal) {
            $scope.MODEL.items[0].raw_final_price = newVal;
          }
          $scope.MODEL.items[0].raw_final_price = newVal;

          if (newVal == 'Custom') {
            $scope.MODEL.items[0].raw_final_price = '';
          }
        });
      }
    },
    currencyDefaults: function currencyDefaults() {
      $scope.MODEL.payment = $scope.MODEL.payment || {};
      $scope.MODEL.payment.raw_currency_code = $scope.campaign.current.raw_currency_code || scCurrencyService.getDefaultCurrency();
    }
  };

  /* ---------------------------------------------------------------------- *
   * Select Radio Buttons
   * ---------------------------------------------------------------------- */

  // Track changes in frequency as GA events so we can compare them
  // to changes in frequency in our experimental recurring donation page
  $scope.selectRadioFrequency = function (freq) {
    if (freq && scOrganizationsService.hasPay()) {
      var campaignType = scCampaignsService.active.current.type;
      scAnalytics.eventBeacon({
        category: 'frs ' + campaignType,
        action: 'click',
        label: 'Donation Frequency \u2013 ' + freq + ' (non-text link)'
      });
    }
  };

  /* ---------------------------------------------------------------------- *
   * UI State
   * ---------------------------------------------------------------------- */

  // recurringDonationLevels - Set the Recurring donation amount array
  // levels - Set one-time donation array
  // frequencyMode - set initial frequency value
  $scope.state = {
    mode: 'default',
    levels: _.cloneDeep($scope.block['donation-levels']),
    recurringDonationLevels: _.cloneDeep($scope.block['recurring-donation-levels']),
    frequencyMode: $scope.block['recurring-default'],
    recurringArray: ['daily', 'weekly', 'bi-weekly', 'monthly', 'quarterly', 'semi-annually', 'yearly'],
    presetIndex: null
  };

  /* ---------------------------------------------------------------------- *
   * Display flags
   * ---------------------------------------------------------------------- */

  $scope.show = {
    amountInput: function amountInput() {
      return !_.includes(['3', '6'], $scope.block['recurring-level']);
    },
    frequencyInput: function frequencyInput() {
      return $scope.state.mode === 'default';
    },
    levels: function levels() {
      return !_.includes(['3', '6'], $scope.block['recurring-level']);
    }
  };

  /* ---------------------------------------------------------------------- *
   * Constructed bindings
   * ---------------------------------------------------------------------- */

  $scope.build = {
    titleText: function titleText() {
      if ($scope.state.mode === 'membership') {
        return 'Your membership investment';
      } else if ($scope.state.mode === 'one-time') {
        return 'One-time gift:';
      }

      return 'Select an amount';
    },
    labelText: function labelText() {
      if ($scope.state.mode === 'membership') {
        return 'Monthly investment of:';
      } else if ($scope.block['recurring-level'] == '4') {
        return 'Your monthly recurring donation:';
      }

      return 'Your donation:';
    }
  };

  /* ---------------------------------------------------------------------- *
   * API
   * ---------------------------------------------------------------------- */

  $scope.template = function (name) {
    return 'global/objects/scBlock/types/frs-donation/components/amount/partials/' + name;
  };

  $scope.selectAmount = function (amount) {
    $scope.MODEL.items[0].raw_final_price = amount || null;
  };

  $scope.selectOther = function () {
    $scope.MODEL.items[0].raw_final_price = null;
    angular.element('[name="amount"]').focus();
  };

  /* ---------------------------------------------------------------------- *
   * Init
   * ---------------------------------------------------------------------- */
  var minDonationUSD = 1;
  var maxDonationUSD = 10000000;
  $scope.maxDonation = maxDonationUSD;
  $scope.minDonation = minDonationUSD;
  $scope.setup.mode();
  $scope.setup.levels();
  if (!$scope.preventOldrfForDeque && !$scope.preventRecurringFrequency) {
    $scope.setup.recurringDonationLevels();
  }
  $scope.setup.intlEstimates();
  $scope.setup.currencyDefaults(); // Sets raw currency to org's default
  $scope.autoRound = false;
  $scope.displayRecurringExperiment = function () {
    return globalExperimentSwitch.RECURRING_EXPERIMENT && $scope.state.mode == 'default' && $scope.block.recurring_donation_experiment;
  };

  $timeout(function () {
    if ($scope.globalState.isPreview) {
      $scope.setup.previewDefaults();
      $scope.setup.currencyDefaults();
    }
  });

  // If multicurrency is disabled, lets ensure that the currency is set on the payment object,
  // as there are certain giving modes (fixed donation amount) that bypass the selection
  if (scCampaignsService.active.multiCurrencyDisabled()) {
    $scope.setup.currencyDefaults();
  } else {
    // Update estimated charge amount on donation amount change if user's selected
    // currency does not match the charged currency.
    $scope.$watchGroup(['META.summaryAmount', 'META.unsupportedTaxCompliance'], function (newValues) {
      var unsupportedTaxCompliance = newValues[1];
      if (unsupportedTaxCompliance) {
        var taxEntity = scTaxEntitiesService.currentTaxableEntity || {
          currency_code: 'USD',
          country: 'US'
        };
        // FRS-7367: estimatedCharge does not appear for passport donations when orgs do not
        // have additional tax entities; this situation is only currently supports USD,
        // but CAD is on the way.
        // FRS-7374
        // TODO: open the above up for CAD and possibly more currencies dynamically
        var newAmount = newValues[0];

        scCurrencyService.convertValue(newAmount, $scope.MODEL.payment.raw_currency_code, taxEntity.currency_code).then(function (resp) {
          $scope.META.estimatedCharge = {
            amount: resp,
            currency: taxEntity.currency_code,
            country: _.find(COUNTRY_LIST, function (country) {
              return country.value == taxEntity.country;
            }).label,
            countryCode: taxEntity.country
          };
        });
      }
    });

    $scope.$watch('MODEL.payment.raw_currency_code', function (newCurrencyCode, oldCurrencyCode) {
      if (newCurrencyCode === oldCurrencyCode) return;

      // Other blocks may depend on the currency change to update their UI, like the footer.
      // Broadcast raw currency change so that they can handle these changes since they do not have
      // access to scope.MODEL from this block.
      $rootScope.$broadcast('donationRawCurrencyChange', { currency: newCurrencyCode });

      scTaxEntitiesService.getTaxEntity(newCurrencyCode).then(function (response) {
        var responseCurrency = response.currency_code;
        // FRS-7377: Currently spoofing USD when there are no tax entities because that's the
        // only fully supported one right now
        // TODO FRS-7374: open this up for CAD and other default currencies
        if (!response.currency_code) {
          responseCurrency = 'USD';
        }
        if ($scope.MODEL.payment.raw_currency_code !== newCurrencyCode) return;
        scTaxEntitiesService.setCurrentEntity(response);
        $scope.META.taxComplianceLanguage = response.compliance_text;
        $scope.META.unsupportedTaxCompliance = newCurrencyCode != responseCurrency;
      });

      if ($scope.globalState.isPreview) return;

      var base = $scope.campaign.current.raw_currency_code;
      var amt = $scope.MODEL.items[0].raw_final_price || 0;
      var prevFrequencyPrice = $scope.MODEL.items[0].previous_frequency_price || 0;
      var amtBoundToLevel = false;
      var prevFrequencyAmtBoundToLevel = false;

      $scope.autoRound = scCurrencyService.getAllowedDecimals(newCurrencyCode) == 0;

      // Determine new min/max acceptable donation value, based on USD floor / ceilings
      scCurrencyService.convertValue(minDonationUSD, 'USD', newCurrencyCode).then(function (converted) {
        if ($scope.MODEL.payment.raw_currency_code !== newCurrencyCode) return;
        $scope.minDonation = Math.ceil(converted);
      });
      scCurrencyService.convertValue(maxDonationUSD, 'USD', newCurrencyCode).then(function (converted) {
        if ($scope.MODEL.payment.raw_currency_code !== newCurrencyCode) return;
        $scope.maxDonation = Math.round(converted);
      });

      // Donation levels, rounded aesthetically
      _.forEach($scope.state.levels, function (level) {
        level.loading = true;

        // For Legacy UI & OldDeque UI, we have single array for both one-time & recurring
        if (($scope.preventOldrfForDeque || $scope.preventRecurringFrequency) && amt === level.displayAmount) {
          amtBoundToLevel = level;
        }

        // For new RF, we have 2 different donation arrays for one-time & recurring
        if (!$scope.preventOldrfForDeque && !$scope.preventRecurringFrequency) {
          if ($scope.MODEL.frequency == 'one-time' && amt === level.displayAmount && level.display_on_page === true) {
            amtBoundToLevel = level;
          }
        }
        // if one-time is selected then, on currency change we need to convert the previous_frequency_price
        // based on currency selected. So once we select recurring frequency, we should get the converted value
        if (!$scope.preventOldrfForDeque && !$scope.preventRecurringFrequency && prevFrequencyPrice === level.displayAmount) {
          prevFrequencyAmtBoundToLevel = level;
        }

        scCurrencyService.convertValue(level.amount, base, newCurrencyCode, true).then(function (converted) {
          if ($scope.MODEL.payment.raw_currency_code !== newCurrencyCode) return;
          level.displayAmount = Math.ceil(converted);
          level.loading = false;
          if (amtBoundToLevel === level) {
            $scope.MODEL.items[0].raw_final_price = converted;
          }
          if (!$scope.preventOldrfForDeque && !$scope.preventRecurringFrequency && prevFrequencyAmtBoundToLevel === level) {
            $scope.MODEL.items[0].previous_frequency_price = converted;
          }
        });
      });

      // if preventOldrfForDeque && preventRecurringFrequency tag are not added,
      // i.e. new reucrring Frequensies UI displayed
      // then only convert recurring-donation array amount to
      // based on selected currency value
      if (!$scope.preventOldrfForDeque && !$scope.preventRecurringFrequency) {
        _.forEach($scope.state.recurringDonationLevels, function (level) {
          level.loading = true;
          if ($scope.MODEL.frequency !== 'one-time' && amt === level.displayAmount && level.display_on_page === true) {
            amtBoundToLevel = level;
          }
          if (prevFrequencyPrice === level.displayAmount) {
            prevFrequencyAmtBoundToLevel = level;
          }

          scCurrencyService.convertValue(level.amount, base, newCurrencyCode, true).then(function (converted) {
            if ($scope.MODEL.payment.raw_currency_code !== newCurrencyCode) return;
            level.displayAmount = Math.ceil(converted);
            level.loading = false;
            if (amtBoundToLevel === level) {
              $scope.MODEL.items[0].raw_final_price = converted;
            }
            if (prevFrequencyAmtBoundToLevel === level) {
              $scope.MODEL.items[0].previous_frequency_price = converted;
            }
          });
        });
      }

      // Donation amount, not rounded unless it matches a donation level
      if (!amtBoundToLevel) {
        // fixed donations need to convert from the donation default
        // in campaign currency every time
        var oldCode = oldCurrencyCode;
        if ($scope.state.fixedAmount) {
          amt = $scope.setup.amount();
          oldCode = base;
        }

        // we don't want to convert the amount initially if we are loading pass through param values
        if ($state.params.currency && $state.params.amount && initialCurrencyConvertCheck) {
          initialCurrencyConvertCheck = false;
          return;
        }

        scCurrencyService.convertValue(amt, oldCode, newCurrencyCode, false).then(function (converted) {
          if ($scope.MODEL.payment.raw_currency_code !== newCurrencyCode) return;

          var isConvertedBelowMinimum = converted < 1;
          var shouldRoundCurrency = $scope.autoRound;
          var shouldUseCeiling = isConvertedBelowMinimum || shouldRoundCurrency;
          var ceilingAmount = Math.ceil(converted);
          var decimalAmount = parseFloat(converted.toFixed(scCurrencyService.getAllowedDecimals(newCurrencyCode)));

          var itemAmount = shouldUseCeiling ? ceilingAmount : decimalAmount;
          if (!$scope.preventRecurringFrequency) {
            $rootScope.$broadcast('selectedAmountChange', itemAmount);
          }

          $scope.MODEL.items[0].raw_final_price = $scope.MODEL.items[0].raw_final_price === 'clear' ? 'clear' : itemAmount || null;
        });
      }
    });
  }

  $scope.$watch("block['recurring-level']", function (level, oldLevel) {
    if (level !== oldLevel) {
      $scope.setup.mode();
    }
  });

  $scope.$watch("block['recurring-default']", function (level, oldLevel) {
    if (level !== oldLevel) {
      $scope.setup.mode();
    }
  });

  $scope.$watch("block['donation-levels']", function (newLevels) {
    $scope.state.levels = _.cloneDeep(newLevels);
    $scope.setup.levels();
  }, true);

  $scope.$watch("block['recurring-donation-levels']", function (newLevels) {
    $scope.state.recurringDonationLevels = _.cloneDeep(newLevels);
    $scope.setup.recurringDonationLevels();
  }, true);

  $scope.$watch('items[0].raw_final_price', function (amount) {
    $scope.META.summaryAmount = amount;
  });

  _.merge($scope.MODEL, {
    frequency: $scope.setup.frequency(),
    items: [{
      type: 'donation',
      product_name: 'Donation',
      raw_final_price: $scope.setup.amount(),
      previous_frequency_price: !$scope.preventOldrfForDeque && !$scope.preventRecurringFrequency && $scope.setup.setPreviousFrequencyPrice()
    }]
  });
}
})();