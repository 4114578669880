(function(){
'use strict';

createHeap.$inject = ["$q", "$window", "scAnalyticsUtils", "scOrganizationsService", "scThemesService"];
angular.module('classy').factory('heapService', createHeap);

function createHeap($q, $window, scAnalyticsUtils, scOrganizationsService, scThemesService) {
  var heapService = { name: 'heap' };
  var debugLog = scAnalyticsUtils.debugLog,
      getCampaign = scAnalyticsUtils.getCampaign,
      isEmbeddedGivingEvent = scAnalyticsUtils.isEmbeddedGivingEvent,
      isFunnelConversionEvent = scAnalyticsUtils.isFunnelConversionEvent,
      isDigitalWalletsLoadedEvent = scAnalyticsUtils.isDigitalWalletsLoadedEvent,
      isPublicPage = scAnalyticsUtils.isPublicPage;

  var loadingPromise = void 0;
  var loadingInterval = void 0;

  /* Interface Methods
  ========================================================= */
  heapService.recordPageView = function (data) {
    if (!$window.heap || !$window.heap.track) return;

    $window.heap.track('Page View', Object.assign({}, data, {
      environment: SC.env,
      is_public: isPublicPage()
    }));
  };

  heapService.loaded = function () {
    if (!$window.heap || !SC.heapId) return $q.reject();
    if (loadingPromise) return loadingPromise;

    loadingPromise = $q(function (resolve, reject) {
      var intervalCount = 0;

      loadingInterval = setInterval(function () {
        intervalCount += 1;

        if (intervalCount > 20) {
          clearInterval(loadingInterval);
          reject();
        }

        if (SC.heapLoaded) {
          clearInterval(loadingInterval);
          resolve();
        }
      }, 200);
    });

    return loadingPromise;
  };

  heapService.track = function (event, data) {
    if (!$window.heap) return;

    if (isFunnelConversionEvent(event)) {
      trackTransactionEvent(event, data);
    } else if (isDigitalWalletsLoadedEvent(event)) {
      trackDigitalWalletsLoadedEvent(data);
    } else if (isEmbeddedGivingEvent(event)) {
      trackEmbeddedGivingEvent(event, data);
    }
  };

  heapService.identifyMember = function (id, data) {
    $window.heap.identify(id.toString());
    $window.heap.addUserProperties(data);
  };

  heapService.eventBeacon = function (data) {
    return heap.track(data.label, data);
  };

  heapService.registerSuperProperties = function () {
    if (!$window.heap) return;

    var campaign = getCampaign();

    var properties = {
      environment: SC.env,
      organization_id: scOrganizationsService.active.current.id,
      payment_processor: scOrganizationsService.active.current.payment_provider_name,
      campaign: campaign.current.id,
      campaign_type: campaign.current.type,
      duplicate_fundraisers: campaign.current.allow_duplicate_fundraisers,
      additional_donation_enabled: _.get(scThemesService.active.current.styles, 'ticketedEvent.additionalDonation', undefined),
      // This property helps us identify existing fundraisers in the create fundraiser flow.
      // We set this to false just in case a member is coming from another campaign
      // in which they were an active fundraiser but might not be on the current campaign
      existing_fundraiser: false
    };

    $window.heap.addEventProperties(properties);
  };

  return heapService;

  /* Private Methods
  ========================================================= */
  function trackTransactionEvent(event) {
    var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (!$window.heap || !$window.heap.track) return;

    debugLog('heap conversion', data);

    var amount = data.amount || 0;
    var rawCurrencyCode = data.raw_currency_code;
    var donationAmount = data.donation_amount;
    var rwfCompletionEvent = event === 'registration/create/complete';

    var transactionId = rwfCompletionEvent ? data.registration_transaction_id : data.transactionId;

    var eventName = rwfCompletionEvent ? 'Event Cart - Successful Payment' : 'Donation Page - Successful Payment';

    // Adding this for RWF is currently out of scope
    var digitalWalletsPresent = rwfCompletionEvent ? false : data.digitalWalletsPresent;
    var paymentRequestButton = rwfCompletionEvent ? '' : data.paymentRequestButton;
    var paymentSource = rwfCompletionEvent ? '' : data.paymentSource;

    $window.heap.track(eventName, {
      id: transactionId,
      revenue: amount,
      donation_amount: donationAmount || undefined,
      num_attendees: data.items ? data.items.length : 0,
      raw_currency_code: rawCurrencyCode,
      digitalWalletsPresent: digitalWalletsPresent.toString(),
      paymentRequestButton: paymentRequestButton,
      paymentSource: paymentSource
    });
  }

  function trackDigitalWalletsLoadedEvent() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    if (!$window.heap || !$window.heap.track) return;

    var eventName = data.digitalWalletsLoaded ? 'Donation Page - View Page - Digital Wallet' : 'Donation Page - View Page - Standard';
    $window.heap.track(eventName, data);
  }

  function trackEmbeddedGivingEvent(event) {
    var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (!$window.heap || !$window.heap.track) return;

    $window.heap.track(event, data);
  }
}
})();