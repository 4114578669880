(function(){
'use strict';

angular.module('classy').component('radioField', {
  template: '\n    <div class="radio-field" data-ng-class="{ \'admin-body__lock-field form__text-input__outer\': $ctrl.lockField }">\n      \n      <form-label required="$ctrl.required" data-ng-if="$ctrl.label" _spec="label">{{::$ctrl.label}}</form-label>\n      <div class="form__sublabel" ng-if="$ctrl.sublabel">{{::$ctrl.sublabel}}</div>\n\n      <div ng-if="$ctrl.lockField"\n        data-sc-tooltip-class="admin-campaign-tooltip lock-tooltip-msg"\n        data-sc-tooltip-position="center"\n        data-sc-tooltip-position-tip="bottom center"\n        show-event="\'mouseenter\'" hide-event="\'mouseout\'"\n        fixed-tooltip="true"\n        data-sc-tooltip="\'This field is locked at the template level.\'"\n        class="admin-body__tooltip-element"></div>\n\n      <radio-control\n        ng-model="$ctrl.model"\n        ng-model-options="{ getterSetter: $ctrl.getterSetter }"\n        ng-required="$ctrl.required"\n        sc-validity-reporter\n        sc-validity-classes\n        value="$ctrl.radioValueA"\n        name="{{::$ctrl.name }}"\n        size="::$ctrl.size"\n        _spec="input"\n        aria-label="{{::$ctrl.radioLabelA}}">{{::$ctrl.radioLabelA}}</radio-control>\n\n      <!-- No name on second radio -->\n      <radio-control class="radio-field"\n        ng-model="$ctrl.model"\n        ng-model-options="{ getterSetter: $ctrl.getterSetter }"\n        ng-required="$ctrl.required"\n        sc-validity-reporter="$ctrl.name"\n        sc-validity-classes\n        value="$ctrl.radioValueB"\n        ng-disabled="$ctrl.disabled"\n        size="::$ctrl.size"\n        _spec="inputB"\n        aria-label="{{::$ctrl.radioLabelB}}">{{::$ctrl.radioLabelB }}</radio-control>\n\n      <form-error name="$ctrl.name" messages="$ctrl.errorMessages" _spec="errors"></form-error>\n    </div>\n  ',
  bindings: {
    model: '=',
    label: '<',
    sublabel: '@?',
    name: '<',
    size: '<?',
    radioLabelA: '@',
    radioValueA: '<',
    radioLabelB: '@',
    radioValueB: '<',
    errorMessages: '<?',
    getterSetter: '<?',
    required: '<?',
    disabled: '<?',
    lockField: '<?'
  },
  controller: function RadioFieldCtrl() {
    var DEFAULTS = {
      errorMessages: {
        required: 'This field is required.'
      },
      getterSetter: false,
      required: false,
      size: 'medium'
    };

    this.$onInit = function () {
      _.defaultsDeep(this, DEFAULTS);
    };
  }
});
})();