(function(){
'use strict';

angular.module('classy').component('cpTokenizerControl', {
  require: {
    ngModelCtrl: 'ngModel'
  },
  template: '\n      <span class="cp-tokenizer-control__status"\n      ng-class="{ loading: $ctrl.isLoading }" aria-live="polite" title="payment card input is {{ $ctrl.isLoading ? \'loading\' : \'ready\' }}">\n        <span class="cp-tokenizer-control__status-loading" aria-hidden="true"></span>\n        <span class="cp-tokenizer-control__status-ready" aria-hidden="true">\n          <i class="ci ci-credit-card cp-tokenizer-control__status-icon" aria-hidden="true"></i>\n        </span>\n      </span>\n      \n      <span id="{{:: $ctrl.id }}" data-tracked-element="donation-page_checkout_tokenex-cc-input" class="{{ $ctrl.isLoading ? \'loading\' : \'\' }} cp-tokenizer-control__iframe" title="please enter your payment card information"></span>\n      <span ng-if="$ctrl.cardBrand" aria-hidden="true" class="card-container">\n        <span class="cp-credit-card__brand" ng-bind-html="$ctrl.brandIcon()">\n        </span>\n      <span>\n    ',
  bindings: {
    cmdEvent: '<',
    onToken: '&',
    onValid: '&',
    onReset: '&',
    cardBrand: '<?'
  },
  controller: ["$element", "$scope", "$timeout", "$window", "$templateCache", "$sce", "cpAPI", "onIOSBlur", "scBanner", function controller($element, $scope, $timeout, $window, $templateCache, $sce, cpAPI, onIOSBlur, scBanner) {
    var _this3 = this;

    /** -------------------------------------------------------------------- *
     * Life cycle
     * -------------------------------------------------------------------- */

    this.$onInit = function () {
      var _this = this;

      this.haveShownTokenExAlert = false;
      this.iframeTimeout = false;
      this.subscribeToCommands();
      this.isLoading = true;
      this.id = 'cp-tokenizer-control__iframe';
      this.empty = true;
      this.ngModelCtrl.$isEmpty = function () {
        return _this.isEmpty();
      };
    };

    this.$postLink = function () {
      var _this2 = this;

      this.buildIframe();
      this.deregisterIOSBlur = onIOSBlur($element, function () {
        return _this2.handleBlur();
      });
    };

    this.$onDestroy = function () {
      this.deregisterIOSBlur();
    };

    this.brandIcon = function () {
      var html = void 0;
      switch (this.cardBrand) {
        case 'visa':
          html = $templateCache.get('global/classy-pay/svg/visa');
          break;
        case 'americanExpress':
          html = $templateCache.get('global/classy-pay/svg/amex');
          break;
        case 'discover':
          html = $templateCache.get('global/classy-pay/svg/discover');
          break;
        case 'masterCard':
          html = $templateCache.get('global/classy-pay/svg/mastercard');
          break;
        case 'diners':
          html = $templateCache.get('global/classy-pay/svg/diners-club');
          break;
        default:
          html = "<i class='cp-credit-card__brand-icon ci ci-credit-card'></i>";
          break;
      }
      return $sce.trustAsHtml(html);
    };

    /** -------------------------------------------------------------------- *
     * Setup
     * -------------------------------------------------------------------- */

    this.buildIframe = function () {
      // This may be a reset after a failed transaction attempt.
      _this3.ngModelCtrl.$setViewValue(null);
      _this3.ngModelCtrl.$setPristine();
      _this3.ngModelCtrl.$setUntouched();

      _this3.isLoading = true;
      var origin = $window.location.origin;

      if (_this3.iframe) {
        _this3.iframe.remove();
      }

      cpAPI.getTokenexData(origin).then(function (data) {
        data.styles = {
          base: '\n              width:85%;\n              height:38px;\n              background:transparent;\n              border:0;\n              line-height:38px;\n              vertical-align:middle;\n              font-size:15px;\n              font-family:\'Mulish\',Arial,Helvetica,sans-serif;\n              color: #444b55;\n              outline:none;\n              padding:0 10px;\n              font-weight:400;\n            ',
          placeholder: '\n              color: #444b55;\n              opacity:0.5;\n              font-family:"Mulish",Arial,Helvetica,sans-serif;\n              font-weight:400;\n            '
        };
        data.font = 'Mulish';
        data.placeholder = 'Credit Card';
        data.enablePrettyFormat = true;
        data.enableValidateOnKeyUp = true;
        data.enableAutoComplete = true;

        // eslint-disable-next-line no-undef
        _this3.iframe = new TokenEx.Iframe(_this3.id, data);
        _this3.iframe.on('focus', _this3.handleFocus);
        _this3.iframe.on('blur', _this3.handleBlur);
        _this3.iframe.on('validate', _this3.handleValidate);
        _this3.iframe.on('tokenize', _this3.handleToken);

        _this3.iframe.on('load', function () {
          $timeout(function () {
            _this3.isLoading = false;
            _this3.$iframe = angular.element('#tx_iframe_cp-tokenizer-control__iframe');
            _this3.$iframe.attr('title', 'Credit Card details');
          });
        });
        _this3.iframe.load();
        _this3.setIframeTimeout();
      });
    };

    /** -------------------------------------------------------------------- *
     * Command management
     * -------------------------------------------------------------------- */

    this.subscribeToCommands = function () {
      var _this4 = this;

      // eslint-disable-next-line no-unused-vars
      $scope.$on(this.cmdEvent, function (ev, command) {
        return _this4.handleCommand(command);
      });
    };

    this.handleCommand = function (command) {
      switch (command) {
        case 'reset':
          this.resetIframe();
          break;
        case 'tokenize':
          if (this.iframe) {
            this.iframe.tokenize();
          }
          break;
        default:
        // do nothing
      }
    };

    /**
     * setIframeTimeout
     *
     * Tokenex' session expires in 30 minutes, so we need to reset the iframe in that
     * interval in order to prevent submission errors on stale pages
     *
     */
    this.setIframeTimeout = function () {
      if (this.iframeTimeout) $timeout.cancel(this.iframeTimeout);
      var twentyFiveMinutes = 25 * 1000 * 60;
      this.iframeTimeout = $timeout(this.resetIframe.bind(this), twentyFiveMinutes);
    };

    this.resetIframe = function () {
      this.iframeTimeout = undefined;
      this.buildIframe();
      this.onReset();
      this.handleValidate({ isValid: false, validator: 'required' });
    };

    /** -------------------------------------------------------------------- *
     * Event handlers
     * -------------------------------------------------------------------- */

    this.handleToken = function (token) {
      _this3.ngModelCtrl.$setViewValue(token.token);
      _this3.onToken({ token: token });
    };

    this.handleFocus = function () {
      /**
       * Temporary remediation for TokenEx issue with Evernote Clipper plugin
       */
      if ((document.head.textContent || document.head.innerText).indexOf('Evernote Clipper') !== -1 && !_this3.haveShownTokenExAlert) {
        scBanner('Some supporters with the Evernote extension are experiencing issues entering credit card information. Simply deactivating the extension fixes the issue', 'error', true);
        _this3.haveShownTokenExAlert = true;
      }

      /**
       * Temporary remediation for TokenEx issue with MetaMask Chrome plugin
       */
      if ($window.web3 && $window.web3.currentProvider && $window.web3.currentProvider.isMetaMask && !_this3.haveShownMetaMaskAlert) {
        scBanner('Some supporters with the MetaMask extension are experiencing issues entering credit card information. Simply deactivating the extension fixes the issue', 'error', true);
        _this3.haveShownMetaMaskAlert = true;
      }

      if (_this3.ngModelCtrl.$focus) {
        _this3.ngModelCtrl.$setTouched();
        _this3.ngModelCtrl.$focus();
      }
    };

    this.handleBlur = function () {
      _this3.ngModelCtrl.$setDirty();
      $element.blur();
      if (_this3.ngModelCtrl.$blur) {
        _this3.ngModelCtrl.$blur();
      }
      _this3.iframe.validate();
    };

    this.handleValidate = function (data) {
      _this3.ngModelCtrl.$setValidity('required', true);
      _this3.ngModelCtrl.$setValidity('format', true);
      _this3.ngModelCtrl.$setValidity('signature', true);
      _this3.ngModelCtrl.$setValidity('luhnCheck', true);
      if (data.isValid) {
        _this3.empty = false;
        _this3.onValid({ brand: data.cardType, lastFour: data.lastFour });
      } else {
        if (data.validator === 'required') {
          _this3.empty = true;
        }
        _this3.onValid({ brand: null, lastFour: null });
        _this3.ngModelCtrl.$setValidity(data.validator, data.isValid);
      }
      _this3.ngModelCtrl.$syncValidity();
    };

    this.isEmpty = function () {
      return this.empty;
    };
  }]
});
})();