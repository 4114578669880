(function(){
'use strict';

createHelper.$inject = ["$location", "$rootScope", "scAnalytics", "scMembersService"];
angular.module('classy').factory('scAnalyticsHelper', createHelper);

function createHelper($location, $rootScope, scAnalytics, scMembersService) {
  var scAnalyticsHelper = {};

  scAnalyticsHelper.watchForMember = function () {
    $rootScope.$watch(function () {
      return _.get(scMembersService, 'active.current.id', null);
    }, function (newId) {
      if (!newId) {
        return;
      }
      var member = scMembersService.active.current;

      var identifyData = {
        avatar: member.thumbnail_large,
        hostName: $location.host(),
        visitorType: scMembersService.you.current && scMembersService.you.current.id ? 'member' : ''
      };
      scAnalytics.identifyMember(member.id, identifyData);
    });
  };

  scAnalyticsHelper.watchForPageChanges = function ($scope) {
    // Ensures we check if member is logged in before registering page view to
    // fullfil parameters sent in FRS-7710 & FRS-7711
    $scope.$on('memberChecked', function () {
      scAnalytics.recordPageView();
    });
  };

  scAnalyticsHelper.setup = function ($scope) {
    scAnalyticsHelper.watchForMember();
    scAnalyticsHelper.watchForPageChanges($scope);
  };

  return scAnalyticsHelper;
}
})();