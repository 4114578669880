(function(){
'use strict';

/*
 * scSsoService
 *
 * */

scSsoService.$inject = ["$http", "$log", "$rootScope", "$q", "scMembersService", "scCampaignsService", "scFundraisingPagesService", "scFundraisingTeamsService", "scOrganizationsService"];
angular.module('classy').service('scSsoService', scSsoService);

function scSsoService($http, $log, $rootScope, $q, scMembersService, scCampaignsService, scFundraisingPagesService, scFundraisingTeamsService, scOrganizationsService) {
  var _this = this;

  this.initClassyapp = function () {
    // if a component or service has already tried to begin the classyapp
    // authentication process, we just return that promise so it can be
    // watched by any future caller. This prevents multiple authenitcation
    // attempts
    if (_this.initClassyappPromise) return _this.initClassyappPromise;

    $log.log('Init: classyapp');

    var deferred = $q.defer();
    // TODO: Remove this entire file soon
    var $iframe = $('<iframe src="/npo/dashboard?cid=' + _.get(scOrganizationsService, 'active.current.id', null) + '" style="display:none;"></iframe>"');
    $iframe.appendTo('body');

    $iframe.on('load', function () {
      deferred.resolve();
      $iframe.remove();
      $log.log('Init: classyapp [DONE]');
    });

    // keep a reference to the promise so that any service who needs to resolve
    // based on classyapp initialization can just tap into the same sso
    // process and there are no duplicate calls
    _this.initClassyappPromise = deferred.promise;

    return deferred.promise;
  };

  this.initMember = function (member) {
    var deferred = $q.defer();
    scMembersService.init(member);
    scMembersService.you.update(member);
    scFundraisingPagesService.userFundraisers = {};

    if (_.get(scCampaignsService, 'active.current', false) && scCampaignsService.active.HAS_FUNDRAISING) {
      scMembersService.getAllMemberPages(member.id, scCampaignsService.active.current.id).then(function (resp) {
        _.forEach(resp.fundraisingPages, function (page) {
          scFundraisingPagesService.add(page);

          // add the team associated with the fundraising page
          if (page.fundraising_team_id) {
            scFundraisingTeamsService.fetch(page.fundraising_team_id);
          }
        });

        // getAllMemberPages only grabs teams that the member is a team lead for (aka created the team)
        _.forEach(resp.fundraisingTeams, function (team) {
          scFundraisingTeamsService.add(team);
        });

        deferred.resolve();
      }).catch(function () {
        deferred.resolve();
      });
    }
    return deferred.promise;
  };

  this.stealthLogin = function () {
    var orgId = _.get(scOrganizationsService, 'active.current.id', false);
    var campaignId = _.get(scCampaignsService, 'active.current.id', false);

    var div = angular.element('\n      <div style="width:1px; height:1px; opacity:0; overflow:hidden;">\n        <div\n          class="classy-login-iframe"\n          data-flow="login_only"\n          data-org_id="' + orgId + '"\n          data-campaign_id="' + campaignId + '"></div>\n      </div>');
    var deferred = $q.defer();

    div.appendTo('body');
    Classy.ready(function () {
      Classy.parseDom(function (response) {
        div.remove();
        if (response.authorization_code) {
          var xhrFields = {};
          if (SC.env === 'development') {
            xhrFields = {
              xhrFields: {
                withCredentials: true
              }
            };
          }
          $http(Object.assign({
            method: 'GET',
            url: '/oauth2/iframe_callback?code=' + response.authorization_code
          }, xhrFields)).then(function (oAuthResponse) {
            $log.log('SSO success');
            /* Exclude Members from Adroll Segmenting */
            try {
              __adroll.record_user({
                adroll_segments: 'member'
              });
            } catch (err) {}

            if (oAuthResponse.data.member) {
              _this.initMember(oAuthResponse.data.member).then(function () {
                deferred.resolve();
              });
            } else {
              deferred.resolve();
            }
            _this.initClassyapp();
          }).catch(function () {
            deferred.resolve();
          });
        } else {
          // handle error.
          $log.log('SSO failed: ' + response.error);
          $rootScope.SC.status.banner = {
            type: 'error',
            msg: "I'm sorry, there was a problem signing you in."
          };
          deferred.reject();
        }
      });
    });
    return deferred.promise;
  };
}
})();