(function(){
'use strict';

/* EmbeddedGivingUtil houses misc business logic */
angular.module('classy').service('EmbeddedGivingUtil', ["EG_EVENTS", function (EG_EVENTS) {
  var _this = this;

  this.getPrimaryColorFromTheme = function (theme) {
    var color = _.get(theme, 'current.styles.primaryColor');
    if (!color) {
      color = 'rgba(0,0,0,1.0)'; // default to black
    }
    return color;
  };

  /* Primary color can be in 2 diff formats: 'rgba' or 'rgb' (string)
   * Background color is calculated by changing opacity of primary color to .12 */
  this.getBackgroundColorFromTheme = function (theme) {
    var primaryColor = _this.getPrimaryColorFromTheme(theme);
    var bgOpacity = '.12';
    var bgColor = 'rgba(0,0,0,' + bgOpacity + ')'; // set opacity to .12

    if (primaryColor) {
      // if format is rgba, then 4 params present, need to replace last ('a')
      if (primaryColor.indexOf('rgba') !== -1) {
        var colorTokens = primaryColor.split(',');
        colorTokens[colorTokens.length - 1] = bgOpacity + ')';
        bgColor = colorTokens.join(',');
      } else if (primaryColor.indexOf('rgb') !== -1) {
        // else check if rgb is present.
        // if is but no 4th param, then we add 4th param (instead of replace)
        var _colorTokens = primaryColor.split(')');
        _colorTokens[_colorTokens.length - 1] = bgOpacity + ')';
        bgColor = _colorTokens.join(',');
      }
    }
    return bgColor;
  };

  // all these nested properties are a nightmare...
  this.getLogoUrl = function (page, theme) {
    var logo = null;
    var headerBlock = _.find(page.block_sections, { name: 'header' });
    if (headerBlock && headerBlock.blocks) {
      headerBlock = headerBlock.blocks[0];
    }

    if (_.get(headerBlock, 'logo.src') || _.get(headerBlock, 'logo.asset.src')) {
      logo = _.get(headerBlock, 'logo.src') || _.get(headerBlock, 'logo.asset.src');
    } else if (_.get(theme, 'current.styles.headerLogo.src')) {
      logo = theme.current.styles.headerLogo.src;
    }

    return logo;
  };

  this.getPaymentTypeFromCheckoutResponse = function (res) {
    var type = '';

    if ((res.payment_gateway === 'Dwolla' || res.payment_gateway === 'Stripe') && type.account_number) {
      type = 'ACH';
    } else if (res.payment_gateway === 'BrainTree' && !res.card_last_four) {
      type = 'PayPal';
    } else if (res.card_last_four) {
      type = 'Credit Card';
    } else if (res.payment_gateway === 'Classy Pay' && res.payment_source === 'PAYPAL') {
      type = 'PayPal Commerce';
    }

    return type;
  };

  this.getAnalyticsEventData = function (opts) {
    var analyticsData = {
      organizationId: SC.organization.id,
      // paymentProcessor, set to crypto? TODO
      campaignType: SC.campaign.type,
      campaignId: SC.campaign.id
      // donationTarget??
    };

    if (!_.isEmpty(opts)) {
      // donation amount
      if (opts.items && opts.items.length) {
        analyticsData.donationAmount = opts.items[0].raw_final_price;
      }

      if (opts.payment && opts.payment.raw_currency_code) {
        analyticsData.currencyCode = opts.payment.raw_currency_code;
      }
    }

    return analyticsData;
  };

  this.getDonationAmountMax = function (block) {
    var levels = block && block['donation-levels'];
    var maxLevelAmount = 0;

    if (levels && levels.length) {
      _.forEach(levels, function (l) {
        if (l.display_on_page && l.amount != 'Custom' && l.amount > maxLevelAmount) {
          maxLevelAmount = l.amount;
        }
      });
    }

    return maxLevelAmount;
  };

  this.getDonationAmountMin = function (block) {
    var levels = block && block['donation-levels'];
    var minLevelAmount = 999999999999999999999;

    if (levels && levels.length) {
      _.forEach(levels, function (l) {
        if (l.display_on_page && l.amount != 'Custom' && l.amount < minLevelAmount) {
          minLevelAmount = l.amount;
        }
      });
    }

    return minLevelAmount;
  };

  this.getEventsObject = function () {
    var egEventsObj = {};
    var egEvents = _.values(EG_EVENTS);
    for (var i = 0; i < egEvents.length; i += 1) {
      egEventsObj[egEvents[i]] = egEvents[i];
    }

    return egEventsObj;
  };

  this.hasDoubleTheDonation = function () {
    return SC && SC.organization && SC.organization.has_double_the_donation_employer_match;
  };
}]);
})();