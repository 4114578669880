(function(){
'use strict';

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

angular.module('classy').controller('EmbeddedGivingCtrl', ["$log", "$scope", "$rootScope", "$timeout", "$q", "$window", "$sce", "formDirective", "bugsnagClient", "cpConstants", "scAnalytics", "scBanner", "scCampaignsService", "scDigitalWalletsService", "PayPalSDK", "CHANNEL", "EG_EVENTS", "EmbeddedGiving", "EmbeddedGivingDAL", "EmbeddedGivingLocations", "EmbeddedGivingMessenger", "EmbeddedGivingPaths", "EmbeddedGivingRecurringNudge", "EmbeddedGivingUtil", function ($log, $scope, $rootScope, $timeout, $q, $window, $sce, formDirective, bugsnagClient, cpConstants, scAnalytics, scBanner, scCampaignsService, scDigitalWalletsService, PayPalSDK, CHANNEL, EG_EVENTS, EmbeddedGiving, EmbeddedGivingDAL, EmbeddedGivingLocations, EmbeddedGivingMessenger, EmbeddedGivingPaths, EmbeddedGivingRecurringNudge, EmbeddedGivingUtil) {
  $scope.EmbeddedGivingDAL = EmbeddedGivingDAL;
  $scope.EmbeddedGivingPaths = EmbeddedGivingPaths;
  /*
   * $scope.eg is an object leveraged to achieve statefullness across EG controllers
   * 6 possiblities for 'donationStep':
   * 1) root
   * 2) recurringNudge (configured)
   * 3) paymentMethods
   * 4) paymentDetails
   * 5) address (configured)
   * 6) contact
   */
  $scope.eg = {
    activeView: EmbeddedGiving.defaultView.path,
    donationStep: 'root',
    backgroundColor: EmbeddedGivingUtil.getBackgroundColorFromTheme($scope.theme),
    primaryColor: EmbeddedGivingUtil.getPrimaryColorFromTheme($scope.theme),
    logoUrl: function logoUrl() {
      return EmbeddedGivingUtil.getLogoUrl($scope.page, $scope.theme);
    },

    isCheckingOut: false,
    isFOTEnabled: scCampaignsService.active.current.classy_mode_enabled || scCampaignsService.active.current.dcf_enabled,
    isRequiredFullAddress: EmbeddedGiving.opts.eg.fullAddress,
    isRequiredRecurringNudge: EmbeddedGiving.opts.eg.recurringNudge,
    isValidPayment: true,
    // For new version of FAQ that "overlays"
    faqOverlay: {
      isOpen: false,
      isOpened: function isOpened() {
        return $scope.eg.faqOverlay.isOpen;
      }
    },
    // Double the donation
    dtd: {
      enabled: false, // Does the org have dtd?
      active: false // DtD Checkbox
    },
    showAchTerms: function showAchTerms() {
      return $scope.API.getPaymentMethod() === cpConstants.PAYMENT_METHODS.ACH;
    },
    showIsCompanyCard: function showIsCompanyCard() {
      return scCampaignsService.active.current.corporate_donation_enabled && ($scope.API.getPaymentMethod() === cpConstants.PAYMENT_METHODS.STRIPE || $scope.API.getPaymentMethod() === cpConstants.PAYMENT_METHODS.CC);
    }
  };

  EmbeddedGivingMessenger.init($scope); // How we communicate with the iframe
  // Not fully wired up currently...
  EmbeddedGivingDAL.initCache({
    amount: 1,
    isFeeOnTopEnabled: null
  });

  $scope.getHeadlineTrustedHtml = function () {
    return $sce.trustAsHtml($scope.block.headline);
  };

  // TODO move all this business logic out of ctrl
  /* [START: Overriding frsDonationBlock ctrl code] */
  var autofillDonationForm = function autofillDonationForm(event) {
    return $timeout(function () {
      scDigitalWalletsService.onDigitalWalletsSubmit();

      var payerName = event.payerName,
          payerEmail = event.payerEmail,
          paymentMethod = event.paymentMethod;
      var billingDetails = paymentMethod.billing_details;
      var name = billingDetails.name,
          _billingDetails$addre = billingDetails.address,
          city = _billingDetails$addre.city,
          state = _billingDetails$addre.state,
          line1 = _billingDetails$addre.line1,
          line2 = _billingDetails$addre.line2,
          postalCode = _billingDetails$addre.postal_code;


      var splitName = payerName.split(' ');
      var firstName = splitName[0];
      var lastName = splitName[splitName.length - 1];

      // Fill in donor information if the donor hasn't already
      if (!$scope.META.member_first_name) {
        $scope.META.member_first_name = firstName;
      }
      if (!$scope.META.member_last_name) {
        $scope.META.member_last_name = lastName;
      }
      if (!$scope.MODEL.member_name) {
        $scope.MODEL.member_name = payerName;
      }
      if (!$scope.MODEL.member_email_address) {
        $scope.MODEL.member_email_address = payerEmail;
      }

      // Fill in Billing Information
      splitName = name.split(' ');
      firstName = splitName[0];
      lastName = splitName[splitName.length - 1];

      $scope.MODEL.billing_first_name = firstName;
      $scope.MODEL.billing_last_name = lastName;
      $scope.MODEL.billing_address1 = line1;
      $scope.MODEL.billing_address2 = line2;
      $scope.MODEL.billing_city = city;
      $scope.MODEL.billing_postal_code = postalCode;
      $scope.MODEL.billing_state = state;

      $scope.MODEL.payment.stripe.source = paymentMethod;

      $scope.FORM.$setSubmitted();
    });
  };

  /**
   * When submitting a digital wallets donation, we need to ignore
   * invlaid credit card field, recaptcha errors, and address autocomplete errors.
   *
   * @param {Array} errList list of errors
   * @returns {Boolean} whether all errors can be ignored for DW donation
   */
  var ignoreErrorsForDigitalWallets = function ignoreErrorsForDigitalWallets(errList) {
    var numOfErrsToIgnore = 0;

    if (errList.find(function (e) {
      return e.$name === 'credit card';
    })) {
      numOfErrsToIgnore += 1;
    }

    if (errList.find(function (e) {
      return e.$name === 'recaptcha';
    })) {
      numOfErrsToIgnore += 1;
    }

    if (errList.find(function (e) {
      return e.$name === 'billing address auto';
    })) {
      numOfErrsToIgnore += 1;
    }

    if (errList.find(function (e) {
      return e.$name === 'honoree first name';
    })) {
      numOfErrsToIgnore += 1;
    }

    if (errList.find(function (e) {
      return e.$name === 'honoree last name';
    })) {
      numOfErrsToIgnore += 1;
    }

    return errList.length === numOfErrsToIgnore;
  };

  function getFormErrorCount(form) {
    var errorList = [];
    if (form && form.$$controls && form.$$controls.length) {
      form.$$controls.forEach(function (control) {
        if (control.$$controls && control.$$controls.length) {
          // if a control is another form, let's get the errors in that form
          errorList.push.apply(errorList, _toConsumableArray(getFormErrorCount(control)));
        } else if (control.$invalid) {
          errorList.push({
            $name: control.$name.replace(/_/g, ' '),
            $element: control.$$element
          });
        }
      });
    }
    return errorList;
  }

  $scope.runPreflight = function () {
    try {
      var results = {};

      _.forEach($scope.PREFLIGHT, function (fn, key) {
        results[key] = fn();
      });

      return $q.all(results);
    } catch (e) {
      bugsnagClient.notify(e, {
        metaData: {
          userData: _.omit($scope.MODEL, 'payment')
        }
      });

      return $q.reject({ status: 500, e: e });
    }
  };

  $scope.submitDigitalWallets = function (event) {
    // wrapping in a $timeout to force a separate change detection cycle tick
    autofillDonationForm(event).then(function () {
      return $timeout(function () {});
    }).then(function () {
      event.complete('success');
      $scope.embeddedGivingCheckout();
    });
  };

  PayPalSDK.setAutoSubmitPayPalCommerceHandler(function () {
    $timeout(function () {}).then(function () {
      $scope.FORM.$setSubmitted();
      $scope.embeddedGivingCheckout();
    });
  });

  // filter out all billing address stuff EXCEPT for postal code
  function __filterRequiredOptionalErrors(errors) {
    var filterables = ['billing_country', 'billing_state', 'billing_city', 'billing_address1'];
    var _errors = errors && errors.required; // only care about 'required' inputs
    var filteredErrors = [];

    if (_errors && _errors.length) {
      for (var i = 0; i < _errors.length; i += 1) {
        if (filterables.indexOf(_errors[i].$name) < 0) {
          filteredErrors.push(_errors[i]);
        }
      }
    }

    // if no errors, then delete 'required' prop
    if (!filteredErrors.length) {
      delete errors.required;
    } else {
      errors.required = filteredErrors;
    }
  }

  $scope.embeddedGivingCheckout = function () {
    if ($scope.globalState.processing) {
      return undefined;
    }

    if (!$scope.organization.preventRecurringFrequencies()) {
      var donationLevel = $scope.block['donation-levels'];

      var isOtherInputPresent = donationLevel.some(function (level) {
        return level.amount === 'Custom' && level.display_on_page;
      });
      // Throw error and focus on first donation amount, if other input field is not present and none of the donation amount is selected.
      if ($scope.MODEL.items[0].raw_final_price < 1 && !isOtherInputPresent) {
        // TODO @hvnt add custom error handle here (on paymentDetails view)
        // $rootScope.SC.status.banner = {
        //   type: 'error',
        //   msg: 'Oops! Please select the donation amount.'
        // };

        var level = donationLevel.filter(function (item) {
          return item.display_on_page;
        });
        $('#D-A' + level[0].amount).focus();
        return false;
      }
    }

    if ($scope.META.fixedDonationAmount && $scope.MODEL.items[0].raw_final_price > 1 && !$scope.preventRecurringFrequency && $scope.FORM.amount) {
      $scope.FORM.amount.$setValidity('required', true);
      $scope.FORM.amount.$setValidity('min', true);
      $scope.FORM.amount.$setValidity('max', true);
    }

    // Subforms are not automatically set to submitted
    Object.keys($scope.FORM).filter(function (key) {
      return $scope.FORM[key] instanceof formDirective[0].controller;
    }).forEach(function (key) {
      $scope.FORM[key].$setSubmitted();
    });

    // egfa is optional, which means all of the nested form
    // inputs should not be required if egfa is false
    // if one of the form errors are for an input on this optional
    // field, yet egfa isnt required, then remove them and continue.
    if (!EmbeddedGiving.isRequiredFullAddress) {
      __filterRequiredOptionalErrors($scope.FORM.$error);
    }
    if (!_.isEmpty($scope.FORM.$error)) {
      var ccFields = ['token', 'expirationMonth', 'expirationYear', 'securityCode'];
      var fullErrorList = getFormErrorCount($scope.FORM);
      // since we can't focus any fields within the credit card frame, filter them out and add a custom element instead
      $scope.META.errorList = fullErrorList.filter(function (error) {
        return !_.includes(ccFields, error.$name);
      });

      if ($scope.META.errorList.length !== fullErrorList.length) {
        $scope.META.errorList.push({ $name: 'credit card', $element: $('#cc-entry-el') });
      }

      // If credit card error is the only error and we are using digital wallets to pay
      // skip validation so the submission can go through
      if (!scDigitalWalletsService.isDigitalWalletSubmission && !PayPalSDK.isPayPalCommerceSubmission || !ignoreErrorsForDigitalWallets($scope.META.errorList)) {
        // TODO @hvnt add custom error handle here (on paymentDetails view)
        // $rootScope.SC.status.banner = {
        //   type: 'error',
        //   msg: 'Oops! Please complete the required fields.'
        // };

        $timeout(function () {
          // we will want to focus the 'other' text field which may not be visible
          if ($scope.META.errorList.find(function (error) {
            return error.$name === 'amount';
          })) {
            if ($scope.organization.preventRecurringFrequencies()) {
              $('#D-AOther')[0].click();
            }
            $('input[name="amount"]').focus();
          }

          $scope.META.focusFormErrorsLink = true;
        });

        scDigitalWalletsService.setIsDigitalWalletsSubmission(false);
        return undefined;
      }
    }

    $scope.META.numberOfErrors = 0;
    $scope.eg.isCheckingOut = true;
    angular.element('[sc-autofocus]').removeAttr('sc-autofocus');
    $scope.runPreflight().then(function () {
      if ($scope.MODEL.recur_until) {
        $scope.MODEL.recur_until = moment($scope.MODEL.recur_until).format('YYYY-MM-DD');
      }

      // only map if eg is active
      if ($scope.eg.dtd.isActive && $window.doublethedonation) {
        mapCheckoutDtD();
      }

      return EmbeddedGivingDAL.checkout($scope.campaign.current.id, $scope.MODEL);
    }).then(function (response) {
      EmbeddedGivingMessenger.postMessageDonationComplete();

      var data = response.data;

      $scope.globalState.redirecting = true;
      $scope.globalState.submitDisabled = true;

      scDigitalWalletsService.setIsLoaded(false);
      scDigitalWalletsService.setIsDigitalWalletsSubmission(false);

      // Move all this conversion type logic to DAL
      data.payment_type = EmbeddedGivingUtil.getPaymentTypeFromCheckoutResponse(data); // for analytics
      scAnalytics.track('transaction', { transaction: data });

      var conversionData = {
        amount: data.total_gross_amount,
        transactionId: data.id,
        raw_currency_code: data.raw_currency_code,
        digitalWalletsPresent: $scope.META.digitalWalletsEnabled,
        digitalWalletType: $scope.META.digitalWalletType,
        paymentSource: data.payment_source
      };

      if ($scope.MODEL.frequency !== 'one-time') {
        scAnalytics.track('recurring-donation/create/complete', conversionData);
      } else {
        scAnalytics.track('donation/create/complete', conversionData);
      }

      $rootScope.$broadcast('donation.success', response.status);

      if (response.status === 202) {
        // $rootScope.SC.status.banner = {
        //   type: 'success',
        //   msg: _.get(response, 'data.message', 'Sorry, something went wrong.'),
        //   timeout: false
        // };
        $log.warn(response.data);
      }

      // Update EG DAL cache with things we care about
      EmbeddedGivingDAL.updateCache({
        email: data.member_email_address,
        currency: data.raw_currency_code,
        frequency: data.frequency,
        dedication: $scope.MODEL.dedication,
        checkoutTotal: conversionData.amount,
        transactionId: conversionData.transactionId
      });

      $timeout(function () {
        $scope.goThankYou();
        $scope.eg.isCheckingOut = false;
      });
    }).catch(function (response) {
      scDigitalWalletsService.setIsDigitalWalletsSubmission(false);
      // NOTE: @hvnt I removed the auto scrolling stuff

      // If the error response comes back with a fixed_fot_percent attached
      // this means that the org has changed the percentage while the donor
      // was in the middle of checkout, an unlikely ocurrance, to say the least.
      if (_.get(response, 'data.fixed_fot_percent', false)) {
        // broadcast a bad request event so that the user can get a fresh new iFrame
        // and input their card information again (new total, new card). This should
        // be extremely rare (.00001% odds) and its cumbersomeness is warranted.
        $rootScope.$broadcast('donation.failure', 400);
        // return handleFixedFotUpdate(response.data.fixed_fot_percent);
      }
      if (response.status && response.status !== 504) {
        scBanner(response.data || 'Sorry, something went wrong', 'error', false);
      } else if (response.message) {
        scBanner(response.message, 'error', false);
      } else {
        scBanner('Your request timed out. Please try again soon.', 'error', false);
      }

      $rootScope.$broadcast('donation.failure', response.status);
      $log.error(response.data || response.message);

      $timeout(function () {
        $scope.eg.isCheckingOut = false;
      });

      return undefined;
    });

    return undefined;
  };
  /* [STOP: overriding frsDonationBlock ctrl code] */

  function mapCheckoutDtD() {
    var dKey = '[0].defaultValue';
    var companyId = _.get(document.getElementsByName('doublethedonation_company_id'), dKey);
    var companyName = _.get(document.getElementsByName('doubledonation_company_name'), dKey);
    var search = _.get(document.getElementsByName('doublethedonation_entered_text'), dKey);

    if (companyId && companyName && search) {
      $scope.MODEL.employer_match = {
        employer_id: companyId,
        employer_name: companyName,
        employer_search_text: search
      };
      SC.organization.doubleTheDonationChannel.companyId = companyId;
    } else {
      $scope.MODEL.employer_match = null;
      SC.organization.doubleTheDonationChannel.companyId = null;
    }
  }

  $scope.setActiveView = function (view) {
    if (EmbeddedGiving.hasView(view)) {
      $scope.eg.activeView = view.path;
    }
  };

  /* [START: 'go' functions, which are used to navigate EG] */
  $scope.goView = function (view) {
    if (EmbeddedGiving.hasView(view)) {
      EmbeddedGivingLocations.updateParam(view.param); // only updates if change
      $scope.setActiveView(view);
    }
  };

  /* Donation consists of 1...M nested partials. Hence we have 5 children 'go's for the Donation View
   * They are declared in-order here.
   * Use goDonation() is used when want to open donation view but don't want to set active donation partial */
  $scope.goDonation = function () {
    $scope.goView(EmbeddedGiving.views.donation);
  };

  $scope.goDonationRoot = function () {
    $scope.eg.donationStep = 'root';
    $scope.META.showPaymentDetails = false; // used by eg-pay component
    $scope.goDonation();
  };

  /* Only if $scope.shouldShowRecurringNudge() */
  $scope.goDonationRecurringNudge = function () {
    scAnalytics.track(EG_EVENTS.recurringNudge, EmbeddedGivingUtil.getAnalyticsEventData($scope.MODEL));
    $scope.eg.donationStep = 'recurringNudge';
    $scope.META.showPaymentDetails = false; // used by eg-pay component
    $scope.goDonation();
  };

  $scope.goDonationPaymentMethods = function () {
    scAnalytics.track(EG_EVENTS.donationList, EmbeddedGivingUtil.getAnalyticsEventData($scope.MODEL));
    $scope.eg.donationStep = 'paymentMethods';
    $scope.META.showPaymentDetails = false;
    $scope.goDonation();
  };

  $scope.goDonationPaymentDetails = function () {
    $scope.eg.donationStep = 'paymentDetails';
    $scope.META.showPaymentDetails = true; // for payments second view -> false
    $scope.goDonation();
  };

  /* Only if EmbeddedGiving.isRequiredFullAddress */
  $scope.goDonationAddress = function () {
    scAnalytics.track(EG_EVENTS.fullAddress, EmbeddedGivingUtil.getAnalyticsEventData($scope.MODEL));
    $scope.eg.donationStep = 'address';
    $scope.META.showPaymentDetails = true;
    $scope.goDonation();
  };

  $scope.goDonationContact = function () {
    scAnalytics.track(EG_EVENTS.contact, EmbeddedGivingUtil.getAnalyticsEventData($scope.MODEL));
    $scope.eg.donationStep = 'contact';
    $scope.META.showPaymentDetails = true; // sanity
    $scope.goDonation();
  };

  $scope.goThankYou = function () {
    scAnalytics.track(EG_EVENTS.thankYou, EmbeddedGivingUtil.getAnalyticsEventData($scope.MODEL));
    $scope.goView(EmbeddedGiving.views['thank-you']);
  };

  $scope.goFAQ = function () {
    scAnalytics.track(EG_EVENTS.faq, EmbeddedGivingUtil.getAnalyticsEventData($scope.MODEL));
    $scope.goView(EmbeddedGiving.views.faq);
  };

  $scope.openFAQOverlay = function () {
    $scope.eg.faqOverlay.isOpen = true;
  };

  $scope.closeFAQOverlay = function () {
    $scope.eg.faqOverlay.isOpen = false;
  };

  /* [START: Utility functions shared across controllers] */
  $scope.shouldShowRecurringNudge = function () {
    if (!$scope.eg.isRequiredRecurringNudge) {
      return false;
    }

    var frequency = $scope.MODEL && $scope.MODEL.frequency;
    var max = EmbeddedGivingUtil.getDonationAmountMax($scope.block);
    var min = EmbeddedGivingUtil.getDonationAmountMin($scope.block);
    var current = $scope.MODEL && $scope.MODEL.items && $scope.MODEL.items.length && $scope.MODEL.items[0].raw_final_price;

    if (!EmbeddedGivingRecurringNudge.isShowable(frequency, current, max, min)) {
      return false;
    }

    return true;
  };

  $scope.openLink = function (link, desc) {
    $window.open(link, desc);
  };

  // Conditionally bootstrap double the donation
  if (EmbeddedGivingUtil.hasDoubleTheDonation()) {
    scCampaignsService.active.getCampaignChannel(CHANNEL.DTD).then(function (resp) {
      var metadata = resp && resp[0] && resp[0].metadata || {};
      $scope.eg.dtd.isEnabled = !!metadata.employer_match_on_donation_page;
      $scope.eg.dtd.isActive = !!metadata.employer_match_preselect;
    }).catch(function () {
      // TODO how to handle error? EG doesn't do banners.
      // scBanner('There was an error fetching the campaign channel status.', 'error');
    });
  }

  // TODO @hvnt Init logic
  // - read params and set appropriate view..
  var view = EmbeddedGivingLocations.getViewByParam(); // for readability instead of nesting fxn call
  $scope.setActiveView(view); // if no param, defaults to Manager's default view

  $scope.$on('$destroy', function () {
    EmbeddedGivingMessenger.postMessageDonationState();
  });
}]).controller('EmbeddedGivingDonationCtrl', ["$timeout", "$scope", function ($timeout, $scope) {
  $timeout(function () {
    $scope.FORM.$setPristine();
  });

  $scope.isValidDonationPayment = function () {
    var form = $scope.FORM;

    if (!$scope.eg.isValidPayment) {
      return false;
    }

    if (!form.billing_postal_code.$valid) {
      return false;
    }

    // Should we isolate all ACH within single conditional?
    if (form.routingNumber && !form.routingNumber.$valid) {
      return false;
    }

    // [CL-12420] for some reason, when accountNumber is pristine it is valid. probably tokenizer things
    if (form.accountNumber && (form.accountNumber.$pristine || !form.accountNumber.$valid)) {
      return false;
    }

    // if ACH terms checkbox visible, make sure it is checked
    if ($scope.eg.showAchTerms()) {
      // verify it is set to true
      if (!_.get($scope.MODEL, 'payment.ach.achTermsAccepted')) {
        return false;
      }
    }

    // if company name visible, make sure it is filled
    if ($scope.eg.showIsCompanyCard() && _.get($scope.META, 'showCompanyName')) {
      if (!form.company_name.$valid) {
        return false;
      }
    }

    return true;
  };

  $scope.$on('eg.donation-payment.is-valid', function (_event, data) {
    $scope.eg.isValidPayment = data.isValid;
    $scope.$apply();
  });
}]).controller('EmbeddedGivingRecurringNudgeCtrl', ["$scope", function ($scope) {
  $scope.$on('recurring-nudge-event', function (_event, data) {
    switch (data) {
      case 'accept':
        // set model?
        $scope.goDonationPaymentMethods();
        break;
      case 'reject':
        $scope.goDonationPaymentMethods();
        break;
      case 'back':
        $scope.goDonationRoot();
        break;
      default:
    }
  });
}]).controller('EmbeddedGivingAddressCtrl', ["$scope", function ($scope) {
  $scope.isValidDonationAddress = function () {
    var form = $scope.FORM;

    if (!form.billing_country.$valid) {
      return false;
    }

    if (!form.billing_address1.$valid) {
      return false;
    }

    if (!form.billing_city.$valid) {
      return false;
    }

    // optional sometimes...
    if (form.billing_state && !form.billing_state.$valid) {
      return false;
    }

    if (!form.billing_postal_code.$valid) {
      return false;
    }

    return true;
  };
}]).controller('EmbeddedGivingContactCtrl', ["$log", "$scope", function ($log, $scope) {
  /*
   * Checks contact input fields and verifies required values are present AND valid
   * $scope.MODEL (i.e. the data for /checkout) and $scope.META house these properties.
   *
   * This consists of 3 required fields (for /checkout):
   * 1) member_first_name (i.e. $scope.MODEL.member_first_name)
   * 2) member_last_name (i.e. $scope.MODEL.member_last_name)
   * 3) member_email_address (i.e. $scope.META.member_email_address)
   *
   * NOTE: could've short-handed this code into 1 line but opted not too for readability and extendability
   */
  $scope.isValidContact = function () {
    var form = $scope.FORM;

    if (!form) {
      return false;
    }

    if (!form.member_first_name || !form.member_first_name.$valid) {
      return false;
    }
    if (!form.member_last_name || !form.member_last_name.$valid) {
      return false;
    }

    if (!form.member_email_address || !form.member_email_address.$valid) {
      return false;
    }
    return true;
  };

  $scope.initiateEmbeddedGivingCheckout = function () {
    if ($scope.isValidContact()) {
      $scope.embeddedGivingCheckout();
    } else {
      $log.warn('[EmbeddedGivingContactCtrl] Required contact fields invalid');
    }
  };
}]).controller('EmbeddedGivingDoubleTheDonationCtrl', ["$scope", "$window", function ($scope, $window) {
  if ($scope.eg.dtd.isEnabled) {
    $window.doublethedonation.plugin.load_streamlined_input();
  }
}]).controller('EmbeddedGivingThankYouCtrl', ["$scope", "scSocialShareLinks", function ($scope, scSocialShareLinks) {
  $scope.ActiveSocials = {
    facebook: null,
    twitter: null,
    email: null
  };

  $scope.openSocialFacebook = function () {
    if ($scope.ActiveSocials.facebook) {
      $scope.ActiveSocials.facebook();
    }
  };

  $scope.openSocialTwitter = function () {
    $scope.openLink($scope.ActiveSocials.twitter, 'Share with Twitter');
  };

  $scope.openSocialEmail = function () {
    $scope.openLink($scope.ActiveSocials.email, 'Share with email');
  };

  // It may seem overkill to have a setter here, but a pattern I like is to always interface with variables through setters
  // This way, I can throw a debugger within the setter and hunt down any mutation. In the future someone will thank me, maybe.
  $scope.setActiveSocials = function (socials) {
    if (!_.isEmpty(socials)) {
      $scope.ActiveSocials.facebook = socials.facebook;
      $scope.ActiveSocials.twitter = socials.twitter;
      $scope.ActiveSocials.email = socials.email;
    }
  };

  // TODO @hvnt get and set ActiveSocials

  scSocialShareLinks.getSocialData().then(function (data) {
    $scope.setActiveSocials({
      facebook: data.facebook_share,
      twitter: data.twitter_link,
      email: data.email_link
    });
  });
}]).controller('EmbeddedGivingFAQCtrl', ["$scope", function ($scope) {
  $scope.ActiveFAQs = []; // TODO @hvnt
  $scope.ActiveFAQ = null;

  $scope.setActiveFAQs = function (faqs) {
    $scope.ActiveFAQs = faqs;
    // if 4 or less automatically open first
    if ($scope.ActiveFAQs && $scope.ActiveFAQs.length >= 1 && $scope.ActiveFAQs.length <= 4) {
      $scope.ActiveFAQ = $scope.ActiveFAQs[0];
    }
  };

  $scope.setActiveFAQ = function (faq) {
    // if selected faq is already Active, set Active to null (close faq answer)
    if ($scope.ActiveFAQ && $scope.ActiveFAQ.id === faq.id) {
      $scope.ActiveFAQ = null;
    } else {
      $scope.ActiveFAQ = faq;
    }
  };

  // init FAQs (this _.get should be encapsulated in business logic... not ctrl logic)
  $scope.setActiveFAQs(_.get($scope, 'campaign.current.faqs.data'));
}]);
})();