(function(){
'use strict';

scAddressLookup.$inject = ["loadGoogleMapsAPI"];
angular.module('classy').directive('scAddressLookup', scAddressLookup);

function scAddressLookup(loadGoogleMapsAPI) {
  return {
    require: ['ngModel', '^?form'],
    scope: {},
    template: '<input type="text" data-ng-model="query" name="scAddressLookup" placeholder="{{placeholder}}" />',
    link: function link(scope, element, attrs, ctrls) {
      var ngModelCtrl = ctrls[0],
          formCtrl = ctrls[1],
          $input = element.find('input');

      if (formCtrl) {
        formCtrl.$removeControl(formCtrl.scAddressLookup);
      }

      scope.autocomplete = null;
      scope.query = null;
      scope.placeholder = attrs.placeholder;

      /* -------------------------------------------------------------------- *
       * Prevent form submission on enter
       * -------------------------------------------------------------------- */

      $input.on('keydown', function (event) {
        if (event.keyCode == 13) {
          event.preventDefault();
        }
      });

      /* -------------------------------------------------------------------- *
       * Make required validator work correctly
       * -------------------------------------------------------------------- */

      ngModelCtrl.$isEmpty = function () {
        return !scope.successfulQuery;
      };

      /* -------------------------------------------------------------------- *
       * Load and Init
       * -------------------------------------------------------------------- */

      loadGoogleMapsAPI.then(function () {
        // Link up autocomplete; limit to street addresses (not "Taj Mahal")
        scope.autocomplete = new google.maps.places.Autocomplete($input[0], {
          types: ['address']
        });

        // When a defined location is selected, update model
        scope.autocomplete.addListener('place_changed', function () {
          var place = scope.autocomplete.getPlace();
          if (!place.address_components) {
            return;
          }
          scope.successfulQuery = $input.val();
          ngModelCtrl.$setViewValue(interpret(place));
        });

        // If a previously selected address is modified, clear on blur. (We
        // can only parse results from Google.)
        $input.on('blur', function () {
          if ($input.val() !== scope.successfulQuery) {
            $input.val('');
            scope.successfulQuery = '';
            ngModelCtrl.$setViewValue(false);
          }
        });

        // Hack to fix iOS.
        // Based on http://vedanova.com/tech/2014/11/24/google-maps-places-autocomplete-bug-on-ios.html
        $input.on('focus', function () {
          var container = document.getElementsByClassName('pac-container');
          angular.element(container).attr('data-tap-disabled', 'true');
          angular.element(container).on('touchend', function ($e) {
            $e.originalEvent.stopPropagation();
          });
        });
      });

      /* -------------------------------------------------------------------- *
       * Utils
       * -------------------------------------------------------------------- */

      function interpret(place) {
        var interpreted = {
          number: '',
          street: '',
          city: '',
          state: '',
          country: '',
          postalCode: '',
          postalCodeSuffix: ''
        };

        // Attempt to squeeze Google's administrative levels into U.S. buckets.
        // Best option should be last.
        // See https://developers.google.com/maps/documentation/geocoding/intro#Types
        var types = {
          number: ['street_number'],
          street: ['route'],
          city: ['administrative_area_level_2', // county
          'administrative_area_level_3', // no U.S. equivalent
          'administrative_area_level_4', // no U.S. equivalent
          'administrative_area_level_5', // no U.S. equivalent
          'locality' // city
          ],
          state: ['administrative_area_level_1'],
          country: ['country'],
          postalCode: ['postal_code'],
          postalCodeSuffix: ['postal_code_suffix']
        };

        _.forEach(types, function (type, humanLabel) {
          _.forEach(type, function (typeLabel) {
            _.forEach(place.address_components, function (component) {
              if (component.types.indexOf(typeLabel) > -1) {
                if (humanLabel == 'country' || humanLabel == 'state') {
                  interpreted[humanLabel] = component.short_name;
                } else {
                  interpreted[humanLabel] = component.long_name;
                }
              }
            });
          });
        });

        interpreted.address = (interpreted.number + ' ' + interpreted.street).trim();
        interpreted.postalCode += interpreted.postalCodeSuffix ? '-' + interpreted.postalCodeSuffix : '';

        return interpreted;
      }
    }
  };
}
})();